import React, { useEffect, useState } from 'react';
import './spareparts-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FiArrowUpRight } from "react-icons/fi";
import { SparePartsImg1, SparePartsImg2, SparePartsImg3, SparePartsImg4, SparePartsImg5, SparePartsImg6, SparePartsImg7, SparePartsImg8, SparePartsImg9, SparePartsImg10, SparePartsImg11, SparePartsImg12, SparePartsImg13, SparePartsImg14, SparePartsImg15, SparePartsImg16, SparePartsImg17, SparePartsImg18, SparePartsImg19, SparePartsImg20, SparePartsImg21, SparePartsImg22, SparePartsImg23, SparePartsImg24, SparePartsImg25, SparePartsImg26, SparePartsImg27, SparePartsImg28, SparePartsImg29, SparePartsImg30, SparePartsImg31, SparePartsImg32, SparePartsImg33, SparePartsImg34, SparePartsImg35, SparePartsImg36, SparePartsImg38, SparePartsImg39, SparePartsImg40, SparePartsImg41, SparePartsImg42, SparePartsImg43, SparePartsImg44, SparesBannerDesk, SparesBannerMobile, TransformerSparesParts } from '../../assets';

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import { Accordion, Button } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { ERR_EMAIL_REQUIRED, ERR_FAILED_EMAIL, ERR_FULL_NAME_REQUIRED, ERR_INVALID_EMAIL, ERR_INVALID_PHONE, ERR_PHONE_REQUIRED, ERR_REQ_MESSAGE, FRM_GET_QUOTE, ERR_INQUIRY_MESSAGE, API_BASE_URL } from '../../constants/commonConstants';

import { successToast, errorToast } from '../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import superagent from 'superagent';

import { Row, Col } from 'react-bootstrap';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';


import GetQuoteSpareParts from "../../components/common/getquotespareparts/GetQuoteSpareParts";

function SparePartsListGoogle(props: { show: boolean, onHide: any }) {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Spares';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/transformer-spares`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const animatedComponents = makeAnimated();
    const countrycode = [
        { "value": 1, "label": "AF +93" },
        { "value": 2, "label": "AL +355" },
        { "value": 3, "label": "DZ +213" },
        { "value": 4, "label": "AS +1-684" },
        { "value": 5, "label": "AD +376" },
        { "value": 6, "label": "AO +244" },
        { "value": 7, "label": "AI +1-264" },
        { "value": 8, "label": "AQ +672" },
        { "value": 9, "label": "AG +1-268" },
        { "value": 10, "label": "AR +54" },
        { "value": 11, "label": "AM +374" },
        { "value": 12, "label": "AW +297" },
        { "value": 13, "label": "AU +61" },
        { "value": 14, "label": "AT +43" },
        { "value": 15, "label": "AZ +994" },
        { "value": 16, "label": "BS +1-242" },
        { "value": 17, "label": "BH +973" },
        { "value": 18, "label": "BD +880" },
        { "value": 19, "label": "BB +1-246" },
        { "value": 20, "label": "BY +375" },
        { "value": 21, "label": "BE +32" },
        { "value": 22, "label": "BZ +501" },
        { "value": 23, "label": "BJ +229" },
        { "value": 24, "label": "BM +1-441" },
        { "value": 25, "label": "BT +975" },
        { "value": 26, "label": "BO +591" },
        { "value": 27, "label": "BA +387" },
        { "value": 28, "label": "BW +267" },
        { "value": 29, "label": "BR +55" },
        { "value": 30, "label": "IO +246" },
        { "value": 31, "label": "VG +1-284" },
        { "value": 32, "label": "BN +673" },
        { "value": 33, "label": "BG +359" },
        { "value": 34, "label": "BF +226" },
        { "value": 35, "label": "BI +257" },
        { "value": 36, "label": "KH +855" },
        { "value": 37, "label": "CM +237" },
        { "value": 38, "label": "CA +1" },
        { "value": 39, "label": "CV +238" },
        { "value": 40, "label": "KY +1-345" },
        { "value": 41, "label": "CF +236" },
        { "value": 42, "label": "TD +235" },
        { "value": 43, "label": "CL +56" },
        { "value": 44, "label": "CN +86" },
        { "value": 45, "label": "CX +61" },
        { "value": 46, "label": "CC +61" },
        { "value": 47, "label": "CO +57" },
        { "value": 48, "label": "KM +269" },
        { "value": 49, "label": "CK +682" },
        { "value": 50, "label": "CR +506" },
        { "value": 51, "label": "HR +385" },
        { "value": 52, "label": "CU +53" },
        { "value": 53, "label": "CW +599" },
        { "value": 54, "label": "CY +357" },
        { "value": 55, "label": "CZ +420" },
        { "value": 56, "label": "CD +243" },
        { "value": 57, "label": "DK +45" },
        { "value": 58, "label": "DJ +253" },
        { "value": 59, "label": "DM +1-767" },
        { "value": 60, "label": "DO +1-809, 1-829, 1-849" },
        { "value": 61, "label": "TL +670" },
        { "value": 62, "label": "EC +593" },
        { "value": 63, "label": "EG +20" },
        { "value": 64, "label": "SV +503" },
        { "value": 65, "label": "GQ +240" },
        { "value": 66, "label": "ER +291" },
        { "value": 67, "label": "EE +372" },
        { "value": 68, "label": "ET +251" },
        { "value": 69, "label": "FK +500" },
        { "value": 70, "label": "FO +298" },
        { "value": 71, "label": "FJ +679" },
        { "value": 72, "label": "FI +358" },
        { "value": 73, "label": "FR +33" },
        { "value": 74, "label": "PF +689" },
        { "value": 75, "label": "GA +241" },
        { "value": 76, "label": "GM +220" },
        { "value": 77, "label": "GE +995" },
        { "value": 78, "label": "DE +49" },
        { "value": 79, "label": "GH +233" },
        { "value": 80, "label": "GI +350" },
        { "value": 81, "label": "GR +30" },
        { "value": 82, "label": "GL +299" },
        { "value": 83, "label": "GD +1-473" },
        { "value": 84, "label": "GU +1-671" },
        { "value": 85, "label": "GT +502" },
        { "value": 86, "label": "GG +44-1481" },
        { "value": 87, "label": "GN +224" },
        { "value": 88, "label": "GW +245" },
        { "value": 89, "label": "GY +592" },
        { "value": 90, "label": "HT +509" },
        { "value": 91, "label": "HN +504" },
        { "value": 92, "label": "HK +852" },
        { "value": 93, "label": "HU +36" },
        { "value": 94, "label": "IS +354" },
        { "value": 95, "label": "IN +91" },
        { "value": 96, "label": "ID +62" },
        { "value": 97, "label": "IR +98" },
        { "value": 98, "label": "IQ +964" },
        { "value": 99, "label": "IE +353" },
        { "value": 100, "label": "IM +44-1624" },
        { "value": 101, "label": "IL +972" },
        { "value": 102, "label": "IT +39" },
        { "value": 103, "label": "CI +225" },
        { "value": 104, "label": "JM +1-876" },
        { "value": 105, "label": "JP +81" },
        { "value": 106, "label": "JE +44-1534" },
        { "value": 107, "label": "JO +962" },
        { "value": 108, "label": "KZ +7" },
        { "value": 109, "label": "KE +254" },
        { "value": 110, "label": "KI +686" },
        { "value": 111, "label": "XK +383" },
        { "value": 112, "label": "KW +965" },
        { "value": 113, "label": "KG +996" },
        { "value": 114, "label": "LA +856" },
        { "value": 115, "label": "LV +371" },
        { "value": 116, "label": "LB +961" },
        { "value": 117, "label": "LS +266" },
        { "value": 118, "label": "LR +231" },
        { "value": 119, "label": "LY +218" },
        { "value": 120, "label": "LI +423" },
        { "value": 121, "label": "LT +370" },
        { "value": 122, "label": "LU +352" },
        { "value": 123, "label": "MO +853" },
        { "value": 124, "label": "MK +389" },
        { "value": 125, "label": "MG +261" },
        { "value": 126, "label": "MW +265" },
        { "value": 127, "label": "MY +60" },
        { "value": 128, "label": "MV +960" },
        { "value": 129, "label": "ML +223" },
        { "value": 130, "label": "MT +356" },
        { "value": 131, "label": "MH +692" },
        { "value": 132, "label": "MR +222" },
        { "value": 133, "label": "MU +230" },
        { "value": 134, "label": "YT +262" },
        { "value": 135, "label": "MX +52" },
        { "value": 136, "label": "FM +691" },
        { "value": 137, "label": "MD +373" },
        { "value": 138, "label": "MC +377" },
        { "value": 139, "label": "MN +976" },
        { "value": 140, "label": "ME +382" },
        { "value": 141, "label": "MS +1-664" },
        { "value": 142, "label": "MA +212" },
        { "value": 143, "label": "MZ +258" },
        { "value": 144, "label": "MM +95" },
        { "value": 145, "label": "NA +264" },
        { "value": 146, "label": "NR +674" },
        { "value": 147, "label": "NP +977" },
        { "value": 148, "label": "NL +31" },
        { "value": 149, "label": "AN +599" },
        { "value": 150, "label": "NC +687" },
        { "value": 151, "label": "NZ +64" },
        { "value": 152, "label": "NI +505" },
        { "value": 153, "label": "NE +227" },
        { "value": 154, "label": "NG +234" },
        { "value": 155, "label": "NU +683" },
        { "value": 156, "label": "KP +850" },
        { "value": 157, "label": "MP +1-670" },
        { "value": 158, "label": "NO +47" },
        { "value": 159, "label": "OM +968" },
        { "value": 160, "label": "PK +92" },
        { "value": 161, "label": "PW +680" },
        { "value": 162, "label": "PS +970" },
        { "value": 163, "label": "PA +507" },
        { "value": 164, "label": "PG +675" },
        { "value": 165, "label": "PY +595" },
        { "value": 166, "label": "PE +51" },
        { "value": 167, "label": "PH +63" },
        { "value": 168, "label": "PN +64" },
        { "value": 169, "label": "PL +48" },
        { "value": 170, "label": "PT +351" },
        { "value": 171, "label": "PR +1-787, 1-939" },
        { "value": 172, "label": "QA +974" },
        { "value": 173, "label": "CG +242" },
        { "value": 174, "label": "RE +262" },
        { "value": 175, "label": "RO +40" },
        { "value": 176, "label": "RU +7" },
        { "value": 177, "label": "RW +250" },
        { "value": 178, "label": "BL +590" },
        { "value": 179, "label": "SH +290" },
        { "value": 180, "label": "KN +1-869" },
        { "value": 181, "label": "LC +1-758" },
        { "value": 182, "label": "MF +590" },
        { "value": 183, "label": "PM +508" },
        { "value": 184, "label": "VC +1-784" },
        { "value": 185, "label": "WS +685" },
        { "value": 186, "label": "SM +378" },
        { "value": 187, "label": "ST +239" },
        { "value": 188, "label": "SA +966" },
        { "value": 189, "label": "SN +221" },
        { "value": 190, "label": "RS +381" },
        { "value": 191, "label": "SC +248" },
        { "value": 192, "label": "SL +232" },
        { "value": 193, "label": "SG +65" },
        { "value": 194, "label": "SX +1-721" },
        { "value": 195, "label": "SK +421" },
        { "value": 196, "label": "SI +386" },
        { "value": 197, "label": "SB +677" },
        { "value": 198, "label": "SO +252" },
        { "value": 199, "label": "ZA +27" },
        { "value": 200, "label": "KR +82" },
        { "value": 201, "label": "SS +211" },
        { "value": 202, "label": "ES +34" },
        { "value": 203, "label": "LK +94" },
        { "value": 204, "label": "SD +249" },
        { "value": 205, "label": "SR +597" },
        { "value": 206, "label": "SJ +47" },
        { "value": 207, "label": "SZ +268" },
        { "value": 208, "label": "SE +46" },
        { "value": 209, "label": "CH +41" },
        { "value": 210, "label": "SY +963" },
        { "value": 211, "label": "TW +886" },
        { "value": 212, "label": "TJ +992" },
        { "value": 213, "label": "TZ +255" },
        { "value": 214, "label": "TH +66" },
        { "value": 215, "label": "TG +228" },
        { "value": 216, "label": "TK +690" },
        { "value": 217, "label": "TO +676" },
        { "value": 218, "label": "TT +1-868" },
        { "value": 219, "label": "TN +216" },
        { "value": 220, "label": "TR +90" },
        { "value": 221, "label": "TM +993" },
        { "value": 222, "label": "TC +1-649" },
        { "value": 223, "label": "TV +688" },
        { "value": 224, "label": "VI +1-340" },
        { "value": 225, "label": "UG +256" },
        { "value": 226, "label": "UA +380" },
        { "value": 227, "label": "AE +971" },
        { "value": 228, "label": "GB +44" },
        { "value": 229, "label": "US +1" },
        { "value": 230, "label": "UY +598" },
        { "value": 231, "label": "UZ +998" },
        { "value": 232, "label": "VU +678" },
        { "value": 233, "label": "VA +379" },
        { "value": 234, "label": "VE +58" },
        { "value": 235, "label": "VN +84" },
        { "value": 236, "label": "WF +681" },
        { "value": 237, "label": "EH +212" },
        { "value": 238, "label": "YE +967" },
        { "value": 239, "label": "ZM +260" },
        { "value": 240, "label": "ZW +263" }
    ]

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [phonecode, setPhoneCode] = useState(countrycode[94]);
    const [inquiryType, setInquiryType] = useState('Transformer Spares');
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);

    // if (redirect) {
    //     return <Navigate to={'/thankyou'} />;
    // }
    if (redirect) {
        window.location.href = "/quotetion-thankyou";
        // return <Navigate to={'/thankyou'} />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (name.trim().length === 0) {
            errorToast(ERR_FULL_NAME_REQUIRED);
            return false;
        }
        if (email.trim().length === 0) {
            errorToast(ERR_EMAIL_REQUIRED);
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) {
            errorToast(ERR_INVALID_EMAIL);
            return false;
        }
        if (mobile.trim().length === 0) {
            errorToast(ERR_PHONE_REQUIRED);
            return false;
        }
        const numberRegex = /^[0-9]+$/;
        if (!numberRegex.test(mobile.trim())) {
            errorToast(ERR_INVALID_PHONE);
            return false;
        }
        if (inquiryType.trim().length === 0) {
            errorToast(ERR_INQUIRY_MESSAGE);
            return false;
        }
        if (message.trim().length === 0) {
            errorToast(ERR_REQ_MESSAGE);
            return false;
        }
        try {
            await superagent.post(`${API_BASE_URL}send-email`)
                .send({ formName: FRM_GET_QUOTE, firstName: name, lastName: lastname, email: email, phoneCode: phonecode.label, mobileNumber: mobile, subject: 'Quotation for spare parts via Google Ads - ' + inquiryType, message: message }).withCredentials();
            setLoading(false);
            setRedirect(true);
        } catch (error: any) {
            setLoading(false);
            errorToast(ERR_FAILED_EMAIL)
        }
    };

    return (
        <>
            <GetQuoteSpareParts show={show} onHide={() => handleClose()} />
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/transformer-spares`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='SperBoxPartBannertop'>
                        <img rel="preload" src={SparesBannerMobile} alt="Spares Banner" className='MobileSpareBanner' />
                        <img rel="preload" src={SparesBannerDesk} alt="Spares Banner" className='DeskSpareBanner' />
                    </div>
                    <div className='sperpttopMainbox MainFormSetTrafSpare'>
                        <div className='sperpttopMainLeft'>
                            <div className='sperpttopMainTitle'>
                                <h1>Transformer Spares</h1>
                                <h6>Get 100% Authentic & Genuine Transformer Spare Parts</h6>
                            </div>
                            <p>End your search for Transformer replacement parts by choosing VoltageShift as your <span>Transformer Parts Manufacturer</span>. We pay attention to detail to manufacture every
                                Transformer Part, and they comply with Industry's highest quality standards.</p>
                            <p>As a trustworthy <span>Transformer Spare Parts Exporter & Manufacturer,</span> we manufacture and export 100% Authentic & Genuine Transformer Spare parts on-site at the International Level in 45+
                                countries, including India, South Africa, Ghana, Singapore, Thailand, Hong Kong, Israel,
                                Iraq, Jordan, Germany, Italy, and Japan.</p>
                            <p>We strive to ensure that our clients get all the required <span>Transformers Parts</span> delivered within
                                the dedicated time frame to keep their Transformers functioning smoothly and operations
                                intact.</p>
                            <p>As a leading <span>Transformer Spare Parts Supplier</span>, we sell an extensive range of Transformer
                                Spare Parts from transformer oil, tap changer, windings, Buchholz Relay, insulating
                                materials, Radiators, cooling fans, silica gel breathers, condenser bushing, pressure relief
                                valves, and more.</p>
                            <p>We have a team of highly qualified and skilled experts who will professionally install/replace <span>Transformer Spare Parts</span> as part of our Transformer Repairing & Preventive Maintenance
                                Services. Choose us and give your Transformers legitimate <span>Transformer Accessories</span> to
                                keep them functioning like new ones.</p>
                        </div>
                        <div className='sperpttopMainRight'>
                            <div className='RightFormSetTrafSpare'>
                                <Form onSubmit={handleSubmit}>
                                    <h4>Get A Quotation</h4>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group className="InputMainComset" controlId="">
                                                {/* <Form.Label>Full Name*</Form.Label> */}
                                                <Form.Control type="" placeholder="Enter full name" value={name} onChange={e => setName(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Group className="InputMainComset" controlId="">
                                                {/* <Form.Label>Email Address*</Form.Label> */}
                                                <Form.Control type="" className='emailID' placeholder="Enter email address" value={email} onChange={e => setEmail(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Group className="InputMainComset" controlId="">
                                                {/* <Form.Label>Phone Number*</Form.Label> */}
                                                <div className='MobileNumbSltbox'>
                                                    <div className='MobileNumbCode'>
                                                        <Select
                                                            components={animatedComponents}
                                                            options={countrycode}
                                                            classNamePrefix="react-select"
                                                            defaultValue={countrycode[94]}
                                                            value={phonecode}
                                                            onChange={(selectedOption) => setPhoneCode(selectedOption as any)}
                                                        />
                                                    </div>
                                                    <Form.Control type="" className='phoneNumber' placeholder="Enter phone number" value={mobile} onChange={e => setMobile(e.target.value)} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {['radio'].map((type: any) => (
                                            <div key={`inline-${type}`} className='InquiryboxSet'>
                                                <h3>Inquiry For</h3>
                                                <div className='InquiryboxIner'>
                                                    <Form.Check
                                                        inline
                                                        name="name1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        label="Transformer"
                                                        value="Transformer"
                                                        onChange={e => setInquiryType(e.target.value)}
                                                    />
                                                </div>

                                                <div className='InquiryboxIner'>
                                                    <Form.Check
                                                        inline
                                                        name="name1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        label="Transformer Service"
                                                        value="Transformer Service"
                                                        onChange={e => setInquiryType(e.target.value)}
                                                    />
                                                </div>

                                                <div className='InquiryboxIner'>
                                                    <Form.Check
                                                        inline
                                                        name="name1"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        label="Transformer Spares"
                                                        value="Transformer Spares"
                                                        checked={inquiryType === "Transformer Spares"}
                                                        onChange={e => setInquiryType(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <Col md={12}>
                                            <Form.Group className="TextareaMainComset" controlId="">
                                                {/* <Form.Label>Requirement*</Form.Label> */}
                                                <Form.Control as="textarea" rows={3} placeholder="Enter requirement" value={message} onChange={e => setMessage(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <div className='FullButtonboxmodal'>
                                                <Button type="submit">Get A Quotation</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <div className='reachUstTobox'>
                                        <p>Or</p>
                                        <a href="tel:+91 9974466992"><BsFillTelephoneFill /> +91 99744 66992</a>
                                        <a href="mailto:info@voltageshift.com"><MdEmail /> info@voltageshift.com</a>
                                    </div> */}
                                    {/* <div className='reachUstTobox'>
                                        <h6>Reach out to us to <a href="tel:+91 9974466992"><BsFillTelephoneFill /> +91 99744 66992</a> or <a href="mailto:info@voltageshift.com"><MdEmail /> info@voltageshift.com</a></h6>
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='CustContainer'>
                    <div className='SparePartsMainTitle'>
                        <h2>Our Transformer Spare Components</h2>
                        <p>At VoltageShift, we manufacture and sell comprehensive <span>Transformer Components</span> to
                            ensure our clients get all the replacement parts at a one-stop destination. As a <span>Transformer
                                Spares Exporter</span>, we export to 45+ countries. The following are the <span>Transformer Spare
                                    Components</span> we deal in.</p>
                    </div>
                    <div className='SparePartsMainCov'>
                        <Row>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg1} alt="Pressure Relief Valve or Pressure Relief Device (PRV)" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Pressure Relief Valve or Pressure Relief Device (PRV)</h3>
                                        <p>Pressure Relief Valve (PRV) is safeguarding the transformer against excessive oil pressure accumulation in the event of a fault.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg2} alt="Buchholz Relay or Gas Actuated Relay" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Buchholz Relay or Gas Actuated Relay</h3>
                                        <p>The Buchholz Relay functions as an alert system sensitive to internal transformer faults.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg3} alt="Oil Level Indicator or Magnetic Oil Level Gauge (MOG)" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Level Indicator or Magnetic Oil Level Gauge (MOG)</h3>
                                        <p>Magnetic Oil Level Indicator (MOG) is designed to display the transformer insulating oil level in the conservator of transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg22} alt="Porcelain Bushing" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Porcelain Bushing</h3>
                                        <p>Porcelain bushing is an insulating structure that enables an energized, current-carrying conductor to pass through the grounded tank of the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg14} alt="On Load Tap changer or OLTC" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>On Load Tap changer or OLTC</h3>
                                        <p>On-Load Tap Changer adjusts transformer voltage to stabilize output amidst load and supply variations.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg23} alt="Radiator Valve or Shut off Valve" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Radiator Valve or Shut off Valve</h3>
                                        <p>Transformer radiator valve is a control device used to regulate the flow of cooling oil through the transformer's radiator, ensuring efficient cooling and temperature control.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg24} alt="Drain Valve or Gate Valve or Globe Valve" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Drain Valve or Gate Valve or Globe Valve</h3>
                                        <p>Transformer drain valve are essential control devices used for managing the draining of oil or controlling the flow within a transformer system.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg4} alt="Silica Gel Breather" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Silica Gel Breather</h3>
                                        <p>A Silica Gel Breather contains silica gel crystals to absorb moisture from the air entering the transformer's conservator tank, preserves transformer oil quality by moisture absorption.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg18} alt="Oil and Winding Temperature Indicator" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil and Winding Temperature Indicator</h3>
                                        <p>Analog Oil and Winding Temperature Indicator is a gauge that provides a visual representation of the transformer oil's and winding temperature on a traditional analog scale.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg8} alt="Transformer Oil or Insulating Oil" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Transformer Oil or Insulating Oil</h3>
                                        <p>Transformer oil is a specialized dielectric fluid used in transformers to provide electrical insulation and efficient cooling, ensuring safe and reliable operation of the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg12} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Gasket and Oring Sets</h3>
                                        <p>Gasket and Oring Sets are sealing materials placed between mating components to prevent leakage of oil or gas, Preserving transformer integrity and performance.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg7} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Cooling Fan</h3>
                                        <p>Transformer cooling fan is a mechanical device used to enhance the cooling process by increasing airflow over the transformer's radiator or cooling fins.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg10} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Surge Relay (OSR)</h3>
                                        <p>The Oil Surge Relay acts as a protective device, responding to sudden oil surges within a transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg5} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Aircell or Flexi Separators</h3>
                                        <p>Aircell is engineered to serve as a partition, keeping unwanted contaminants in the atmosphere from coming into contact with transformer oil inside a conservator.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg25} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Conservator Protection Relay (CPR)</h3>
                                        <p>The Conservator Protection Relay (CPR) is a dedicated protective device created to identify Aircell leaks by generating electrical signals.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg26} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Gas Collecting Device (GCD)</h3>
                                        <p>Gas Collecting Device is an attachment connected to the gas collection petcock of the Buchholz Relay, Easily collect Gas and Oil samples from the relay.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg38} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>CT Terminal Box (TBX)</h3>
                                        <p>Terminal Box (TBX) provides a quick, reliable connection solution for linking CT wires inside the transformer to external measurement systems.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg27} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>CFT Terminal Box (CFT)</h3>
                                        <p>CFT is intended for establishing a ground connection between the transformer's core and frame and the tank.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg28} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Flow Indicator</h3>
                                        <p>The Flow Indicator is primarily crafted as a safety device, ensuring an electrical signal in case of forced liquid circulation failure within the pipeline.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg41} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Prismatic Oil Level Indicators</h3>
                                        <p>Prismatic Oil Liquid Level Indicators for Transformers are monitoring devices that display the current oil level in the transformer's conservator tank.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>

                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg29} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Pressure Gauge</h3>
                                        <p>A Pressure Gauge is a measuring device that displays the pressure of the insulating oil within the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg30} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Off Load Tap changer or De energised Switch</h3>
                                        <p>An Off-Load Tap Changer is a switching mechanism used to adjust the transformer's tap settings without load or power flow, Optimizing voltage when transformer's inactive.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg31} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Remote Indicator (OTI/WTI)</h3>
                                        <p>A Remote Indicator is a monitoring device that displays the Oil Temperature Indicator (OTI) and Winding Temperature Indicator (WTI) readings from a remote location.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg32} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Automatic Voltage Regulator (AVR)</h3>
                                        <p>An Automatic Voltage Regulator is an essential control device that automatically adjusts and stabilizes the output voltage to maintain a consistent and reliable electrical supply.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg33} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Annunciator</h3>
                                        <p>An Annunciator is a visual or audible alert system that provides notifications or warnings about specific conditions or events occurring in the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg34} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Tap Position Indicator</h3>
                                        <p>A Tap Position Indicator is a device that visually or electronically indicates the current position of the tap changer on the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg11} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>RTCC Panel</h3>
                                        <p>Remote Tap Changer Control Panel is a control and monitoring system that remotely regulates the tap changer settings on a transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg35} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Marshalling Box</h3>
                                        <p>A Marshalling Box for transformers is an enclosure or junction box used to organize and route various control and monitoring cables.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg9} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Radiator</h3>
                                        <p>A radiator for transformers is a heat exchange device that dissipates excess heat generated during the transformer's operation.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg15} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Bushing CT (BCT)</h3>
                                        <p>A Bushing CT (Current Transformer) for transformers is an instrument that measures the electrical current passing through a transformer bushing.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>



                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg36} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Copper Flat</h3>
                                        <p>Copper flat for transformers refers to flat-shaped copper conductors used within transformers for efficient electrical conductivity.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg40} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Pump</h3>
                                        <p>A transformer oil pump is a mechanical device responsible for circulating and maintaining the proper flow of insulating oil within the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg19} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Roller or Transformer Wheels</h3>
                                        <p>Rollers or transformer wheels are mechanical components attached to the base of a transformer, allowing for easy mobility and transportation.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg39} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Epoxy Bushing</h3>
                                        <p>An epoxy bushing for transformers is an insulating component made of epoxy resin, which provides insulation and conductor support.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg42} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Rapid Pressure Rise relay</h3>
                                        <p>A Rapid Pressure Rise Relay is a protective device designed to detect and respond to sudden increases in pressure within the transformer.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg43} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Thermometer</h3>
                                        <p>A Thermometer for transformers is an instrument used to measure and display the temperature of the transformer's oil or winding.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg44} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Heat Exchanger</h3>
                                        <p>A Heat Exchanger for transformers is a component that helps regulate the temperature of the transformer's insulating oil by dissipating excess heat.</p>
                                        <button onClick={handleShow}>Get a Quote</button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. Which are the primary Transformer Spare Parts?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Transformers have several components in them. However, the primary ones are core,
                                        transformer oil, tap changer, windings, Buchholz Relay, insulating materials, explosion vent,
                                        cooling tubers, breather, condenser bushing, and valves.</p>
                                    <p>Out of all, only core, transformer oil, windings, and insulating materials are present in almost
                                        every transformer. And the rest components in transformers above 50 KVA Power Rating.</p>
                                    <p>At VoltageShift, we sell comprehensive Transformer Spare Parts for all ranges, making us
                                        your one-stop destination. Partner with us as your <span>Transformer Spares Supplier</span> and get
                                        100% authentic & genuine spare parts at cost-effective pricing. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. What is Buchholz Relay in Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Buchholz Relay is placed in a cast housing over the connecting pipe that flows from the
                                        primary tank to the conservator. It senses the faults within the transformer. It operates through
                                        the emitted gases due to the deterioration of the Transformer Oil during internal issues.</p>
                                    <p>Buchholz Relay has two contact sets. One is used to manage slow accumulations of gas. The
                                        other handles bulk displacement of Transformer Oil during significant internal problems.</p>
                                    <p>If you are looking for a highly reliable <span>Transformer Spares Distributor</span>, VoltageShift is
                                        your go-to destination. We manufacture and sell an extensive range of Transformer Spare
                                        Components, including Buchholz Relay. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. What is a Tap Changer in a Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Tap Changer is used for balancing the Transformer's voltage variations. They are of two
                                        types - On Load and Off Load. In On Load Tap Changers (OLTC), you can change tapping
                                        without isolating the Transformer from the supply. In contrast, in Off Load Tap Changers,
                                        you are required to disconnect tapping from the supply to change it.</p>
                                    <p>If you are searching for a <span>Transformer Spares Manufacturer</span> selling Tap Changers,
                                        VoltageShift is your go-to destination. We sell premier quality and 100% authentic
                                        Transformer Tap Changers. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. What is Silica Gel Breather in Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>Silica Gel Breathers</span>, also known as Dehydrating Breathers, act as dying agents in
                                        Transformers. Its primary purpose is to prevent and control moisture content within a
                                        breather transformer from coming in connection with an Electrical Transformer's Insulating
                                        Liquid. It protects the internal components of the Transformer from damaging effects.</p>
                                    <p>Silica Gel Breathers are blue in color when they are new. However, if the color changes to
                                        light pink, it is a sign to the operators/workers to opt for replacement or regeneration of the
                                        Silica Gel in the Breather.</p>
                                    <p>As a leading silica gel breather supplier & manufacturer, VoltageShift offers a wide range of <span>Electrical Transformers Parts</span>, including Silica Gel Breathers that are non-toxic, inflammable, and DMF-free, ensuring corrosion prevention. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>5. What is Bushing in a Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A <span>Bushing in a Transformer</span> is an insulating structure to facilitate the flow of energized and
                                        current-carrying conductors via a Transformer grounded tank. In an electric power
                                        transformer, Bushing is a hollow electrical insulator enabling the safe passage of electrical
                                        conductors. Electrical Bushings are a pivotal part of <span>Power Transformer Parts</span>.</p>
                                    <p>In any range of Transformer, there are Low Voltage (LV) & High Voltage (HV) Bushing. The
                                        conducting rod of an LV Bushing carries a higher current than HV Bushing. LV Bushing is
                                        thicker due to its larger diameter than HV. They perform the crucial task of passing current at
                                        HV through enclosed components. The Oil is required in Bushing for the insulation and
                                        coolant.</p>
                                    <p>At VoltageShift, renowned transformer bushing suppliers & manufacturers, we sell 100% genuine and premier quality <span>Power Transformer Parts and Components</span>, including Bushing. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>6. What distinguishes a Transformer on Load Tap Changer from an Off Load Tap Changer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Transformer on Load Tap Changer (OLTC) enables voltage adjustments while the transformer is connected to the supply, ensuring continuous operation. Conversely, a Transformer Off Load Tap Changer requires disconnection from the power supply for voltage changes, offering a simpler and more cost-effective solution.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>7. Who is a leading oil pump manufacturer for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a premier oil pump manufacturer and supplier for transformers. We specialize in producing high-quality, durable oil pumps essential for transformer maintenance and efficiency. Our products guarantee authenticity and reliability, offering cost-effective solutions for your transformer needs. Contact us for a detailed quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>8. Who is a renowned transformer oil manufacturer offering authentic and high-quality transformer oil?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a distinguished transformer oil manufacturer and supplier, known for supplying 100% authentic and top-grade transformer oil. They ensure their products meet stringent quality standards, providing reliable and cost-effective solutions for your transformer needs. Contact VoltageShift for a quotation on their superior transformer oil.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>9. Why choose VoltageShift as transformer radiator supplier?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a leading transformer radiator manufacturer, offering top-quality, reliable radiators for various transformer types. As a one-stop destination for transformer spare parts, we ensure our radiators meet the highest standards, providing effective cooling solutions for transformers. Contact us for a detailed quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>10. What are Transformer Bushing Parts and their Importance in Transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Transformer Bushing Parts are essential insulating structures allowing energized conductors to pass safely through a Transformer's grounded tank. They play a crucial role in managing high and low voltage currents, ensuring safe current transmission and preventing electrical hazards. At VoltageShift, we offer premium quality Transformer Bushing Parts. Contact us for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>11. Who is a reliable transformer cooling fans manufacturer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a premier cooling fan manufacturer & supplier for transformers. We specialize in producing top-quality, durable cooling fans essential for maintaining optimal transformer performance. Partner with us for your transformer cooling fan needs and receive exceptional products at cost-effective prices. Contact us for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>12. What is the Role of a Transformer Pressure Relief Valve?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Transformer Pressure Relief Valve is crucial for managing internal pressure. It ensures safety by releasing excess pressure from the transformer, preventing damage. VoltageShift offers top-quality Transformer Pressure Relief Valves, essential for maintaining transformer integrity. Contact us for durable, reliable valves.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>13. Who should you contact for reliable gasket supply and manufacturing?</Accordion.Header>
                                <Accordion.Body>
                                    <p>As a leading gasket supplier and manufacturer, VoltageShift is your premier destination. We offer a wide range of high-quality gaskets, ensuring durability and efficiency for all your needs. Contact us for a quotation to get 100% authentic and cost-effective gasket solutions.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>14. Who is a Leading O Ring Supplier and Manufacturer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>As a premier O Ring supplier and manufacturer, we at VoltageShift pride ourselves on providing high-quality, durable O Rings. Our products ensure optimal sealing solutions across various applications. Contact us for a quotation and experience our commitment to excellence and customer satisfaction.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>
                </div>

                <Continent />

            </div>
        </>
    )
}

export default SparePartsListGoogle