import React, { SyntheticEvent, useState } from 'react';
import './login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Logo } from "../../assets";
import { Navigate } from 'react-router-dom';
import { successToast, errorToast } from '../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import superagent from 'superagent';
import { API_BASE_URL } from '../../constants/commonConstants';

function Index() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await superagent.post(`${API_BASE_URL}login`)
                .send({ email, password }).withCredentials();

            const data = response.body;
            if (data && data.status === 200) {
                setRedirect(data);
                successToast('Login Successfully');
            } else {
                errorToast(data.message);
            }
        } catch (e: any) {
            console.log(`${e.response.status}: ${e.response.body.message}`);
            errorToast(e.response.body.message);
        }
    }

    /* const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const { data } = await axios.post('login', {
                email,
                password
            });
            if (data && data.status === 200) {
                setRedirect(data);
                successToast('Login Successfully');
            } else {
                errorToast(data.message);
            }
        } catch (e: any) {
            console.log(`${e.data.status}: ${e.data.message}`);
            errorToast(e.data.data.message);
        }
    } */

    if (redirect) {
        return <Navigate to={'/admin/users-list'} />;
    }

    return (
        <><ToastContainer />
            <div className='LoginMainCov'>
                <div className='LoginMainRight'>
                    <div className='LoginRightIner'>
                        <img src={Logo} alt="logo" />
                        <h2>Login to your Account</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <form onSubmit={submit}>
                            <Form.Group className="InputMainComset" controlId="user_email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter your email" autoComplete='false'
                                    onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="InputMainComset" controlId="user_password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter your password" autoComplete='false'
                                    onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            <div className='BtnsubtnboxCov'>
                                <Button type="submit">Login</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index