import React, { useState } from 'react'
import './sidebar.css';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import { HiUserGroup } from "react-icons/hi";
import { BASE_URL } from '../../../constants';
import axios from "axios";
import { User } from '../../../models/user';
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';

function Index() {
    const [redirect, setRedirect] = useState(false);
    const logout = async () => {
        await axios.post('logout', {});
        setRedirect(true);
    }
    if (redirect) {
        return <Navigate to={'/admin/login'} />
    }
    return (
        <div>
            <div className='SidebarNavboxMain'>
                <Accordion>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/roles`}><HiUserGroup className='sidebarIconsetBox' />Roles</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/users-list`}><HiUserGroup className='sidebarIconsetBox' />Users</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/category`}><HiUserGroup className='sidebarIconsetBox' />Categories</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/seo`}><HiUserGroup className='sidebarIconsetBox' />News SEOs</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/pages-seo`}><HiUserGroup className='sidebarIconsetBox' />Pages SEOs</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href={`${BASE_URL}admin/sitemaps`}><HiUserGroup className='sidebarIconsetBox' />Sitemaps</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={logout}><HiUserGroup className='sidebarIconsetBox' />Logout</Nav.Link>
                    </Nav>
                </Accordion>
            </div>
        </div>
    )
}

const mapStateToProps = (state: { user: User }) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(Index);