import React, { useEffect, useState } from 'react'
import './blog-slider-style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios';
import BlogCard from '../common/cards/BlogCard';

function Index() {
    const [seos, setSeos] = useState([]);
    useEffect(() => {
        fetchSeoList();
    }, []);
    const fetchSeoList = async () => {
        const { data } = await axios.get(`getseolisthome?page=1&size=6`);
        setSeos(data.data);
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return (
        <>
            <div className='CustContainer'>
                <div className='BlogDetailsHomeCov'>
                    <div className='HomepgTitleCov'>
                        <h3>Latest News</h3>
                    </div>

                    <div className='BlogDetailsNewsMain'>
                        <Slider {...settings}>
                            {seos && seos.length > 0 ? (
                                seos.map((data1: any) => (
                                    <>
                                        <BlogCard
                                            key={data1.id}
                                            slug={data1.slug}
                                            description={data1.description}
                                            image={data1?.image}
                                            category_name={data1.category.name}
                                            h1_title={data1.h1_title}
                                            id={data1?.id}
                                            created_at={data1.updated_at || data1.created_at}
                                        />
                                    </>
                                ))
                            ) : (
                                <div className='text-center'>
                                    No News found
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index