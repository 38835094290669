import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sparepartsdetails-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { BsInfoCircleFill } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import { FaCloudDownloadAlt } from "react-icons/fa";

import { PRV, ServiceBrochure } from '../../assets';

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function PressureReliefValve() {
    useEffect(() => {
        document.title = 'Pressure Relief Valve';
    }, []);

    const [nav1, setNav1] = useState<Slider | null>(null);
    const [nav2, setNav2] = useState<Slider | null>(null);
    
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>  
            <GetaQuote show={show} onHide={() => handleClose()} />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-spares">Transformer Spares</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Details</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ProddetailsBoxCov'>
                    <div className='ProddetailsBoxLeft'>
                        <ul>
                            <li className='active'><a href="/pressure-relief-valve">- Pressure Relief Valve (PRV)</a></li>
                            <li><a href="">- Buchholz Relay</a></li>
                            <li><a href="">- Oil Level Indicator (MOG)</a></li>
                            <li><a href="">- Silica Gel Breather</a></li>
                            <li><a href="">- Aircell (flexi separator)</a></li>
                            <li><a href="">- Rapid Pressure Rise Relay</a></li>
                            <li><a href="">- Transformer Valve</a></li>
                            <li><a href="">- Cooling Fan</a></li>
                            <li><a href="">- Transformer Oil</a></li>
                            <li><a href="">- RTCC Panel & Equipments</a></li>
                            <li><a href="">- Wheels ( Roller)</a></li>
                            <li><a href="">- Bushing & Accessories</a></li>
                            <li><a href="">- Flow Indicators</a></li>
                            <li><a href="">- Oring & Gasket</a></li>
                            <li><a href="">- Current Transformer </a></li>
                            <li><a href="">- Transformer Main Tank & Accessories</a></li>
                            <li><a href="">- Core (CRGO)</a></li>
                            <li><a href="">- Insulation Materials</a></li>
                            <li><a href="">- Radiators</a></li>
                            <li><a href="">- Tap changer</a></li>
                            <li><a href="">- Smart Breather</a></li>
                        </ul>
                    </div>
                    <div className='ProddetailsBoxRight'>
                        <div className='ProddetailsBoxSliderprod'>
                            <div className='ProddetailsBoxsldtxtLeft'>
                                <Slider className='TopSlidermainpd' asNavFor={nav2 ?? undefined} ref={(slider1) => setNav1(slider1)}>
                                    <div className='MainImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='MainImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='MainImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='MainImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                </Slider>
                                <Slider
                                    className='BotSlidermainpd'
                                    asNavFor={nav1 ?? undefined} ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >
                                    <div className='ThmImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='ThmImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='ThmImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                    <div className='ThmImgboxsld'>
                                        <img src={PRV} alt="PRV" />
                                    </div>
                                </Slider>
                            </div>
                            <div className='ProddetailsBoxsldtxtRight'>
                                <h1>Pressure Relief Valve (PRV)</h1>
                                <h6>Pressure Relief Valve (PRV) is designed to operate at a predetermined set pressure to protect the transformer from high oil pressure build up during fault conditions. This valve acts as a safety measure, preventing damage to the transformer and ensuring the stability of the electrical grid.</h6>
                                <p><BsInfoCircleFill /> For more details please send us enquiry</p>
                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>
                        </div>
                        <div className='ProddetailsOverview'>
                            <h3>Overview</h3>
                            <p>A pressure relief valve is a critical component of a power transformer, designed to release excess pressure that may build up within the transformer during normal operation or emergency situations. This valve acts as a safety measure, preventing damage to the transformer and ensuring the stability of the electrical grid.</p>

                            <p>The pressure relief valve operates by opening and allowing the release of pressurized gas or oil in the transformer when the pressure exceeds a predetermined limit. This limits the pressure within the transformer to a safe level, reducing the risk of damage to the electrical components and ensuring the stability of the power supply.</p>

                            <hr />

                        </div>
                        <div className="ProddetailsTechnil">
                            <h3>Technical Specification</h3>

                            <h6>Port Opening</h6>
                            <p>25, 50, 70 & 150mm nominal diameter.</p>

                            <h6>Operating Pressure:(Any One Value)</h6>
                            <p>0.28, 0.35, 0.42, 0.49, 0.56, 0.70, 0.80 kg/cm² 1.4 kg/cm²</p>

                            <h6>Switch</h6>
                            <p>With Switch or Without Switch option Contacts of Switch: 1NO + 1NC OR 2NO + 2NC</p>

                        </div>
                    </div>
                </div>

            </div>

            <ConsultationForm />

            <BlogList />

            <Continent />
        </>
    )
}

export default PressureReliefValve