import React from 'react';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

type PaginationProps = {
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
    onPageChange: (pageNumber: number) => void;
    onItemsPerPageChange: (itemsPerPage: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
    totalItems,
    currentPage,
    itemsPerPage,
    onPageChange,
    onItemsPerPageChange,
}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    const visiblePages = 1;

    const handleClick = (pageNumber: number) => {
        onPageChange(pageNumber);
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleFirstClick = () => {
        onPageChange(1);
    };

    const handleLastClick = () => {
        onPageChange(totalPages);
    };

    const handleFirst3Click = () => {
        if (currentPage > 2) {
            onPageChange(1);
        } else {
            onPageChange(currentPage - visiblePages);
        }
    };

    const handleLast3Click = () => {
        if (currentPage < totalPages - 1) {
            onPageChange(totalPages);
        } else {
            onPageChange(currentPage + visiblePages);
        }
    };

    const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onItemsPerPageChange(parseInt(e.target.value));
    };
    let showing_start = (currentPage == 1) ? currentPage : ((currentPage - 1) * itemsPerPage) + 1;
    let showing_end = (totalItems > (Number(currentPage) * itemsPerPage)) ? Number(currentPage) * itemsPerPage : totalItems;
    return (
        <nav>
            <ul className="pagination">
                <li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
                    <button className="page-link" onClick={handlePrevClick}>
                        <GrFormPrevious />  Prev
                    </button>
                </li>
                {currentPage - 1 > visiblePages && (
                    <>
                        <li className={`page-item${currentPage === 1 ? ' active' : ''}`}>
                            <button className="page-link" onClick={handleFirstClick}>
                                1
                            </button>
                        </li>
                        {currentPage - 1 > visiblePages + 1 && (
                            <li className="page-item">
                                <button className="page-link" onClick={handleFirst3Click}>
                                    ...
                                </button>
                            </li>
                        )}
                    </>
                )}
                {pageNumbers
                    .filter(
                        (pageNumber) =>
                            pageNumber >= currentPage - visiblePages &&
                            pageNumber <= currentPage + visiblePages
                    )
                    .map((pageNumber) => (
                        <li
                            key={pageNumber}
                            className={`page-item${currentPage === pageNumber ? ' active' : ''}`}
                        >
                            <button className="page-link" onClick={() => handleClick(pageNumber)}>
                                {pageNumber}
                            </button>
                        </li>
                    ))}
                {currentPage < totalPages - visiblePages && (
                    <>
                        {currentPage < totalPages - visiblePages - 1 && (
                            <li className="page-item">
                                <button className="page-link" onClick={handleLast3Click}>
                                    ...
                                </button>
                            </li>
                        )}
                        <li className={`page-item${currentPage === totalPages ? ' active' : ''}`}>
                            <button className="page-link" onClick={handleLastClick}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
                <li className={`page-item${currentPage === totalPages ? ' disabled' : ''}`}>
                    <button className="page-link" onClick={handleNextClick}>
                        Next <GrFormNext />
                    </button>
                </li>
            </ul>
            <div className="ItempageSelectbox">
                <select
                    className="form-select"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={100}>100</option>
                    <option value={totalItems}>All</option>
                </select>
            </div>
            <div className="PageItemTotalbox Total float-end">
                <span className="page-link">
                    Showing {showing_start} to {showing_end} of {totalItems} entries
                </span>
            </div>

        </nav>
    );
};
export default Pagination;