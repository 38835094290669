import React, { useEffect, useState } from 'react';
import './spareparts-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FiArrowUpRight } from "react-icons/fi";
import Row from 'react-bootstrap/Row';
import { SparePartsImg1, SparePartsImg2, SparePartsImg3, SparePartsImg4, SparePartsImg5, SparePartsImg6, SparePartsImg7, SparePartsImg8, SparePartsImg9, SparePartsImg10, SparePartsImg11, SparePartsImg12, SparePartsImg13, SparePartsImg14, SparePartsImg15, SparePartsImg16, SparePartsImg17, SparePartsImg18, SparePartsImg19, SparePartsImg20, SparePartsImg21, SparePartsImg22, SparePartsImg23, SparePartsImg24, SparePartsImg25, SparePartsImg26, SparePartsImg27, SparePartsImg28, SparePartsImg29, SparePartsImg30, SparePartsImg31, SparePartsImg32, SparePartsImg33, SparePartsImg34, SparePartsImg35, SparePartsImg36, SparePartsImg38, SparePartsImg39, SparePartsImg40, SparePartsImg41, SparePartsImg42, SparePartsImg43, SparePartsImg44, TransformerSparesParts } from '../../assets';

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

function SparePartsList() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Spares';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/transformer-spares`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/transformer-spares`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Transformer Spares</li>
                        </ul>
                    </div>
                </div>
                <div className='CustContainer'>
                    <div className='sperpttopMainTitle'>
                        <h1>Transformer Spares</h1>
                        <h6>Get 100% Authentic & Genuine Transformer Spare Parts</h6>
                    </div>
                    <div className='sperpttopMainbox'>
                        <div className='sperpttopMainLeft'>
                            <p>End your search for Transformer replacement parts by choosing VoltageShift as your <span>Transformer Parts Manufacturer</span>. We pay attention to detail to manufacture every
                                Transformer Part, and they comply with Industry's highest quality standards.</p>
                            <p>As a trustworthy <span>Transformer Spare Parts Exporter & Manufacturer,</span> we manufacture and export 100%
                                Authentic & Genuine Transformer Spare parts on-site at the International Level in 45+
                                countries, including India, South Africa, Ghana, Singapore, Thailand, Hong Kong, Israel,
                                Iraq, Jordan, Germany, Italy, and Japan.</p>
                            <p>We strive to ensure that our clients get all the required <span>Transformers Parts</span> delivered within
                                the dedicated time frame to keep their Transformers functioning smoothly and operations
                                intact.</p>
                            <p>As a leading <span>Transformer Spare Parts Supplier</span>, we sell an extensive range of Transformer
                                Spare Parts from transformer oil, tap changer, windings, Buchholz Relay, insulating
                                materials, Radiators, cooling fans, silica gel breathers, condenser bushing, pressure relief
                                valves, and more.</p>
                            <p>We have a team of highly qualified and skilled experts who will professionally install/replace <span>Transformer Spare Parts</span> as part of our Transformer Repairing & Preventive Maintenance
                                Services. Choose us and give your Transformers legitimate <span>Transformer Accessories</span> to
                                keep them functioning like new ones.</p>
                        </div>
                        <div className='sperpttopMainRight'>
                            <img src={TransformerSparesParts} alt="Transformer Spares" />
                        </div>
                    </div>
                </div>
                <div className='CustContainer'>
                    <div className='SparePartsMainTitle'>
                        <h2>Our Transformer Spare Components</h2>
                        <p>At VoltageShift, we manufacture and sell comprehensive <span>Transformer Components</span> to
                            ensure our clients get all the replacement parts at a one-stop destination. As a <span>Transformer
                                Spares Exporter</span>, we export to 45+ countries. The following are the <span>Transformer Spare
                                    Components</span> we deal in.</p>
                    </div>
                    <div className='SparePartsMainCov'>
                        <Row>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg1} alt="Pressure Relief Valve or Pressure Relief Device (PRV)" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Pressure Relief Valve or Pressure Relief Device (PRV)</h3>
                                        <p>Pressure Relief Valve (PRV) is safeguarding the transformer against excessive oil pressure accumulation in the event of a fault.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg2} alt="Buchholz Relay or Gas Actuated Relay" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Buchholz Relay or Gas Actuated Relay</h3>
                                        <p>The Buchholz Relay functions as an alert system sensitive to internal transformer faults.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg3} alt="Oil Level Indicator or Magnetic Oil Level Gauge (MOG)" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Level Indicator or Magnetic Oil Level Gauge (MOG)</h3>
                                        <p>Magnetic Oil Level Indicator (MOG) is designed to display the transformer insulating oil level in the conservator of transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg22} alt="Porcelain Bushing" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Porcelain Bushing</h3>
                                        <p>Porcelain bushing is an insulating structure that enables an energized, current-carrying conductor to pass through the grounded tank of the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg14} alt="On Load Tap changer or OLTC" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>On Load Tap changer or OLTC</h3>
                                        <p>On-Load Tap Changer adjusts transformer voltage to stabilize output amidst load and supply variations.</p>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg23} alt="Radiator Valve or Shut off Valve" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Transformer Radiator Valve or Shut off Valve</h3>
                                        <p>Transformer radiator valve is a control device used to regulate the flow of cooling oil through the transformer's radiator, ensuring efficient cooling and temperature control.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg24} alt="Drain Valve or Gate Valve or Globe Valve" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Transformer Drain Valve or Gate Valve or Globe Valve</h3>
                                        <p>Transformer drain valve are essential control devices used for managing the draining of oil or controlling the flow within a transformer system.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg4} alt="Silica Gel Breather" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Silica Gel Breather</h3>
                                        <p>A Silica Gel Breather contains silica gel crystals to absorb moisture from the air entering the transformer's conservator tank, preserves transformer oil quality by moisture absorption.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg18} alt="Oil and Winding Temperature Indicator" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil and Winding Temperature Indicator</h3>
                                        <p>Analog Oil and Winding Temperature Indicator is a gauge that provides a visual representation of the transformer oil's and winding temperature on a traditional analog scale.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg8} alt="Transformer Oil or Insulating Oil" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Transformer Oil or Insulating Oil</h3>
                                        <p>Transformer oil is a specialized dielectric fluid used in transformers to provide electrical insulation and efficient cooling, ensuring safe and reliable operation of the transformer.</p>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg12} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Gasket and Oring Sets</h3>
                                        <p>Gasket and Oring Sets are sealing materials placed between mating components to prevent leakage of oil or gas, Preserving transformer integrity and performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg7} alt="Transformer Cooling Fan" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Cooling Fan</h3>
                                        <p>Transformer cooling fan is a mechanical device used to enhance the cooling process by increasing airflow over the transformer's radiator or cooling fins.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg10} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Surge Relay (OSR)</h3>
                                        <p>The Oil Surge Relay acts as a protective device, responding to sudden oil surges within a transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg5} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Aircell or Flexi Separators</h3>
                                        <p>Aircell is engineered to serve as a partition, keeping unwanted contaminants in the atmosphere from coming into contact with transformer oil inside a conservator.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg25} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Conservator Protection Relay (CPR)</h3>
                                        <p>The Conservator Protection Relay (CPR) is a dedicated protective device created to identify Aircell leaks by generating electrical signals.</p>
                                    </div>
                                </div>
                            </div>


                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg26} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Gas Collecting Device (GCD)</h3>
                                        <p>Gas Collecting Device is an attachment connected to the gas collection petcock of the Buchholz Relay, Easily collect Gas and Oil samples from the relay.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg38} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>CT Terminal Box (TBX)</h3>
                                        <p>Terminal Box (TBX) provides a quick, reliable connection solution for linking CT wires inside the transformer to external measurement systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg27} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>CFT Terminal Box (CFT)</h3>
                                        <p>CFT is intended for establishing a ground connection between the transformer's core and frame and the tank.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg28} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Flow Indicator</h3>
                                        <p>The Flow Indicator is primarily crafted as a safety device, ensuring an electrical signal in case of forced liquid circulation failure within the pipeline.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg41} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Prismatic Oil Level Indicators</h3>
                                        <p>Prismatic Oil Liquid Level Indicators for Transformers are monitoring devices that display the current oil level in the transformer's conservator tank.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg29} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Pressure Gauge</h3>
                                        <p>A Pressure Gauge is a measuring device that displays the pressure of the insulating oil within the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg30} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Off Load Tap changer or De energised Switch</h3>
                                        <p>An Off-Load Tap Changer is a switching mechanism used to adjust the transformer's tap settings without load or power flow, Optimizing voltage when transformer's inactive.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg31} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Remote Indicator (OTI/WTI)</h3>
                                        <p>A Remote Indicator is a monitoring device that displays the Oil Temperature Indicator (OTI) and Winding Temperature Indicator (WTI) readings from a remote location.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg32} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Automatic Voltage Regulator (AVR)</h3>
                                        <p>An Automatic Voltage Regulator is an essential control device that automatically adjusts and stabilizes the output voltage to maintain a consistent and reliable electrical supply.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg33} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Annunciator</h3>
                                        <p>An Annunciator is a visual or audible alert system that provides notifications or warnings about specific conditions or events occurring in the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg34} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Tap Position Indicator</h3>
                                        <p>A Tap Position Indicator is a device that visually or electronically indicates the current position of the tap changer on the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg11} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>RTCC Panel</h3>
                                        <p>Remote Tap Changer Control Panel is a control and monitoring system that remotely regulates the tap changer settings on a transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg35} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Marshalling Box</h3>
                                        <p>A Marshalling Box for transformers is an enclosure or junction box used to organize and route various control and monitoring cables.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg9} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Radiator</h3>
                                        <p>A radiator for transformers is a heat exchange device that dissipates excess heat generated during the transformer's operation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg15} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Bushing CT (BCT)</h3>
                                        <p>A Bushing CT (Current Transformer) for transformers is an instrument that measures the electrical current passing through a transformer bushing.</p>
                                    </div>
                                </div>
                            </div>



                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg36} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Copper Flat</h3>
                                        <p>Copper flat for transformers refers to flat-shaped copper conductors used within transformers for efficient electrical conductivity.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg40} alt="Transformer Oil Pump" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Oil Pump</h3>
                                        <p>A transformer oil pump is a mechanical device responsible for circulating and maintaining the proper flow of insulating oil within the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg19} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Roller or Transformer Wheels</h3>
                                        <p>Rollers or transformer wheels are mechanical components attached to the base of a transformer, allowing for easy mobility and transportation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg39} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Epoxy Bushing</h3>
                                        <p>An epoxy bushing for transformers is an insulating component made of epoxy resin, which provides insulation and conductor support.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg42} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Rapid Pressure Rise relay</h3>
                                        <p>A Rapid Pressure Rise Relay is a protective device designed to detect and respond to sudden increases in pressure within the transformer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg43} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Thermometer</h3>
                                        <p>A Thermometer for transformers is an instrument used to measure and display the temperature of the transformer's oil or winding.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='SparePartsMainIner'>
                                <div className='SparePartsBoxdata'>
                                    <div className='SparePartsBoxImg'>
                                        <img src={SparePartsImg44} alt="" />
                                    </div>
                                    <div className='SparePartsBoxDetails'>
                                        <h3>Heat Exchanger</h3>
                                        <p>A Heat Exchanger for transformers is a component that helps regulate the temperature of the transformer's insulating oil by dissipating excess heat.</p>
                                    </div>
                                </div>
                            </div>


                        </Row>
                    </div>
                </div>

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. Which are the primary Transformer Spare Parts?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Transformers have several components in them. However, the primary ones are core,
                                        transformer oil, tap changer, windings, Buchholz Relay, insulating materials, explosion vent,
                                        cooling tubers, breather, condenser bushing, and valves.</p>
                                    <p>Out of all, only core, transformer oil, windings, and insulating materials are present in almost
                                        every transformer. And the rest components in transformers above 50 KVA Power Rating.</p>
                                    <p>At VoltageShift, we sell comprehensive Transformer Spare Parts for all ranges, making us
                                        your one-stop destination. Partner with us as your <span>Transformer Spares Supplier</span> and get
                                        100% authentic & genuine spare parts at cost-effective pricing. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. What is Buchholz Relay in Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Buchholz Relay is placed in a cast housing over the connecting pipe that flows from the
                                        primary tank to the conservator. It senses the faults within the transformer. It operates through
                                        the emitted gases due to the deterioration of the Transformer Oil during internal issues.</p>
                                    <p>Buchholz Relay has two contact sets. One is used to manage slow accumulations of gas. The
                                        other handles bulk displacement of Transformer Oil during significant internal problems.</p>
                                    <p>If you are looking for a highly reliable <span>Transformer Spares Distributor</span>, VoltageShift is
                                        your go-to destination. We manufacture and sell an extensive range of Transformer Spare
                                        Components, including Buchholz Relay. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. What is a Tap Changer in a Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Tap Changer is used for balancing the Transformer's voltage variations. They are of two
                                        types - On Load and Off Load. In On Load Tap Changers (OLTC), you can change tapping
                                        without isolating the Transformer from the supply. In contrast, in Off Load Tap Changers,
                                        you are required to disconnect tapping from the supply to change it.</p>
                                    <p>If you are searching for a <span>Transformer Spares Manufacturer</span> selling Tap Changers,
                                        VoltageShift is your go-to destination. We sell premier quality and 100% authentic
                                        Transformer Tap Changers. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. What is Silica Gel Breather in Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>Silica Gel Breathers</span>, also known as Dehydrating Breathers, act as dying agents in
                                        Transformers. Its primary purpose is to prevent and control moisture content within a
                                        breather transformer from coming in connection with an Electrical Transformer's Insulating
                                        Liquid. It protects the internal components of the Transformer from damaging effects.</p>
                                    <p>Silica Gel Breathers are blue in color when they are new. However, if the color changes to
                                        light pink, it is a sign to the operators/workers to opt for replacement or regeneration of the
                                        Silica Gel in the Breather.</p>
                                    <p>As a leading silica gel breather supplier & manufacturer, VoltageShift offers a wide range of <span>Electrical Transformers Parts</span>, including Silica Gel Breathers that are non-toxic, inflammable, and DMF-free, ensuring corrosion prevention. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>5. What is Bushing in a Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A <span>Bushing in a Transformer</span> is an insulating structure to facilitate the flow of energized and
                                        current-carrying conductors via a Transformer grounded tank. In an electric power
                                        transformer, Bushing is a hollow electrical insulator enabling the safe passage of electrical
                                        conductors. Electrical Bushings are a pivotal part of <span>Power Transformer Parts</span>.</p>
                                    <p>In any range of Transformer, there are Low Voltage (LV) & High Voltage (HV) Bushing. The
                                        conducting rod of an LV Bushing carries a higher current than HV Bushing. LV Bushing is
                                        thicker due to its larger diameter than HV. They perform the crucial task of passing current at
                                        HV through enclosed components. The Oil is required in Bushing for the insulation and
                                        coolant.</p>
                                    <p>At VoltageShift, renowned transformer bushing suppliers & manufacturers, we sell 100% genuine and premier quality <span>Power Transformer Parts and Components</span>, including Bushing. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>6. What distinguishes a Transformer on Load Tap Changer from an Off Load Tap Changer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Transformer on Load Tap Changer (OLTC) enables voltage adjustments while the transformer is connected to the supply, ensuring continuous operation. Conversely, a Transformer Off Load Tap Changer requires disconnection from the power supply for voltage changes, offering a simpler and more cost-effective solution.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>7. Who is a leading oil pump manufacturer for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a premier oil pump manufacturer and supplier for transformers. We specialize in producing high-quality, durable oil pumps essential for transformer maintenance and efficiency. Our products guarantee authenticity and reliability, offering cost-effective solutions for your transformer needs. Contact us for a detailed quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>8. Who is a renowned transformer oil manufacturer offering authentic and high-quality transformer oil?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a distinguished transformer oil manufacturer and supplier, known for supplying 100% authentic and top-grade transformer oil. They ensure their products meet stringent quality standards, providing reliable and cost-effective solutions for your transformer needs. Contact VoltageShift for a quotation on their superior transformer oil.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>9. Why choose VoltageShift as transformer radiator supplier?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a leading transformer radiator manufacturer, offering top-quality, reliable radiators for various transformer types. As a one-stop destination for transformer spare parts, we ensure our radiators meet the highest standards, providing effective cooling solutions for transformers. Contact us for a detailed quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>10. What are Transformer Bushing Parts and their Importance in Transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Transformer Bushing Parts are essential insulating structures allowing energized conductors to pass safely through a Transformer's grounded tank. They play a crucial role in managing high and low voltage currents, ensuring safe current transmission and preventing electrical hazards. At VoltageShift, we offer premium quality Transformer Bushing Parts. Contact us for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>11. Who is a reliable transformer cooling fans manufacturer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift stands out as a premier cooling fan manufacturer & supplier for transformers. We specialize in producing top-quality, durable cooling fans essential for maintaining optimal transformer performance. Partner with us for your transformer cooling fan needs and receive exceptional products at cost-effective prices. Contact us for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>12. What is the Role of a Transformer Pressure Relief Valve?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Transformer Pressure Relief Valve is crucial for managing internal pressure. It ensures safety by releasing excess pressure from the transformer, preventing damage. VoltageShift offers top-quality Transformer Pressure Relief Valves, essential for maintaining transformer integrity. Contact us for durable, reliable valves.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>13. Who should you contact for reliable gasket supply and manufacturing?</Accordion.Header>
                                <Accordion.Body>
                                    <p>As a leading gasket supplier and manufacturer, VoltageShift is your premier destination. We offer a wide range of high-quality gaskets, ensuring durability and efficiency for all your needs. Contact us for a quotation to get 100% authentic and cost-effective gasket solutions.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>14. Who is a Leading O Ring Supplier and Manufacturer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>As a premier O Ring supplier and manufacturer, we at VoltageShift pride ourselves on providing high-quality, durable O Rings. Our products ensure optimal sealing solutions across various applications. Contact us for a quotation and experience our commitment to excellence and customer satisfaction.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />

            </div>
        </>
    )
}

export default SparePartsList