import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Wrapper from "../../components/Wrapper";
import { Category } from '../../models/category';
import { Link } from "react-router-dom";
import BTable from 'react-bootstrap/Table';
import Pagination from '../../components/pagination/index';
import { RiEditBoxLine, RiDeleteBinLine } from 'react-icons/ri';

import { GoPlus } from "react-icons/go";

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');
    const [category_cnt, setCategoriesCount] = useState(0);

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true);
            const { data } = await axios.get(`categories?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
            setCategoriesCount(data.length);
            setCategories(data);
            setLoading(false);
        };
        fetchCategories();
    }, [currentPage, itemsPerPage, searchText]);

    const del = async (id: number) => {
        if (window.confirm('Are you sure you want to delete?')) {
            try {
                await axios.delete(`categories/${id}?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
                setCategories(categories.filter((r: Category) => r.id !== id));
            } catch (error: any) {
                console.log(`${error.response.status}: ${error.response.message}`);
            }
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };
    return (
        <Wrapper>
            <div className="CardPedboxCover">
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Categories</h1>
                    </div>
                    <div className='AdmintopTitleRight'>
                        <div className='addeditBtnBoxTitle'>
                            <Link to={'/admin/category/create'} className="AddBtnSet"><GoPlus /> Add Category</Link>
                        </div>
                    </div>
                </div>
                <>
                    <div className='ComTableCardCov'>
                        <BTable striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th>Name</th>
                                    <th>Action</th>
                                    <th style={{ width: 35 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td></td>
                                        <td colSpan={6}>Loading...</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    <>
                                        {categories.length > 0 ? (
                                            categories.map((cate: Category) => {
                                                return (
                                                    <tr key={cate.id}>
                                                        <td></td>
                                                        <td>{cate.name}</td>
                                                        <td>
                                                            <Link to={`/admin/category/${cate.id}/edit`} role="button" className="ViewPageBtnCov"><RiEditBoxLine /></Link>
                                                            <button type="button" className="DownloadPageBtnCov" onClick={() => del(cate.id)}><RiDeleteBinLine /></button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td></td>
                                                <td colSpan={2}>No data found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </BTable>
                    </div>
                </>
                <div className='PaginationCustbox PaginationCusUser'>
                    <Pagination
                        totalItems={category_cnt}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
        </Wrapper>
    );
};

export default Categories;