import React, { useEffect, useState } from 'react';
import './services-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { TransformerServicesImg, InstallationAndCommissioning, TransformerTestingService, OilFiltration, OnSiteFaultTroubleshooting, OverhaulingService, RepairingService, AnnualMaintenanceContract, TurnkeyProjects, OurInfrastructure1, OurInfrastructure2, ServiceBrochure, InfrastructureImg1, InfrastructureImg2, InfrastructureImg3, InfrastructureImg4, InfrastructureImg5, InfrastructureImg6, InfrastructureImg7, InfrastructureImg8 } from '../../assets';
import Button from 'react-bootstrap/Button';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";

import { FiArrowUpRight } from "react-icons/fi";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import GetaQuote from "../../components/common/getquote/GetaQuote";
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';
function TransformerServices() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Services';
        fetchSeo();
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/transformer-services`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/transformer-services`}
            />
            <GetaQuote show={show} onHide={() => handleClose()} />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Transformer Services</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='TransfServpageixiimg'>
                    <div className='TransfServpaggImg'>
                        <img src={TransformerServicesImg} alt="Transformer Services" />
                    </div>
                    <div className='TransfServpaggTxt'>
                        <div className='TransfServpagTxtLeft'>
                            <h1>Transformer Services</h1>
                            <h2>Partner with the best in the industry for Comprehensive Transformer Services</h2>
                            <p>Give your Industrial, Commercial, & Residential, Facilities, and Infrastructure Projects
                                UAE's most reliable & efficient <span>Transformer Services</span>. At VoltageShift, we provide on-site
                                Transformer Services at the International Level, including installation & commissioning,
                                testing, repair, and maintenance.</p>
                            <p>Our <span>Transformer Maintenance and Repair</span> includes oil filtration, relocation, 24/7
                                emergency repairs, annual maintenance contracts, on-site fault troubleshooting, and more to
                                ensure you get everything for your Transformers at a one-stop destination.</p>
                            <p>We have a team of highly qualified & skilled engineers proficient in providing <span>Power
                                Transformer Maintenance</span> for projects of different sizes, scales, and complexity. Get your
                                Quotation by clicking on the icon & download the brochure to read in detail.</p>
                            <Button onClick={handleShow}>Get A Quotation</Button>
                            <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                        </div>
                        <div className='TransfServpagTxtReft'>
                            <h3>What do our Transformer Services include?</h3>
                            <h6>As a leading Transformer Repair Companies, we provide highly professional & first-rate Transformer Services that include the following:</h6>
                            <p><BsCheck /> All-Inclusive Installation, Commissioning, and Testing activities for a vast range of Transformers for up to 500 MVA Power Rating and 400 KV Voltage Class.</p>
                            <p><BsCheck /> Comprehensive Transformer Testing Services for transformers, relays, and switchyard equipment for up to 400 KV Voltage Class.</p>
                            <p><BsCheck /> Extensive Repairing Services for up to 100 MVA Power Rating to keep your Transformers functioning smoothly.</p>
                            <p><BsCheck /> Oil Filtration Services for up to 400 KV Voltage Class for outstanding performance & astonishing longevity.</p>
                            <p><BsCheck /> On-Site Overhauling Services for Transformers for up to 100 MVA Power Rating by our proficient Engineers</p>
                            <p><BsCheck /> Pre-Eminent Annual Maintenance Contracts include all required Transformer Maintenance for excellent & seamless operation of transformers.</p>
                            <p><BsCheck /> Reliable Turnkey Project Transformer Services for up to 66 KV Voltage Class, including Installation, Commissioning, and Transformer Testing.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='OurservofferBoxCov'>
                <div className='CustContainer'>
                    <div className='OurservofferBoxTitle'>
                        <h3>Our Transformer Service Offerings</h3>
                        <p>As a fast-growing <span>Transformer Service Provider</span>, we comprehend our client's transformer
                            application requirements and accordingly design tailored solutions to ensure their transformer
                            efficiency, tremendous performance, and extended lifespan. The following are our
                            Transformer Service Offerings.</p>
                    </div>
                    <div className='OurservofferBoxSrvs'>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/installation-and-commissioning">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={InstallationAndCommissioning} alt="Installation and Commissioning" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Installation and Commissioning</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Installation and Commissioning</h3>
                                    <p><BsCheck /> On-site Installation & Commissioning at the International Level in 45+ Countries</p>
                                    <p><BsCheck /> Carried out by our highly Competent & Experienced Installers</p>
                                    <p><BsCheck /> Training provided to your operators for proper usage & maintenance</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/transformer-testing-service">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={TransformerTestingService} alt="Transformer Testing Service" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Transformer Testing Service</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Transformer Testing Service</h3>
                                    <p><BsCheck /> Our Experts are available 24/7 for Testing Services & Related Assistance</p>
                                    <p><BsCheck /> Breaker Testing to evaluate Breaker's performance & prevent interruptions</p>
                                    <p><BsCheck /> Relay Testing to keep your relays protected from faults and breakdowns</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/oil-filtration">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={OilFiltration} alt="Oil Filtration" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Oil Filtration</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Oil Filtration</h3>
                                    <p><BsCheck /> Usage of Advanced Oil Filtration Technology & Tools to remove impurities from the
                                        Transformer Oil</p>
                                    <p><BsCheck /> On-Site Oil Filtration Services, Testing & Analysis ensure it complies with Industry's
                                        Standards.</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/on-site-fault-troubleshooting">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={OnSiteFaultTroubleshooting} alt="On Site Fault Troubleshooting" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>On Site Fault Troubleshooting</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>On Site Fault Troubleshooting</h3>
                                    <p><BsCheck /> Customized Diagnostic Plan for On-Site Fault Troubleshooting</p>
                                    <p><BsCheck /> Determining appropriate corrective measures to resolve the issue</p>
                                    <p><BsCheck /> Maintaining Detailed Documentation to prevent future risks</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/overhauling-services">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={OverhaulingService} alt="Overhauling Service" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Overhauling Service</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Overhauling Service</h3>
                                    <p><BsCheck /> Thorough Inspection and Assessment of the Transformer's Condition</p>
                                    <p><BsCheck /> Professional Cleaning & Replacement of the Transformer's Components</p>
                                    <p><BsCheck /> Insulation Resistance Test of Transformer & Commissioning</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/repairing-service">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={RepairingService} alt="Repairing Service" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Repairing Service</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Repairing Service</h3>
                                    <p><BsCheck /> Careful Inspection and Assessment to detect the root cause of the issue</p>
                                    <p><BsCheck /> Preparing Bespoke Plan to fix the problem after Inspection & Assessment</p>
                                    <p><BsCheck /> Transformer Oil Analysis to detect any contaminants or degradation</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/annual-maintenance-contract">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={AnnualMaintenanceContract} alt="Annual Maintenance Contract" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Annual Maintenance Contract (AMC)</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Annual Maintenance Contract (AMC)</h3>
                                    <p><BsCheck /> Regular Inspections to ensure the transformers are functioning smoothly</p>
                                    <p><BsCheck /> Scheduled Maintenance for Oil Filtration and Bushing & Contact cleaning</p>
                                    <p><BsCheck /> 24/7 Emergency Service to address & resolve the issue upfront</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                        <div className='OurservofferBoxSrvIner'>
                            <a href="/turnkey-projects">
                                <div className='OurservofferBoxSrvImg'>
                                    <img src={TurnkeyProjects} alt="Turnkey projects" />
                                </div>
                                <div className='OurservofferBoxSrvTxt'>
                                    <h3>Turnkey projects</h3>
                                    <p>Learn More <FiArrowUpRight /></p>
                                </div>
                                <div className='OurservofferBoxSrvBox'>
                                    <h3>Turnkey projects</h3>
                                    <p><BsCheck /> For Transformer & Switchgear Industry for upto 400 KV Voltage Class</p>
                                    <p><BsCheck /> Includes Installation, Testing, and Commissioning of Transformer</p>
                                    <p><BsCheck /> End-to-End Transformer Solutions for Solar & Substation Projects</p>
                                    <h6>Learn More <FiArrowUpRight /></h6>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <div className='OurInfrsDatacov'>
                    <h2>Our infrastructure</h2>

                    <Slider {...settings}>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg1} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Filtration Machine</h4>
                                    <p>Upto 12000 LPH Ultra High Vacuum Filtration Machine.</p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg2} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Vacuum Machine</h4>
                                    <p>5000LPM</p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg3} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Dry Air Generator</h4>
                                    <p>65 CFM To 100 CFM upto achieving -60 degrees celsius dew point</p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg4} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>NAS Filtration System</h4>
                                    <p>9000 Liter NAS Filtration System</p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg5} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Dew Point Meter</h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg6} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>PPM Meter</h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg7} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Oil BDV Kit</h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className='OurInfrsDataMain'>
                            <div className='OurInfrsDataIner'>
                                <img src={InfrastructureImg8} alt="Filtration Machine" />
                                <div className='OurInfrsDataTxt'>
                                    <h4>Online Particle Counter Testing</h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is a Transformer Insulation Resistance Test?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Insulation Resistance Test tests the transformer's winding insulation integrity. Better
                                    Insulation means lower leakage currents & enhanced performance and safety.</p>
                                <p>It measures high <span>Transformer Insulation Resistance</span> because it's an insulator and requires
                                    high voltage for testing. It is recommended to follow safety guidelines & wear protective gear
                                    while conducting the test.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Why is Transformer Maintenance Important?</Accordion.Header>
                            <Accordion.Body>
                                <p>Transformer Maintenance should always be addressed. It is a crucial aspect to keep your
                                    Transformer up and running smoothly post-installation.</p>
                                <p>There are numerous benefits of Transformer Maintenance, such as enhancing its safety &
                                    efficiency, reducing unforeseen significant breakdown costs, detecting issues beforehand,
                                    extending its lifespan, and giving outstanding output in the long run.</p>
                                <p>For <span>Transformer Service & Maintenance</span>, don't look beyond VoltageShift. We provide
                                    comprehensive Transformer Servicing, including <span>Substation Transformer Repair</span>. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What is Transformer Oil Filtration?</Accordion.Header>
                            <Accordion.Body>
                                <p>The <span>Transformer Oil Filtration process</span> involves removing dissolved moisture, gasses, and
                                    sludge to boost its performance & quality. <span>Transformation Oil must be changed annually</span>
                                    for 132 KV Transformers and once in two years for Transformers below & above 132 KV</p>
                                <p>If Transformation Oil is not changed for longer periods, it will be susceptible to degradation
                                    & containments inside it, such as exposure to acid, moisture, and dust. It will affect its
                                    functioning and might damage the transformers in the long run.</p>
                                <p>VoltageShift is your one-stop destination for <span>Transformer Oil Filtration Services</span>. <a href="/contact-us" target="_blank">Contact us</a> for a free consultation and quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default TransformerServices