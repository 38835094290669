import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import Form from 'react-bootstrap/Form';
import TextEditor from '../../components/editor/ckeditor';
import { Category } from '../../models/category';
import { ToastContainer } from 'react-toastify';
import { errorToast, successToast } from '../../utils/toastrUtils';
import { Col, Row } from 'react-bootstrap';

const SeoCreate = () => {
    const [pagename, setPagename] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [news_img, setNewsImg] = useState('');
    const [category, setCategory] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [meta_title, setMetaTitle] = useState('');
    const [meta_description, setMetaDescription] = useState('');
    const [meta_keyword, setMetaKeyword] = useState('');
    const [focus_keyword, setFocusKeyword] = useState('');
    const [og_title, setOgTitle] = useState('');
    const [og_description, setOgDescription] = useState('');
    const [og_image, setOgImage] = useState('');
    const [image_alt_tag, setImageAltTag] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('categories');
                setCategoryList(data);
                setPagename('news');
            }
        )();
    }, []);

    const handleEditorChange = (content: string) => {
        if (content !== null && content !== undefined) {
            let html = '';
            const html_arr = JSON.parse(content);
            html_arr.blocks.map((myRes: any) => {
                html += myRes.text;
            })
            setDescription(html);
        }
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const { data } = await axios.post('seos', {
                pagename: pagename,
                h1_title: title,
                slug: slug,
                description: description,
                image: news_img,
                category: category,
                meta_title: meta_title,
                meta_description: meta_description,
                meta_keyword: meta_keyword,
                focus_keyword: focus_keyword,
                og_title: og_title,
                og_description: og_description,
                og_image: og_image,
                image_alt_tag: image_alt_tag
            });
            const seoId = data.id;
            if (selectedFiles !== null) {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('image', selectedFiles[i]);
                }
                const response = await axios.post('upload', formData);
                const url = response.data.result;
                await axios.put(`seos/${seoId}`, {
                    image: url,
                    og_image: url,
                });
            }
            /* const seoId = data.id;
            if (selectedFiles !== null) {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    const file = selectedFiles[i];
                    const image = new Image();
                    const loadImage = new Promise((resolve, reject) => {
                        image.onload = resolve;
                        image.onerror = reject;
                    });
                    image.src = URL.createObjectURL(file);
                    try {
                        await loadImage;
                        const { naturalWidth, naturalHeight } = image;
                        if (naturalWidth === 900 && naturalHeight === 450) {
                            formData.append('image', file);
                        } else {
                            errorToast('Image dimensions are not valid.Image required: 900x450');
                            return;
                        }
                    } catch (error) {
                        errorToast('Failed to load image.');
                        return;
                    }
                }
                const response = await axios.post('upload', formData);
                const url = response.data.result;
                await axios.put(`seos/${seoId}`, {
                    image: url,
                    og_image: url,
                });
            } */
            if (data && data.status === 200) {
                successToast('Success');
                setRedirect(true);
            } else {
                errorToast(data.message);
            }
        } catch (error: any) {
            console.log(error);

            console.log(`${error.request.status}: ${error.request.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/seo'} />
    }

    function generateSlug(title: any) {
        const slug = title
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-');
        return slug;
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedFiles(e.target.files);
        }
    };

    const onChange = (data: any) => {
        var span = document.createElement('span');
        span.innerHTML = data;
        setDescription((span.textContent || span.innerText).trim());
    }

    return (
        <Wrapper>
            <ToastContainer />
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Add SEO</h1>
                    </div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="pagename" className="form-label">Pagename</label>
                                    <input type="text" className="form-control" id="fullName" value='news' readOnly
                                        onChange={e => setPagename('news')}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="category" className="form-label">Category</label>
                                    {/* <input type="text" className="form-control" id="category" onChange={e => setCategory(e.target.value)} /> */}
                                    <select className="form-select" id="category"
                                        onChange={e => setCategory(e.target.value)}>
                                        <option>Please select category...</option>
                                        {categoryList.map((c: Category) => {
                                            return (
                                                <option key={c.id} value={c.id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control" id="title"
                                        onChange={e => { setTitle(e.target.value); setSlug(generateSlug(e.target.value)); }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="slug" className="form-label">Slug</label>
                                    <input type="text" className="form-control" id="slug" value={slug}
                                        onChange={e => setSlug(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="TextareaMainComset">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <TextEditor content={description} onChange={onChange} contentId={1} />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="InputMainComset ImgBlodupldboxset">
                                    <label htmlFor="news_img" className="form-label">Image</label>
                                    <input type="file" className="form-control" id="news_img" onChange={handleFileSelect} accept="image/png, image/jpeg, image/jpg" />
                                    <p className='textdangerNote'>Note: Image size should be width:900px and height: 450px.</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="image_alt_tag" className="form-label">Image Alt Tag</label>
                                    <input type="text" className="form-control" id="image_alt_tag" value={image_alt_tag}
                                        onChange={e => setImageAltTag(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_title" className="form-label">Meta Title</label>
                                    <input type="text" className="form-control" id="meta_title"
                                        onChange={e => setMetaTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_description" className="form-label">Meta Description</label>
                                    <input type="text" className="form-control" id="meta_description"
                                        onChange={e => setMetaDescription(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_keyword" className="form-label">Meta Keyword</label>
                                    <input type="text" className="form-control" id="meta_keyword"
                                        onChange={e => setMetaKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="focus_keyword" className="form-label">Focus Keyword</label>
                                    <input type="text" className="form-control" id="focus_keyword"
                                        onChange={e => setFocusKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="og_title" className="form-label">Og Title</label>
                                    <input type="text" className="form-control" id="og_title"
                                        onChange={e => setOgTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="og_description" className="form-label">Og Description</label>
                                    <input type="text" className="form-control" id="og_description"
                                        onChange={e => setOgDescription(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default SeoCreate;