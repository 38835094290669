import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';

import TransformersSlider from './TransformersSlider';

import Button from 'react-bootstrap/Button';

import { BiCheck } from "react-icons/bi";

import { PowerTransformImg5, ServiceBrochure } from '../../assets';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function SolarInverterDutyTransformer() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Solar Inverter Duty Transformer';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/solar-inverter-duty-transformer`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />

            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/solar-inverter-duty-transformer`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Solar Inverter Duty Transformer</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>

                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Solar Inverter Duty Transformer</h1>
                                <p>Shift towards Sustainability with our Skillfully Designed Solar Inverter Duty Transformer</p>
                            </div>
                        </div>

                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg5} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> Up to 15MVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 33KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> ONAN/ONAF</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Tap Changer :</span> Off Circuit or On Load Tap Changer Insulating</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IS 2026, IS 1180, IEC 60076</h4>

                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>

                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Take pride in using VoltageShift Skillfully Designed & Engineered <span>Solar Inverter Duty
                                Transformer</span> specifically made for solar power plants & solar energy systems applications.
                                As a reputable <span>Solar Inverter Duty Transformer Manufacturer</span>, we carefully select
                                premier quality raw materials to build highly durable and robust performance Transformers,
                                which can last for decades with our <span>Solar Inverter Transformer Maintenance</span>.</p>
                            <p>Our Solar Transformers are renowned for their efficiency, reliability, and cost-effectiveness.
                                As a leading <span>Solar Inverter Duty Transformer Manufacturing Company</span>, we have set a
                                benchmark to ensure all Solar Transformers are constructed according to IS & IEC's highest
                                standards.</p>
                            <p>We take maximum advantage of our extensive experience & knowledge by combining it with
                                the advanced technology & tools deployed in-house to give our clients first-rate <span>Solar
                                    Inverter Transformer Services</span>.</p>
                            <p>We provide comprehensive solutions from highly professional installation, testing, and
                                commissioning to <span>Solar Inverter Transformer Repair</span> to ensure your splendid experience
                                with us. Make a shift today with free consultation for a greener future</p>
                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Solar Inverter Duty Transformers Salient Features & Technical Specifications</h3>
                            <p>As a prominent <span>3Phase Solar Inverter Duty Transformer Manufacturer</span>, we pay attention
                                to detail in the technical specifications of Solar Transformers to give them the finest touch for
                                outstanding performance.</p>
                            <p>We provide a range of <span>Inverter Solar Transformers</span> to fulfill your industrial, commercial,
                                and residential properties' various requirements & needs. The following are the technical
                                specifications & features:</p>
                            <h4><BiCheck /> Power Rating of upto 15 MVA</h4>
                            <h4><BiCheck /> Voltage Rating of upto 33KV Class</h4>
                            <h4><BiCheck /> <span>Winding Material:</span> Copper or Aluminum</h4>
                            <h4><BiCheck /> <span>Cooling:</span> ONAN/ONAF</h4>
                            <h4><BiCheck /> <span>Type (Duty):</span> Indoor/Outdoor</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dy11Y11, Dy11, Y11y11 or as per specific requirement</h4>
                            <h4><BiCheck /> <span>Frequency Rating:</span> 50Hz or 60Hz</h4>
                            <h4><BiCheck /> <span>Tap Changer:</span> Off Circuit or On Load Tap Changer Insulating</h4>
                            <h4><BiCheck /> <span>Insulating Oil Types:</span> Mineral Oil/Ester Oil</h4>
                            <p>All our <span>3Phase Solar Inverter Duty Transformers</span> comply with IS 2026, IS 1180, and IEC
                                60076 standards. We export them to 45+ countries and provid <span>Solar Inverter Transformer
                                    Installation</span> on-site at the International Level.</p>
                        </div>
                    </div>

                </div>

                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Solar Inverter Duty Transformer Applications</h3>
                                <p>VoltageShift is a renowned <span>3 Phase Solar Inverter Duty Transformer Supplier</span> fulfilling
                                    various application needs of several industries. We comprehend the market requirements and
                                    numerous expectations of clients worldwide to manufacture high-quality <span>Solar
                                        Transformers</span> for businesses of different sizes & scales, including turnkey projects. The
                                    following is our Solar Transformer applications:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <h3>Salient Features</h3>
                                    <ul>
                                        <li><BiCheck /> Solar Power Plants</li>
                                        <li><BiCheck /> Wind Power Plants</li>
                                        <li><BiCheck /> Renewable Energy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>Our <span>Solar Wind Inverter Transformer</span> is a perfect choice for Solar Farms and Industrial
                                Areas. You can use our Solar Transformers for Commercial & Residential purposes. As a highly reliable <span>Solar Inverter Duty Transformer Service Provider</span>, we take care of every aspect, from installation to maintenance, to ensure your transformer functions excellently.</p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Advantages of Choosing Our Solar Transformer</h3>
                        <h6>By partnering with us as your <span>Solar Inverter Duty Transformer Supplier</span>, you will
                            experience maximum value for your investment. As a trustworthy <span>Solar Inverter Duty
                                Transformer</span>, we strive to deliver our clients stellar Solar Power Solutions, irrespective of
                            their country, by catering to 45+ countries. It includes comprehensive on-site services at the
                            International Level.</h6>
                        <h6>The following are the advantages of choosing our <span>Solar Transformer</span>:</h6>
                        <p><BiCheck /> Customized Oil Options to meet your application requirements, including mineral & ester
                            oils. Employment of advanced oil filtration technology for maintenance.</p>
                        <p><BiCheck /> 100% Authentic & Genuine <span>Solar Inverter Transformer Spare Parts</span> deliverable to 45+
                            countries for quick replacement & smooth functioning of transformers.</p>
                        <p><BiCheck /> Pre-Installation & Electrical Testing of the Transformer to verify all connections are
                            properly tightened and secured according to the Industry's Standards.</p>
                        <p><BiCheck /> Preventive Maintenance Services to prevent unforeseen breakdowns & extend transformer
                            lifespan through regular inspections, cleaning, testing, and oil filtration.</p>
                        <p><BiCheck /> On-Site Fault Troubleshooting by conducting a comprehensive diagnostic test & taking
                            corrective measures to resolve the issue & keep your operations running efficiently.</p>
                        <p><BiCheck /> Outstanding Capability to withstand harsh weather conditions, electrical surges, and
                            voltage load issues, making it the wisest & highly reliable investment option.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>

                <TransformersSlider />

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a Solar Inverter Duty Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Solar Inverter Duty Transformer is specifically designed for solar power applications. It
                                        transfers the DC Voltage and increases it to the rated output (AC). It has isolated windings to
                                        recognize fully isolated inputs. It is renowned for its low noise, low loss, and reduced energy
                                        cost due to less load</p>
                                    <p>If you are searching for a <span>15 MVA Solar Inverter Duty Transformer Manufacturer &
                                        Supplier</span>, VoltageShift is your one-stop destination. We specialize in designing & engineering <span>15MVA Solar Inverter Duty Transformers</span>. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is the Difference Between Solar Inverter Transformers & Electrical Transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Both Solar Inverters & Electrical Transformers perform similar functions. However,
                                        Electrical Transformers step up and down AC electricity from one voltage level to another. In
                                        contrast, Solar Inverter Transformers use DC electricity as their input and produce AC as
                                        their output.</p>
                                    <p>A Standard Electrical Transformer is designed to handle low levels of AC electricity. And
                                        Solar Inverter Duty Transformers are specifically designed to handle high-frequency levels.
                                        They are ideal for inductive load.</p>
                                    <p>If you are looking to buy a 33KV <span>Solar Inverter Duty Transformer</span>, VoltageShift is your
                                        go-to destination. We are a fast-growing <span>33KV Solar Inverter Duty Transformer
                                            Manufacturer & Supplier</span>. <a href="/contact-us" target="_blank">Contact us</a> to get your quotation and for a free consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default SolarInverterDutyTransformer