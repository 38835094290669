import axios from "axios";
import moment from "moment";
import React from "react";
import { FaUser } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import { RiPagesFill, RiTimeFill } from "react-icons/ri";
import LazyLoad from "react-lazyload";

export default function BlogCard(props: any) {
    const created_date = moment.utc(props.created_at).startOf('day').format('MMMM DD, YYYY');
    return (
        <>
            <div className='BlogDetailsNewsCov' key={props.id}>
                <div className='BlogDetailsNewsIner'>
                    <div className='BlogDetailsInerImg'>
                        <LazyLoad
                            placeholder={<div>Loading...</div>}
                        >
                            <img src={`${axios.defaults.baseURL}${props.image}`} alt={`${props.slug}`} />
                        </LazyLoad>

                    </div>
                    <div className='BlogDetailsInerTxt'>
                        <h3><a href={`/news/${props.slug}`}>{props.h1_title}</a></h3>

                        <div className="TimeandcateboxMaincovb">
                            <h6><RiTimeFill /> {created_date}</h6>
                            <h6><RiPagesFill />{props.category_name}</h6>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                        <a href={`/news/${props.slug}`}>Learn More <FiArrowUpRight /></a>
                    </div>
                </div>
            </div>
        </>
    );
}
