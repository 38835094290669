import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import { store } from './app/store';
import { configureStore } from "./redux/configureStore";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import './assets/css/color.css';
import './assets/css/main.css';
import './assets/css/responsive.css';

import Header from "./components/common/header";
import Footer from "./components/common/footer";
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import { API_BASE_URL } from './constants/commonConstants';

axios.defaults.baseURL = `${API_BASE_URL}`;
axios.defaults.withCredentials = true;

/* import request from 'superagent';
import { API_BASE_URL } from './constants/commonConstants';
const api = request(API_BASE_URL); */

const container = document.getElementById('root')!;
const root = createRoot(container);

const isBackendRoute = window.location.pathname.startsWith('/admin');

const store = configureStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {isBackendRoute ? null : <Header />}
      <HelmetProvider>
        <App />
      </HelmetProvider>
      {isBackendRoute ? null : <Footer />}
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
