import axios from "axios";
import moment from "moment";
import React from "react";
import { IoCalendar } from "react-icons/io5";

export default function BlogListCard(props: any) {
    const created_date = moment.utc(props.created_at).startOf('day').format('MMMM DD, YYYY');
    return (
        <>
            <div className='BlogDetRecePotListCov'>
                <a href={`/news/${props.slug}`}>
                    <div className='BlogDetRecePotListImg'>
                        <img src={`${axios.defaults.baseURL}${props.image}`} alt={`${props.slug}`} />
                    </div>
                    <div className='BlogDetRecePotListTxt'>
                        <h3>{props.h1_title}</h3>
                        <p><IoCalendar /> {created_date}</p>
                    </div>
                </a>
            </div>
            <hr />
        </>
    );
}
