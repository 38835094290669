import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, TurnkeyProjectsDetails, Count1, Count2, Count3, Count4, Count5, Count6, Count7, OurProjectsImg1, OurProjectsImg2, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { BsCheck } from 'react-icons/bs';
import Metatags from '../../components/meta/metatags';
import axios from 'axios';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function TurnkeyProjects() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Turnkey projects';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/turnkey-projects`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/turnkey-projects`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Turnkey projects</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={TurnkeyProjectsDetails} alt="Turnkey projects" />
                            <h1>Turnkey projects</h1>
                            <h6>Comprehensive Transformer Services for Turnkey Projects for upto 400 KV Voltage Class</h6>
                            <p>Give your Turnkey Projects the most reliable Turnkey <span>Transformer Installation Services in
                                UAE</span>. At VoltageShift, we take care of the Installation, Testing, and Commissioning of the
                                Transformers on-site at the International Level in 45+ countries, including India, Singapore,
                                Hong Kong, Malaysia, Thailand, Iraq, Iran, Israel, Jordan, and Japan.</p>
                            <p>We provide Turnkey Transformer Installation Services for several industries and sectors,
                                including Substations, Solar Power Plants, and Solar Power Stations. We provide
                                comprehensive Solar Power Plants services, including <span>Solar Power Plant Installation
                                    Services, Building Solar Power Plants</span>, and <span>Solar Power Plant Construction</span>.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>Why Choose Our Service</h3>
                                <p>We also offer the Design and Installation of EHV/HV Substations, EPC Solutions for Solar
                                    Projects, Solar PhotoVoltaic Power Station, Switchgear Turnkey Projects, Turnkey HV &
                                    MV EPC Services, and EHV/HV Works Service. We serve Air and Gas Insulated Companies
                                    by taking care of all aspects of Installation for their Air and Gas Insulated Substation.</p>
                                <p>Partner with us for comprehensive Transformer Installation Services for Turnkey and various
                                    project requirements.</p>
                            </div>
                        </div>

                    </div>
                    <div className='ServiDetailsPageRight mobileSideRemove'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li className='active'><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>

            <div className='OverProcesBoxBg'>
                <div className='CustContainer'>
                    <div className='OverProcesBoxTitle'>
                        <h2>Our Turnkey Project Execution Process</h2>
                        <p>At VoltageShift, we follow a set procedure for delivering <span>Turnkey HV/LV Transformer
                            Solutions</span> and other services for new and upgraded power systems. The following is our
                            process for End-to-End Turnkey Projects Execution</p>
                    </div>
                    <div className='OverProcesBoxMain'>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count1} alt="01" />
                                <h3>Initial Consultation</h3>
                                <p>Meeting with the client to comprehend their requirements and project scope.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count2} alt="02" />
                                <h3>Feasibility Study</h3>
                                <p>Conducting a Feasibility Study to ascertain the project's technical and financial viability.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count3} alt="03" />
                                <h3>Design</h3>
                                <p>Formulating a detailed and well-documented design plan, including cabling, circuits, and
                                    other essential components.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count4} alt="04" />
                                <h3>Procurement</h3>
                                <p>Procuring premier quality required equipment, including copper & aluminum wirings for
                                    strength & reliability.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count5} alt="05" />
                                <h3>Installation</h3>
                                <p>Comprehensive Installation services for EHV & HV Substations, Solar PhotoVoltaic Power
                                    Stations, and Solar Power Plant Installation.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count6} alt="06" />
                                <h3>Testing</h3>
                                <p>Thorough Testing of the Transformer to ensure it is operating at its peak performance &
                                    giving value for investment.</p>
                            </div>
                        </div>
                        <div className='OverProcesBoxCov'>
                            <div className='OverProcesBoxIner'>
                                <img src={Count7} alt="07" />
                                <h3>Commissioning</h3>
                                <p>Commissioning the Transformer and training the operators about proper usage &
                                    maintenance before handing it over to the client.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='OurProjdetaMain'>
                <div className='CustContainer'>
                    <div className='OverProcesBoxTitle'>
                        <h2>High-End Projects Services</h2>
                        <p>As one of the leading <span>Turnkey Projects Services Providers in UAE</span>, we offer
                            comprehensive services, from planning, design, and construction, to maximize clients' project
                            value by optimally utilizing resources, capital, and time.</p>
                        <p>We deliver all the projects within the dedicated time frame and budget, adhering to the
                            Industry's Highest Quality Standards. We cater to the following High-End Projects</p>
                    </div>
                    <div className='OurProjdetaCov'>
                        <div className='OurProjdetaBoxImg'>
                            <img src={OurProjectsImg1} alt="EPC Solutions for Solar Projects" />
                        </div>
                        <div className='OurProjdetaBoxTxt'>
                            <h3>1. EPC Solutions for Solar Projects</h3>
                            <p>We provide cost-effective and highly reliable <span>Solar EPC Solutions</span> for Solar Power Plants
                                and Stations. It includes designing, engineering, procurement, construction of AC & DC
                                Portions, and civil construction as well.</p>
                            <p>We offer premier quality Turnkey Solutions for Solar Business Verticals for <span>Building Solar
                                Power Plants</span> based on Photovoltaic (PV) technology and Concentrated Solar Power (CSP)
                                Technology, both grid-connected and off-grid solutions. We cater to <span>Concentrating Solar
                                    Power Station and Concentrating Solar Power Plants</span>.</p>
                        </div>
                    </div>
                    <div className='servdetailsListBox'>
                        <h2>Our End-to-End EPC Solutions for Solar Projects include the following:</h2>
                        <ul>
                            <li><BsCheck /> Site Inspection and Assessment</li>
                            <li><BsCheck /> Designing and Engineering</li>
                            <li><BsCheck /> Procurement and Installation of HV & MV Switchgea</li>
                            <li><BsCheck /> HV/LV Panel and Inverters</li>
                            <li><BsCheck /> Verifying Power Evacuation and Distribution</li>
                            <li><BsCheck /> Repair and Maintenance</li>
                        </ul>
                    </div>
                    <div className='weundeboxMaincov'>
                        <p>We leverage our immense experience and knowledge to deliver highly reliable Turnkey Solar
                            EPC Solutions and Turnkey Switchgear Services for Solar Power Projects on-site at the
                            International Level in 45+ countries, irrespective of their size, scale, and complexity.</p>
                    </div>


                    <div className='OurProjdetaCov OurProjdetaboxset2'>
                        <div className='OurProjdetaBoxTxt'>
                            <h3>2. EHV/HV Substation Projects</h3>
                            <p>As a renowned <span>Turnkey Transformer Substations Service Provider in UAE</span>, we specialize
                                in Turnkey Electrical Contracts for Air Insulated Substation and Gas Insulated Substations of
                                various Power Ratings and Voltage Classes. We take care of all aspects, from concept to
                                commissioning.</p>
                        </div>
                        <div className='OurProjdetaBoxImg'>
                            <img src={OurProjectsImg2} alt="EPC Solutions for Solar Projects" />
                        </div>
                    </div>
                    <div className='weundeboxMaincov'>
                        <p>We utilize our extensive experience & expertise in designing electrical systems and project
                            management planning to deliver cost-effective and innovative solutions for new and upgrade
                            projects for up to 400KV Voltage Class. We provide <span>High Voltage Substations</span> for numerous
                            projects, such as real estate sectors, government entities, electricity utility companies, and
                            large corporate houses.
                        </p>
                    </div>
                    <div className='weundeboxMaincov'>
                        <p>We handle all aspects from <span>High Voltage Substation Design</span> to building and upgrading at the
                            International Level in 45+ countries. We have deployed cutting-edge technology and tools to
                            manage <span>Turnkey Transformer Substations</span> projects of different scales and sizes, regardless
                            of site challenges.</p>
                    </div>
                    <div className='weundeboxMaincov'>
                        <p>We execute the entire <span>Installation of HV and EHV Substations</span>, including designing &
                            engineering, procurement of equipment & supplies, site development & management,
                            supervision, erection, installation, testing, and commissioning.</p>
                    </div>
                    <div className='weundeboxMaincov'>
                        <p>Our End-to-End Solutions for <span>Turnkey Installation for Substations</span> encompass all civil &
                            mechanical activities without sub-contracting any part of it.</p>
                    </div>
                </div>
            </div>


            <div className='CustContainer DeskSideRemove'>
                <div className='ServiDetailsPageRight'>
                    <ul>
                        <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                        <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                        <li><a href="/oil-filtration">- Oil Filtration</a></li>
                        <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                        <li><a href="/overhauling-services">- Overhauling Service</a></li>
                        <li><a href="/repairing-service">- Repairing Service</a></li>
                        <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                        <li className='active'><a href="/turnkey-projects">- Turnkey projects</a></li>
                    </ul>
                    <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                    <ServiceCont />
                </div>
            </div>

            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <BlogList />

            <Continent />
        </>
    )
}

export default TurnkeyProjects