import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { FiArrowUpRight } from "react-icons/fi";
import { InstallationAndCommissioningImg, BreakerRelayTesting, TransformerTestingServiceDetails, ServiceBrochure } from '../../assets';
import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import { Accordion, Col, Row } from 'react-bootstrap';
import ServiceCont from './ServiceCont';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function TransformerTestingService() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Transformer testing service';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/transformer-testing-service`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/transformer-testing-service`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Transformer Testing Service</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={TransformerTestingServiceDetails} alt="Transformer Testing Service" />
                            <h1>Transformer Testing Service</h1>
                            <h6>Highly Compelling Testing Services for Transformers & Switchyard Equipment</h6>
                            <p>Give your Industrial, Commercial, and Residential Facilities VoltageShift highly effective &
                                competent <span>Transformer Testing Services</span> for up to 400 KV Voltage Classes. We have a team
                                of highly qualified and skilled experts proficient in carrying out thorough <span>Transformer
                                    Testing and Monitoring</span> for the Transformers and switchyard equipment (switchgear) on-site
                                at the International Level in 45+ countries.</p>
                            <p>As a leading <span>Power Transformer Testing Company</span>, we have deployed advanced
                                technology and tools in-house, which we utilize by blending them with our immense
                                experience to test & evaluate your Transformers & switchgear.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>We aim to ensure your Transformers operate excellently and adhere to the industry's
                                    standards. Through our Transformer Inspection Services, we strive to achieve excellence
                                    by preventing downtime caused by electrical failures to ensure your operations are running
                                    seamlessly & safely. Bank on us and get the most reliable Transformer Testing &
                                    Maintenance in UAE.</p>
                            </div>
                        </div>

                        <Row>
                            <Col md="12">
                                <div className='TranElctTestmaintextcov'>
                                    <h3>Transformer Electrical Testing:</h3>
                                    <p>At VoltageShift, we provide a comprehensive range of Transformer Electrical Testing that
                                        complies with the Industry's highest standards. Our highly qualified and competent experts
                                        are available 24/7 to assist you with <span>Electrical Transformer Testing</span>. The following are the components included in it:</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className='servdetailsListBox'>
                                    <ul>
                                        <li><BsCheck /> Voltage Ratio</li>
                                        <li><BsCheck /> Magnetic Balance</li>
                                        <li><BsCheck /> Magnetising Current</li>
                                        <li><BsCheck /> Insulation Resistance</li>
                                        <li><BsCheck /> Vector Group</li>
                                        <li><BsCheck /> Winding Resistance</li>
                                        <li><BsCheck /> Short Circuit</li>
                                        <li><BsCheck /> Tan Delta and Capacitance</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className='servdetailsListBox'>
                                    <ul>
                                        <li><BsCheck /> Sweep Frequency Response Analysis (SFRA)</li>
                                        <li><BsCheck /> CT Testing</li>
                                        <li><BsCheck /> Noise Level Measurement test</li>
                                        <li><BsCheck /> Vibration Level Measurement</li>
                                        <li><BsCheck /> Frequency Domain Spectroscopy by DIRANA</li>
                                        <li><BsCheck /> Partial Discharge Test (PD Test)</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className='TranElctTestmaintextcov'>
                                    <h3>Transformer Oil Sample Testing:</h3>
                                    <p>We provide Transformer Oil Sample Testing as part of our <span>Transformer Maintenance
                                        Services</span>, which include the following:</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className='servdetailsListBox'>
                                    <ul>
                                        <li><BsCheck /> Interfacial Tension (IFT)</li>
                                        <li><BsCheck /> Particle Count Testing</li>
                                        <li><BsCheck /> Dissolved Gas Analysis (DGA)</li>
                                        <li><BsCheck /> Furan Analysis</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className='servdetailsListBox'>
                                    <ul>
                                        <li><BsCheck /> Breakdown Voltage (BDV)</li>
                                        <li><BsCheck /> Moisture Contain (PPM)</li>
                                        <li><BsCheck /> Tan Delta</li>
                                        <li><BsCheck /> Resistivity</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <div className='breakadrelTestBox'>
                            <div className='breakadrelTestImg'>
                                <img src={BreakerRelayTesting} alt="Breaker and Relay Testing" />
                            </div>
                            <div className='breakadrelTestTxt'>
                                <h3>Breaker and Relay Testing</h3>
                                <p>Breakers and Relays are critical components of Transformer Power systems that ensure the
                                    safety & reliability of electrical energy transmission & distribution. As highly reputable, we
                                    provide <span>Transformer Breakers & Relays Testing Services</span> on-site at the International Level
                                    in 45+ countries to evaluate systems performance for identification of issues. And working
                                    on a course of action to fix them. The following are the components of our Breakers &
                                    Relays Service:</p>
                            </div>
                        </div>

                        <div className='brektedetailsMaincov'>

                            <h3>Breaker Testing</h3>
                            <p>We offer a vast range of services in our <span>Transformer Breakers Testing</span> for various types of
                                breakers, including low, medium, and high voltage breakers. We have designed a set of
                                testing procedures to evaluate & test the breaker's performance and functionality to ensure it
                                operates seamlessly without interruptions in current flow and faults. Our Breakers Testing
                                Service includes the following:</p>

                            <h4>Contact Resistance Testing</h4>
                            <p>We measure the breaker contacts' resistance to ensure they operate smoothly without
                                unnecessary breakdowns or interruptions.</p>

                            <h4>Timing Tests</h4>
                            <p>We evaluate the time the breaker takes to open and close, which helps to check the
                                synchronization between the breaker & the protective relay.</p>

                            <h4>Insulation Resistance Tests</h4>
                            <p>We monitor the breaker's insulation resistance to ensure it complies with the industry's
                                highest standards and best practices.</p>

                            <h4>High Voltage Tests</h4>
                            <p>In this test, we apply a high voltage to the breaker to ascertain whether it can withstand the
                                expected electrical stress without breaking down.</p>

                            <h4>Mechanical Operation Tests</h4>
                            <p>We assess whether the mechanical operation of the breaker is operating safely, reliably, and
                                efficiently without any faults & failures.</p>

                            <hr />

                            <h3>Relay Testing</h3>
                            <p>We offer <span>Transformer Relay Testing</span> for a wide range of protective relays employed in
                                power systems, including differential relays, distance relays, overcurrent relays, and more.
                                We have a set of testing procedures to analyze the relay's performance to detect and correct
                                faults in isolation. Our Relays Testing Services include:</p>

                            <h4>Function Testing</h4>
                            <p>In this test, we check the correct operation of the delay to ensure it is perfectly detecting
                                faults & accordingly initiate the right protective action.</p>

                            <h4>Timing Tests</h4>
                            <p>We measure the time relay takes to find and fix the faults in isolation. It also helps to verify
                                the synchronization between the relay and the breaker</p>

                            <h4>CT and PT Testing</h4>
                            <p>In this test, we evaluate the accuracy of the Current transformers (CT) and Potential
                                Transformers (PT) current & voltage levels used in the relay circuit.</p>

                            <h4>Insulation Resistance Tests</h4>
                            <p>In this test, we determine the insulation resistance of the relay circuit to ensure it complies
                                with the industry's highest safety standards & best practices.</p>

                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li className='active'><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>
            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. What is the Role of Switchgear and Relays during Failures and Issues?</Accordion.Header>
                            <Accordion.Body>
                                <p>An <span>Electric Transformer Switchgear</span> is connected to electric supply systems for power
                                    transmission and distribution in low & high-voltage power transformers. Its primary purpose
                                    is to de-power transformers during <span>Electrical Transformer Maintenance & Repair</span> for
                                    inspection and fixing issues.</p>
                                <p>Protective Switchgear consists of a relay & circuit breaker. Relay plays a crucial role during
                                    faults by closing the faulty circuit and disconnecting the defective line. It ensures the massive
                                    current flow doesn't damage the transformer & prevents supply disruption to the end
                                    consumers. Relays make sure the faulty part remains isolated and the rest functions
                                    appropriately, like usual.
                                </p>
                                <p>If you are searching for a Transformer Switchgear Testing and Relay Testing Provider,
                                    VoltageShift is your one-stop destination. We provide 24/7 Emergency Services and on-site <span>Transformer Repair Services</span>. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and free consultation.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What is Power Transformer Maintenance and Field Testing?</Accordion.Header>
                            <Accordion.Body>
                                <p>Power Transformers are renowned for lasting 2-3 decades and sometimes more. And it all
                                    depends on <span>Power Transformer Maintenance and Field Testing</span>. It helps to determine the
                                    status & evolution of the transformer over its lifespan.</p>
                                <p>It helps to assess whether the transformer is in appropriate condition to operate, its overload
                                    capacity, limit load, or needs parts replacement. And accordingly, perform a well-formulated
                                    maintenance program with a test protocol to get it at peak performance.</p>
                                <p>For <span>Transformer Inspection & Maintenance</span>, get in touch with VoltageShift, where we
                                    provide premier quality comprehensive transformer maintenance and field testing services. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and consultation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default TransformerTestingService