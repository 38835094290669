import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Wrapper from "../../components/Wrapper";
import { Role } from "../../models/role";
import { Link } from "react-router-dom";
import BTable from 'react-bootstrap/Table';
import Pagination from '../../components/pagination/index';
import { RiEditBoxLine, RiDeleteBinLine } from 'react-icons/ri';

import { GoPlus } from "react-icons/go";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const Roles = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');
    const [role_cnt, setRolesCount] = useState(0);

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true);
            const { data } = await axios.get(`roles?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
            setRolesCount(data.length);
            setRoles(data);
            setLoading(false);
        };
        fetchRoles();
    }, [currentPage, itemsPerPage, searchText]);

    const del = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            try {
                await axios.delete(`roles/${id}?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
                setRoles(roles.filter((r: Role) => r.id !== id));
            } catch (error: any) {
                console.log(`${error.response.status}: ${error.response.message}`);
            }
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    // const handleSearch = (e: React.KeyboardEvent<FormControlElement>) => {
    //     setSearchValue(e.currentTarget.value);
    // };

    return (
        <Wrapper>
            <div className="CardPedboxCover">
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Roles</h1>
                    </div>
                    <div className='AdmintopTitleRight'>
                        <div className='addeditBtnBoxTitle'>
                            <Link to={'/admin/roles/create'} className="AddBtnSet"><GoPlus /> Add Role</Link>
                        </div>
                    </div>
                </div>

                <>
                    <div className='ComTableCardCov'>
                        <BTable striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th>Name</th>
                                    <th>Action</th>
                                    <th style={{ width: 35 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td></td>
                                        <td colSpan={6}>Loading...</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    <>
                                        {roles.length > 0 ? (
                                            roles.map((role: Role) => {
                                                return (
                                                    <tr key={role.id}>
                                                        <td></td>
                                                        <td>{role.name}</td>
                                                        <td>
                                                            <Link to={`/admin/roles/${role.id}/edit`} role="button" className="ViewPageBtnCov"><RiEditBoxLine /></Link>
                                                            <button type="button" className="DownloadPageBtnCov" onClick={() => del(role.id)}><RiDeleteBinLine /></button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td></td>
                                                <td colSpan={2}>No data found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </BTable>
                    </div>
                </>
                <div className='PaginationCustbox PaginationCusUser'>
                    <Pagination
                        totalItems={role_cnt}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
        </Wrapper>
    );
};

export default Roles;