import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BiCheck } from "react-icons/bi";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';

import TransformersSlider from './TransformersSlider';

import Button from 'react-bootstrap/Button';

import { ServiceBrochure, PowerTransformImg1 } from '../../assets';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function PowerTransformer() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Power Transformer';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/power-transformer`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />
            
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/power-transformers`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Power Transformer</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>

                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Power Transformer</h1>
                                <p>Meticulously Designed & Engineered Power Transformer</p>
                            </div>
                        </div>

                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg1} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> 2.5 MVA to 50 MVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 132KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> ONAN/ONAF/OFAF</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Tap Changer :</span> Off Circuit or On Load Tap Changer Insulating</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IS 2026, IS 1180, IEC 60076</h4>

                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>


                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Partner with the most reliable <span>Power Transformer Manufacturer</span> and get a highly durable
                                & phenomenal performance Power Transformer. We manufacture them with premier quality
                                materials, including copper & aluminum winnings, tested for reliability, strength, and
                                efficiency to deliver value for money <span>Power Transformer Services</span> to the clients.
                            </p>
                            <p>As a fast-growing <span>Power Transformer Manufacturing Company</span>, we ensure all Power
                                Transformers are manufactured in compliance with IS & IEC's highest standards. We have
                                different MVA ratings for Power Transformers, including 2.5 MVA Power Transformers, 50
                                MVA Power Transformers, and 132 KV Power Transformers. You can select according to
                                your <span>Power Transformer Rating</span> requirements.</p>
                            <p>As a reputable <span>3 Phase Power Transformer Manufacturer</span>, we use our extensive
                                experience & knowledge to deliver cost-effective Power Transformer solutions from sales &
                                installation to repair & maintenance services to 45+ countries, ensuring our clients can
                                worry-free use our Power Transformer.</p>
                            <p>We sell 100% authentic & genuine <span>Power Transformer Spare Parts</span> to ensure your Power
                                Transformer works excellently after repair. Contact us for a Quotation and give your facility
                                supreme quality Power Transformer.
                            </p>

                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Power Transformer Key Features & Specifications</h3>

                            <h4><BiCheck /> Our <span>Power Transformer MVA Ratings</span> are 2.5MVA to 50MVA, and Voltage is up to
                                132 KV Class. </h4>
                            <h4><BiCheck /> Our <span>Three Phase Power Transformer</span> Frequency Rating is 50Hz or 60Hz.</h4>
                            <h4><BiCheck /> <span>Winding Material:</span> Copper or Aluminium </h4>
                            <h4><BiCheck /> <span>Cooling:</span> ONAN/ONAF/OFAF</h4>
                            <h4><BiCheck /> <span>Type (Duty):</span> Indoor/Outdoor</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dyn1/ Dyn5 / Dyn11 or as per specific requirement</h4>
                            <h4><BiCheck /> <span>Tap Changer:</span> Off Circuit or On Load Tap Changer Insulating</h4>
                            <h4><BiCheck /> <span>Oil/Fluid:</span> Mineral Oil / Ester Oil</h4>
                            <h4><BiCheck /> All our <span>Industrial Power Transformers</span> adhere to IS 2026, IS 1180, and IEC 60076 Standards.</h4>

                            <h6><BsInfoCircleFill /> We recommend getting the oil changed after every two years during <span>Power Transformer Maintenance</span></h6>
                        </div>
                    </div>





                </div>


                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Power Transformer Applications</h3>
                                <p>By choosing VoltageShift as your <span>3Phase Power Transformer Supplier</span>, you will get
                                    comprehensive Power Transformer Services from Power Transformer Installation to repair &
                                    maintenance.
                                </p>
                                <p>Our Power Transformers are versatile & best suited for applications in several industries
                                    irrespective of size, scale, and complexity, including turnkey projects. The following are the
                                    industries' applications of our Power Transformer:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <ul>
                                        <li><BiCheck /> Steel Industries</li>
                                        <li><BiCheck /> Cement Industries</li>
                                        <li><BiCheck /> Chemical Industries</li>
                                        <li><BiCheck /> Food and Beverages Industries</li>
                                        <li><BiCheck /> Ceramics Industries</li>
                                        <li><BiCheck /> Hospital and Pharmaceutical Industries</li>
                                        <li><BiCheck /> Manufacturing Industries</li>
                                        <li><BiCheck /> Commercial & Residential Buildings</li>
                                        <li><BiCheck /> Renewable Energy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>As a trustworthy <span>Power Transformer Exporter</span>, we export to 45+ countries, we take care of
                                all aspects, from Power Transformer Installation Procedure to Power Transformer Repair, to
                                ensure you get everything under one consolidated roof.</p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Why Choose Us for Power Transformers?</h3>
                        <h6>By partnering with VoltageShift as your <span>Power Transformer Service Provider</span>, you willavail numerous benefits & advantages in the long run, and salient ones are as follows:</h6>
                        <p><BiCheck /> They are designed to withstand electrical surges, temperature fluctuations, and dynamic stress.</p>
                        <p><BiCheck /> The transformer oil options are customizable to meet the specific requirements &needs of the clients, including mineral & ester oils.</p>
                        <p><BiCheck /> The transformers have low magnetizing current, resulting in reduced no-loadelectricity bills & mitigating capacitor needs.</p>
                        <p><BiCheck /> We have an extensive range of <span>Power Transformer parts</span>, from pressure relief valvesto Buchholz relays, oil level indicators, silica gel breathers, and bushing &accessories.</p>
                        <p><BiCheck /> Get Complete Assistance with <span>Power Transformer Installation Service</span> and a highlycompetent & professional customer service team, offering personalized solutions toresolve your queries.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>


                <TransformersSlider />

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a 2.5 MVA Power Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p><span>2.5MVA Power Transformers</span> are large & primarily used for power distribution in Industrial
                                        applications. They are made up of copper wires cores enveloped with copper windings
                                        insulated from each other.</p>
                                    <p>They have a high power factor & provide stable voltage with low distortion. The 2.5 MVA
                                        Industrial Transformers change AC Power voltage from a generator or a transmission line to
                                        primary voltage in Industries applications, such as buildings and on-site.</p>
                                    <p>If you are searching for a <span>2.5 MVA Power Transformer Manufacturer</span> & Supplier, <a href="/contact-us" target="_blank">contact us</a>  for a quotation. And get highly durable, excellent performance, and premier quality 2.5 MVA Power Transformers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is a 50 MVA Power Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A 50 MVA Power Transformer is large in capacity and renowned for its low noise, partial
                                        discharge, and excellent short-circuit resistance. Its Frequency Rating is 50HZ/60HZ.</p>
                                    <p>All 50 MVA Power Transformers windings are wrapped with self-adhesive transposed wires
                                        with outstanding conductivity to enhance resistance to short-circuit. Its iron core has oil
                                        passages at appropriate positions for heat dissipation.</p>
                                    <p>If you are searching for a <span>50 MVA Power Transformer Manufacturer</span> & Supplier, <a href="/contact-us" target="_blank">contact us</a> for a quotation & free consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What is a 132 KV Voltage Rating Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A 132 KV Voltage Rating Transformer is extensively used to transmit electrical energy from
                                        generating stations to end users. It falls under the classification of power and substation
                                        transformer.
                                    </p>
                                    <p>The power is transferred by changing the voltage level to keep its frequency efficient. And
                                        we recommend maintaining a 1.8 meters approach distance from the 132 KV power line
                                        while handling.</p>
                                    <p>If you are looking for a <span>132 KV Power Transformer Manufacturer</span> & Supplier, <a href="/contact-us" target="_blank">contact us</a> for a free consultation and quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default PowerTransformer