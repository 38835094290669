import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import './home-style.css';
import './../../assets/css/product-style.css';
import BannerCard from '../../components/home/Banner';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import GetinTouch from '../../components/getintouch/GetinTouch';
import Continent from '../../components/continent/Continent';
import { AboutImg1, PowerTransformImg1, PowerTransformImg2, PowerTransformImg3, PowerTransformImg4, PowerTransformImg5, PowerTransformImg6, TransformerService, TransformerSparesParts, HighQualityProducts, OnTimeDelivery, ExperienceIcon, BestService, LineIndicator1, WorkFlowIcon1, WorkFlowIcon2, WorkFlowIcon3, WorkFlowIcon4, WorkFlowIcon5, WorkFlowIcon6, WorkFlowIcon7 } from '../../assets';
import { FiArrowUpRight } from "react-icons/fi";
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

function Index() {
    const [pageSlug, setPageSlug] = useState('');
    const [h1_title, setH1Title] = useState('');
    const [description, setDescription] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');

    useEffect(() => {
        document.title = 'Home';
        fetchSeo();
    }, []);

    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/homepage`);
        setPageSlug(data.data.slug);
        setH1Title(data.data.h1_title);
        setDescription(data.data.description);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL}
            />
            <BannerCard />
            <div className='CustContainer'>
                <div className='aboutHomePageCov'>
                    <div className='HomepgTitleCov'>
                        <h1>Fulfill all your Transformers Needs at VoltageShift.</h1>
                    </div>
                    <div className='aboutHomePageLeftimg'>
                        <LazyLoad
                            placeholder={<div>Loading...</div>}
                        >
                            <img src={AboutImg1} alt="We’re VoltageShift." />
                        </LazyLoad>
                    </div>
                    <div className='aboutHomePageRighttxt'>

                        <h6>Partner with the most competent Custom Transformer Solutions Provider for your various Power Transmission needs.</h6>
                        <p>Get a vast range of premier-quality Transformer Solutions customized according to your
                            applications & requirements. As a fast-growing <span>Transformer Manufacturing Company</span>, we
                            deliver cost-effective Transformers which adhere to the highest quality standards set by IEC
                            & IS.</p>
                        <p>We provide a wide range of services from design & engineering to manufacturing,
                            installation, spares, repair & maintenance services by utilizing our state-of-the-art technology,
                            tools, and resources deployed in-house.</p>
                        <p>We employ our extensive knowledge & expertise to seamlessly handle projects of all sizes,
                            scales, and complexities, including turnkey projects. As a reliable <span>Transformer exporter</span>, we
                            export to 45+ countries.</p>
                        <a href="/about-us">More about company <FiArrowUpRight /></a>
                    </div>
                </div>
            </div>

            <div className='WhatwedoDataCover'>
                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h2>Our Range of Transformers</h2>
                        <p>At VoltageShift, we provide six core <span>Transformer Solutions</span> of <br /> different MVA & Voltage
                            Classes for numerous application needs of the clients.</p>
                    </div>
                    <div className='ProductDataMain'>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/power-transformer">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg1} alt="Power Transform" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Power Transformer</h3>
                                        <p>2.5MVA To 50 MVA, Voltage Class upto 132 KV</p>
                                        <h5>Designed to Withstand Electrical Surges and Temperature Fluctuations</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/distribution-transformer">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg2} alt="Distribution Transformer" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Distribution Transformer</h3>
                                        <p>100 KVA To 2500 KVA, Voltage class upto 33 KV</p>
                                        <h5>Designed with Low Magnetizing Current by minimizing the load on electricity bills</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/cast-resin-dry-type-transformer">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg3} alt="Dry Type Transformer" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Dry Type Transformer</h3>
                                        <p>Up to 05 MVA, Voltage class upto 33 KV</p>
                                        <h5>Compact Design, Low in Weight, and Lesser Maintenance Cost</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/furnace-transformer">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg4} alt="Furnace Transformer" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Furnace Transformer</h3>
                                        <p>Up to 15 MVA, Voltage class upto 33 KV</p>
                                        <h5>High Current Capacity Transformer, Best for Steel & Metal Industries</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/solar-inverter-duty-transformer">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg5} alt="Solar Inverter Duty Transformer" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Solar Inverter Duty Transformer</h3>
                                        <p>Up to 15 MVA, Voltage Class upto 33 KV</p>
                                        <h5>Best for Solar & Wind Power Plants and Renewable Energy</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/compact-secondary-substations">
                                    <div className='ProductInerCovImg'>
                                        <LazyLoad
                                            placeholder={<div>Loading...</div>}

                                        >
                                            <img src={PowerTransformImg6} alt="Compact Secondary Substations" />
                                        </LazyLoad>
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Compact Secondary Substations (CSS)</h3>
                                        <p>100 KVA To 2500 KVA, Voltage class upto 33 KV</p>
                                        <h5>Best for Commercial & Residential Buildings, Easy for Transportation</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <div className='TrustedPartboxTitle'>
                    <h3>Post-Installation Transformer Spares, Repair, and Maintenance Services at a
                        one-stop Destination</h3>
                    <p>Partner with us as your <span>Transformer Manufacturer</span>. And Stay worry-free as we will take
                        care of your Transformers Repair & Maintenance Services and provide spares to ensure they
                        are working efficiently & smoothly.</p>
                </div>
                <div className='TrustedPartboxCov'>
                    <div className='TrustedPartboxIner'>
                        <div className='TrustedPartboxImg'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={TransformerSparesParts} alt="Transformer Spares" />
                            </LazyLoad>
                        </div>
                        <div className='TrustedPartboxTxt'>
                            <h3>Transformer Spares</h3>
                            <p>Get an extensive range of Transformer Spares, including pressure relief valves, Buchholz
                                relays, oil level indicators, silica gel breathers, bushing & accessories, transformer oil, and
                                more under one consolidated roof to keep your Transformers up and running like new ones.</p>
                            <a href="/transformer-spares">See all Products <FiArrowUpRight /></a>
                        </div>
                    </div>
                    <div className='TrustedPartboxIner'>
                        <div className='TrustedPartboxImg'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={TransformerService} alt="Transformer service" />
                            </LazyLoad>
                        </div>
                        <div className='TrustedPartboxTxt'>
                            <h3>Transformer services</h3>
                            <p>We provide Transformer Repair & Maintenance Services worldwide for several industries,
                                such as Steel, Cement, Chemical, Manufacturing, Commercial & Residential Buildings,
                                Hospital and Pharmaceutical Industries, Food and Beverages Industries, and more.</p>
                            <a href="/transformer-services">Know More <FiArrowUpRight /></a>
                        </div>
                    </div>
                </div>
            </div>


            <div className='WhatwedoMainBg'>
                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Why Choose VoltageShift?</h3>
                    </div>
                    <div className='WhatwedoMainCov'>
                        <div className='WhatwedoInerboxcov'>
                            <div className='WhatwedoMainIner'>
                                <LazyLoad
                                    placeholder={<div>Loading...</div>}

                                >
                                    <img src={HighQualityProducts} alt="Supreme Quality" />
                                </LazyLoad>
                                <h3>Supreme Quality</h3>
                                <p>All our products are manufactured in accordance with IS & IEC quality standards and best
                                    practices at our cutting-edge technology premises by our highly qualified & professional
                                    engineers.
                                </p>
                            </div>
                        </div>
                        <div className='WhatwedoInerboxcov'>
                            <div className='WhatwedoMainIner'>
                                <LazyLoad
                                    placeholder={<div>Loading...</div>}

                                >
                                    <img src={ExperienceIcon} alt="Extensive Experience" />
                                </LazyLoad>

                                <h3>Extensive Experience</h3>
                                <p>Our team has immense experience & knowledge in delivering <span>Custom Transformer
                                    Solutions</span> to clients worldwide, from installation to commissioning, repair, maintenance, and
                                    more services.</p>
                            </div>
                        </div>
                        <div className='WhatwedoInerboxcov'>
                            <div className='WhatwedoMainIner'>
                                <LazyLoad
                                    placeholder={<div>Loading...</div>}

                                >
                                    <img src={BestService} alt="On-Time Delivery" />
                                </LazyLoad>

                                <h3>On-Time Delivery</h3>
                                <p>As a reputable <span>Transformer Supplier</span>, we prioritize every order, and our logistics team
                                    follows an SOP to ship all orders within the dedicated timeframe. We keep our customers
                                    updated with their shipment progress.</p>
                            </div>
                        </div>
                        <div className='WhatwedoInerboxcov'>
                            <div className='WhatwedoMainIner'>
                                <LazyLoad
                                    placeholder={<div>Loading...</div>}

                                >
                                    <img src={OnTimeDelivery} alt="Prompt Customer Service" />
                                </LazyLoad>

                                <h3>Prompt Customer Service</h3>
                                <p>From sales & installation to post-installation support, our highly trained, professional, and
                                    proficient customer service team are available at your ease. They offer personalized solutions
                                    to resolve queries with prompt & reliable support service.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConsultationForm />

            <div className='WorkflowBoxCovdata'>
                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Our Workflow</h3>
                        <p>As a highly trusted <span>Transformer Solution Provider</span>, we follow Step-by-Step Approach to <br />
                            deliver Versatile Transformer Solutions for your Industry Applications.</p>
                    </div>
                </div>
                <div className='WorkflowBoxIner'>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon1} alt="No-Cost Consultation And Product Selection" />
                            </LazyLoad>
                        </div>
                        <p>No-Cost Consultation<br />
                            And<br />
                            Product Selection</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon2} alt="Quotation and Order Confirmation" />
                            </LazyLoad>
                        </div>
                        <p>Quotation<br />
                            and<br />
                            Order Confirmation</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon3} alt="Manufacturing and Testing" />
                            </LazyLoad>
                        </div>
                        <p>Manufacturing<br />
                            and<br />
                            Testing</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon4} alt="Quality Control and Customer’s Inspection" />
                            </LazyLoad>
                        </div>
                        <p>Quality Control<br />
                            and<br />
                            Customer’s Inspection</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon5} alt="Packaging and Delivery" />
                            </LazyLoad>
                        </div>
                        <p>Packaging<br />
                            and<br />
                            Delivery</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}

                            >
                                <img src={WorkFlowIcon6} alt="Installation and Commissioning" />
                            </LazyLoad>
                        </div>
                        <p>Installation<br />
                            and<br />
                            Commissioning</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={WorkFlowIcon7} alt="Ongoing Support" />
                            </LazyLoad>
                        </div>
                        <p>Ongoing<br />
                            Support</p>
                    </div>
                </div>

            </div>


            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>For how long will Power Transformers last?</Accordion.Header>
                            <Accordion.Body>
                                <p>The general lifespan of an Oil-Filled Power Transformer is approximately 20-30, which can
                                    go up to 50-60 depending on high-voltage models and regular servicing & change of
                                    Transformer spares. Predominantly Transformers last for more than 50% of the person's
                                    lifespan who have got them installed.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Why is IEC 60076 Standard Important for Transformers?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>IEC 60076 Standard</span> is a crucial quality standard in Power Transformer Industry, specifying
                                    maximum Impedance resilience for Power Transformers based on their voltage level & rated power.</p>
                                <p>The maximum Impedance tolerance for up to 500 kVA transformers is 105, and 7% for 500
                                    kVA and 2 MVA. IEC 60076 ensures they are designed, tested, and rated to meet specified
                                    standards of quality & performance.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What does Transformer Maintenance include?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>Transformer Maintenance</span> is necessary to ensure it is functioning smoothly. Delays in
                                    maintenance can cause issues in its machinery leading to high repair costs. Basic Transformer
                                    Preventative Maintenance includes oil filtration, routine inspection, transformer rewinding,
                                    testing, minor fixes, special handling instructions, and annual maintenance check.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How often should you opt for Transformer Oil Filtration?</Accordion.Header>
                            <Accordion.Body>
                                <p>You should opt for Transformer Oil Filtration/Oil Change when you detect leaks, noise &
                                    vibration, old & flaking paint, black or dark oil color, and running hot. However, you must
                                    conduct DGA Oil Tests to ascertain the dissolved gasses in transformer oil filtration, which
                                    should be done in around two years.</p>
                                <p>Check out VoltageShift <a href="/oil-filtration" target="_blank">Transformer Oil Filtration</a> to stay worry-free.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How to Install a Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Installing Transformer is challenging as it requires immense technical expertise and handling,
                                    as minor mistakes can cause extensive damage & jeopardize lives. We recommend the
                                    following steps to install a Transformer:</p>
                                <ul>
                                    <li>1) Selection of Area: Ensure no ignition or corrosion sources are nearby.</li>
                                    <li>2) Mounting Transformer: Disable the primary power supply</li>
                                    <li>3) Adjust the primary taps correctly: Clean & Coat them with an Electrical compound
                                        before bolting the wire</li>
                                    <li>4) Route Wires & Cables into the Enclosure</li>
                                    <li>5) Ground the Transformer carefully</li>
                                    <li>6) Verify the Secondary Voltage for the load when power is switched on</li>
                                    <li>7) Connect the Load to the Secondary Terminals</li>
                                    <li>8) Assemble the Transformer & Perform the Final Inspection</li>
                                </ul>
                                <p>Contact us, and our highly qualified technicians will proficiently <a href="installation-and-commissioning" target="_blank">install Transformers at your
                                    site</a>.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>


            <BlogList />

            <div className='ContinentMaintopMar'>

                <GetinTouch />

                <Continent />

            </div>

        </>
    )
}

export default Index