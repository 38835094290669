import React, { useEffect } from 'react';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { HiArrowSmallLeft, HiArrowSmallRight } from "react-icons/hi2";
import './blog-style.css';
import { RTDimg3, FacebookIcon, LinkedinIcon, TwitterIcon, PowerTransformersDetails1, BlogBanner2, BlogBanner3, BlogBanner1 } from '../../assets';
import Continent from '../../components/continent/Continent';
import ConsultationForm from '../../components/common/consultation';
import { IoCalendar } from 'react-icons/io5';

function BlogDetailsTwo() {
    useEffect(() => {
        document.title = 'The Importance of Choosing the Right Service Provider for Transformers';
    }, []);
    return (
        <>
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/news">News</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>News Detail</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='BlogDetailsMain'>
                    <div className='BlogDetailsLeft'>
                        <div className='BlogDetailsLefImgbox'>
                            <img src={BlogBanner2} alt="Best Transformer Provider" />
                            <span>Service</span>
                        </div>
                        <div className='BlogDetailsLefTitle'>
                            <h1>The Importance of Choosing the Right Service Provider for Transformers</h1>
                            <p><FaUser /> by VoltageShift on April 21, 2023</p>
                        </div>
                        <div className='BlogDetailsDataall'>
                            <p>In the world of transformers, selecting the right service provider is critical to ensure the best performance and reliability. At VoltageShift, we understand the significance of this decision and strive to be your trusted partner in delivering exceptional transformer solutions. Here's why choosing the right service provider is crucial.</p>

                            <h2>Expertise and Experience:</h2>
                            <p>With over 20 years of experience, VoltageShift has established itself as a leading transformer manufacturer and service provider. Our skilled team possesses in-depth knowledge and expertise in handling diverse transformer projects, ensuring that your specific needs are met.</p>

                            <h2>Quality Standards:</h2>
                            <p>We adhere to the highest quality standards set by IEC and IS, guaranteeing that our transformer solutions meet stringent industry requirements. By choosing the right service provider like VoltageShift, you can have confidence in the reliability and performance of your transformers.</p>

                            <h2>Comprehensive Solutions:</h2>
                            <p>VoltageShift offers a complete range of services, including design, engineering, manufacturing, installation, commissioning, repair, and maintenance. Our comprehensive approach ensures seamless project execution and dependable performance throughout the transformer's lifecycle.</p>

                            <h2>Cutting-Edge Technology:</h2>
                            <p>As a globally recognized company, VoltageShift leverages cutting-edge technology and top-notch transformers in its solutions. By partnering with us, you benefit from advanced technological advancements that enhance efficiency, durability, and overall performance.</p>

                            <h2>Global Reach and Reputation:</h2>
                            <p>With exports to over 45 countries, VoltageShift has established a strong global presence. Our reputation for excellence in turnkey projects and reliable transformer solutions has earned us the trust and confidence of customers worldwide.</p>

                            <p>Choosing the right service provider for your transformer needs is vital to ensure optimal performance, durability, and long-term satisfaction. With VoltageShift's expertise, commitment to quality, and comprehensive solutions, we are ready to be your trusted partner in transforming your power infrastructure. Contact us today to discuss your transformer requirements and experience the difference of choosing the right service provider.</p>

                        </div>
                        <div className='BdetailspageSharbtn'>
                            <div className='BdetailspageSharLeft'>
                                <p>Share with</p>

                                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.voltageshift.com/the-importance-of-choosing-the-right-service-provider-for-transformers" id="shareonfacebook" target="_blank" data-href="https://www.voltageshift.com/the-importance-of-choosing-the-right-service-provider-for-transformers"><img src={FacebookIcon} alt="Facebook" /></a>
                                <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.voltageshift.com/the-importance-of-choosing-the-right-service-provider-for-transformers&title=The Importance of Choosing the Right Service Provider for Transformers&summary=description&source=https://www.voltageshift.com" target="_blank"><img src={LinkedinIcon} alt="Linkedin" /></a>
                                <a href="https://twitter.com/intent/tweet?text=The Importance of Choosing the Right Service Provider for Transformers&url=https://www.voltageshift.com/the-importance-of-choosing-the-right-service-provider-for-transformers&hashtags=" data-size="large" target="_blank"><img src={TwitterIcon} alt="Twitter" /></a>
                            </div>
                            <div className='BdetailspageSharRight'>
                                <a href="/choosing-the-right-transformer-a-guide-to-ensuring-quality-and-performance"><HiArrowSmallLeft /> Previous</a>
                                <a href="/the-backbone-of-industrial-power-unveiling-the-importance-of-transformers">Next <HiArrowSmallRight /></a>
                            </div>
                        </div>
                    </div>
                    <div className='BlogDetailsRight'>
                        {/* <div className='BlogDetRecentPostTitle'>
                            <h2>Categories</h2>
                        </div>
                        <div className='CategorieListBlog'>
                            <ul>
                                <li className='active'><a href="">All</a></li>
                                <li><a href="">Category one</a></li>
                                <li><a href="">Category two</a></li>
                                <li><a href="">Category three</a></li>
                                <li><a href="">Category four</a></li>
                            </ul>
                        </div> */}

                        <div className='BlogDetRecentPostTitle'>
                            <h2>Recent Post</h2>
                        </div>
                        <div className='BlogDetRecentPostList'>

                            <div className='BlogDetRecePotListCov'>
                                <a href="/the-backbone-of-industrial-power-unveiling-the-importance-of-transformers">
                                    <div className='BlogDetRecePotListImg'>
                                        <img src={BlogBanner3} alt="The Backbone of Industrial Power: Unveiling the Importance of Transformers" />
                                    </div>
                                    <div className='BlogDetRecePotListTxt'>
                                        <h3>The Backbone of Industrial Power: Unveiling the Importance of Transformers</h3>
                                        <p><IoCalendar /> May 19, 2023</p>
                                    </div>
                                </a>
                            </div>
                            <hr />
                            <div className='BlogDetRecePotListCov'>
                                <a href="/choosing-the-right-transformer-a-guide-to-ensuring-quality-and-performance">
                                    <div className='BlogDetRecePotListImg'>
                                        <img src={BlogBanner1} alt="Choosing the Right Transformer: A Guide to Ensuring Quality and Performance" />
                                    </div>
                                    <div className='BlogDetRecePotListTxt'>
                                        <h3>Choosing the Right Transformer: A Guide to Ensuring Quality and Performance</h3>
                                        <p><IoCalendar /> March 20, 2023</p>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ConsultationForm />

            <Continent />


        </>
    )
}

export default BlogDetailsTwo