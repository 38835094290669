import React from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'

function PrivacyPolicy() {
    return (
        <>
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='PolicyDataSetcov'>
                    <h1>Privacy Policy</h1>
                    <p>At VoltageShift Engineering Solutions LLP ("VoltageShift," "we," "us," or "our"), we are committed to protecting the privacy and personal information of our website visitors and users. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you interact with our website (www.voltageshift.com). By accessing or using our website, you consent to the terms and practices described in this policy.</p>

                    <h2>Information Collection</h2>
                    <p>We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and any other information you choose to provide when filling out forms on our website or contacting us through the provided communication channels.</p>
                    <p>In addition, we may collect certain non-personal information automatically when you visit our website, including your IP address, browser type, operating system, referring URLs, and website usage data. This information helps us analyze trends, administer the site, track user movements, and gather demographic information for aggregate use. This data is collected using cookies, web beacons, and other similar technologies.</p>

                    <h2>Information Usage</h2>
                    <p>We use the collected information for various purposes, including:</p>
                    <ul>
                        <li>1. Responding to your inquiries and providing the requested services.</li>
                        <li>2. Sending you relevant information, updates, and promotional materials.</li>
                        <li>3. Improving our website, products, and services based on your feedback and preferences.</li>
                        <li>4. Conducting research, analytics, and market studies to enhance our offerings.</li>
                        <li>5. Complying with legal obligations and protecting our rights and interests.</li>
                    </ul>

                    <h2>Information Sharing</h2>
                    <p>We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business activities, or delivering requested services on our behalf. These third parties are obligated to maintain the confidentiality and security of your information and are prohibited from using it for any other purpose.</p>
                    <p>We may also disclose your information when required by law, in response to a valid legal request, or to protect our rights, safety, or property. Additionally, if VoltageShift undergoes a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</p>

                    <h2>Data Security</h2>
                    <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h2>Third-Party Links</h2>
                    <p>Our website may contain links to third-party websites or services that are not controlled or operated by VoltageShift. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing any personal information.</p>

                    <h2>Updates to the Privacy Policy</h2>
                    <p>We may revise this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the revised effective date. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.</p>

                    <p><a href="/contact-us">Contact Us</a></p>

                    <p>If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal information, please contact us using the provided contact information on our website.</p>

                    <p style={{ color: '#010101', }}>Last updated: 6th June 2023</p>

                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy