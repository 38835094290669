import React, { useEffect, useState } from 'react';
import './users-style.css';
import axios from 'axios';
import Pagination from '../../components/pagination/index';
import Wrapper from '../../components/Wrapper';
import BTable from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GoPlus, GoSearch } from "react-icons/go";
import { Seo } from '../../models/seo';
import Button from 'react-bootstrap/Button';
import { RiEditBoxLine } from "react-icons/ri";
import { Link } from 'react-router-dom';

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const Index: React.FC = () => {
    const [seos, setSeos] = useState<Seo[]>([]);
    const [seos_cnt, setSeosCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            const { data } = await axios.get(`seos?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
            setSeos(data.data);
            setSeosCount(data.meta.total);
            setLoading(false);
        };
        fetchUsers();
    }, [currentPage, itemsPerPage, searchText]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    const handleSearch = (e: React.KeyboardEvent<FormControlElement>) => {
        setSearchValue(e.currentTarget.value);
        setCurrentPage(1);
    };

    const currentItems = seos;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Wrapper>
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>SEOs</h1>
                    </div>
                    <div className='AdmintopTitleRight'>
                        <Form className="SerachFlttitlemain">
                            <Form.Group className="SearchTitleboxCov" controlId="">
                                <GoSearch />
                                <Form.Control type="text" placeholder="Search by Pagename/ Title/ Slug/ Description" onKeyUp={handleSearch} />
                            </Form.Group>
                        </Form>
                        <div className='addeditBtnBoxTitle'>
                            <Link to={'/admin/add-seo'} className="AddBtnSet"><GoPlus /> Add</Link>
                        </div>
                    </div>
                </div>
                <>
                    <div className='ComTableCardCov'>
                        <BTable striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th>ID</th>
                                    <th>Pagename</th>
                                    {/* <th>Slug</th> */}
                                    <th>H1 Tag</th>
                                    {/* <th>Description</th> */}
                                    <th>Image</th>
                                    <th>Category</th>
                                    {/* <th>Meta Title</th> */}
                                    {/* <th>Meta Keyword</th> */}
                                    {/* <th>Meta Description</th> */}
                                    {/* <th>Focus Keyword</th> */}
                                    {/* <th>OG Title</th> */}
                                    {/* <th>OG Image</th> */}
                                    {/* <th>OG description</th> */}
                                    <th>Is Active?</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                    <th style={{ width: 35 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td></td>
                                        <td colSpan={8}>Loading...</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    <>
                                        {currentItems.length > 0 ? (
                                            currentItems.map((seo: Seo) => {
                                                return (
                                                    <tr key={seo.id}>
                                                        <td></td>
                                                        <td>{seo.id}</td>
                                                        <td>{seo.pagename}</td>
                                                        {/* <td>{seo.slug}</td> */}
                                                        <td>{seo.h1_title}</td>
                                                        {/* <td>{seo.description}</td> */}
                                                        <td><img src={`${axios.defaults.baseURL}${seo.image}`} alt={`${seo.slug}`} width={100} /></td>
                                                        <td>{seo.category.name}</td>
                                                        {/* <td>{seo.meta_title}</td> */}
                                                        {/* <td>{seo.meta_keyword}</td> */}
                                                        {/* <td>{seo.meta_description}</td> */}
                                                        {/* <td>{seo.focus_keyword}</td> */}
                                                        {/* <td>{seo.og_title}</td> */}
                                                        {/* <td>{seo.og_image}</td> */}
                                                        {/* <td><img src={`${axios.defaults.baseURL}${seo.og_image}`} alt={`${seo.slug}`} width={100} /></td> */}
                                                        {/* <td>{seo.og_description}</td> */}
                                                        <td><p dangerouslySetInnerHTML={{ __html: seo.is_active === '0' ? '<span className="Yesonbox">Yes</span>' : '<span className="Nooffbox">No</span>' }}></p>
                                                        </td>

                                                        <td>{seo.created_at}</td>
                                                        <td className="usersActinLinkbox">
                                                            {/* <a onClick={handleShow} className='cursor_pointer'>Manage Password</a> */}
                                                            <Button variant='link' className='ViewPageiconBtn'><Link to={`/admin/update-seo/${seo.id}`} className="btn btn-sm btn-outline-secondary"><RiEditBoxLine /></Link></Button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td></td>
                                                <td colSpan={8}>No data found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </BTable>
                    </div>
                </>
                <div className='PaginationCustbox PaginationCusUser'>
                    <Pagination
                        totalItems={seos_cnt}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
        </Wrapper >
    );
};

export default Index;