import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from "./pages/home/";
import Transformers from "./pages/transformers";
import PowerTransformer from "./pages/transformers/PowerTransformer";
import DistributionTransformer from "./pages/transformers/DistributionTransformer";
import CastResinDryTypeTransformer from "./pages/transformers/CastResinDryTypeTransformer";
import FurnaceTransformer from "./pages/transformers/FurnaceTransformer";
import SolarInverterDutyTransformer from "./pages/transformers/SolarInverterDutyTransformer";
import CompactSecondarySubstations from "./pages/transformers/CompactSecondarySubstations";
import SparePartsList from "./pages/spareparts/SparePartsList";
import PressureReliefValve from "./pages/sparepartsdetails/PressureReliefValve";
import TransformerServices from "./pages/services/TransformerServices";

import AboutUs from "./pages/aboutus/AboutUs";
import ContactUs from "./pages/contact/ContactUs";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";

import InstallationAndCommissioning from "./pages/services/InstallationAndCommissioning";
import TransformerTestingService from "./pages/services/TransformerTestingService";
import OilFiltration from "./pages/services/OilFiltration";
import OnSiteFaultTroubleshooting from "./pages/services/OnSiteFaultTroubleshooting";
import OverhaulingServices from "./pages/services/OverhaulingServices";
import RepairingService from "./pages/services/RepairingService";
import AnnualMaintenanceContract from "./pages/services/AnnualMaintenanceContract";
import TurnkeyProjects from "./pages/services/TurnkeyProjects";

import BlogList from "./pages/blog/BlogList";
import BlogDetailsOne from "./pages/blog/BlogDetailsOne";
import BlogDetailsTwo from "./pages/blog/BlogDetailsTwo";
import BlogDetailsThree from "./pages/blog/BlogDetailsThree";

import ThankYou from "./pages/thankyou/ThankYou";
import PageNotFound from "./pages/error/PageNotFound";

import FrequentlyAskedQuestion from "./pages/faq/FrequentlyAskedQuestion";
import GenerateSitemap from "./pages/generateSitemap";
import Admin from './admin/App';
import BlogDetails from './pages/blog/BlogDetails';
import { useAppDispatch } from './app/hooks';
import { setCategory } from './redux/actions/setCategoryAction';
import { Category } from './models/category';
import SparePartsListGoogle from './pages/spareparts/SparePartsListGoogle';

function App() {
  const dispatch = useAppDispatch();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/transformers" element={<Transformers />} />
        <Route path="/transformer-services" element={<TransformerServices />} />
        <Route path="/power-transformer" element={<PowerTransformer />} />
        <Route path="/distribution-transformer" element={<DistributionTransformer />} />
        <Route path="/cast-resin-dry-type-transformer" element={<CastResinDryTypeTransformer />} />
        <Route path="/furnace-transformer" element={<FurnaceTransformer />} />
        <Route path="/solar-inverter-duty-transformer" element={<SolarInverterDutyTransformer />} />
        <Route path="/compact-secondary-substations" element={<CompactSecondarySubstations />} />
        <Route path="/transformer-spares" element={<SparePartsList />} />
        <Route path="/transformer-spare-parts-lp" element={<SparePartsListGoogle show={false} onHide={undefined} />} />
        <Route path="/pressure-relief-valve" element={<PressureReliefValve />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/installation-and-commissioning" element={<InstallationAndCommissioning />} />
        <Route path="/transformer-testing-service" element={<TransformerTestingService />} />
        <Route path="/oil-filtration" element={<OilFiltration />} />
        <Route path="/on-site-fault-troubleshooting" element={<OnSiteFaultTroubleshooting />} />
        <Route path="/overhauling-services" element={<OverhaulingServices />} />
        <Route path="/repairing-service" element={<RepairingService />} />
        <Route path="/annual-maintenance-contract" element={<AnnualMaintenanceContract />} />
        <Route path="/turnkey-projects" element={<TurnkeyProjects />} />
        <Route path="/news" element={<BlogList />} />
        {/* <Route path="/news/:slug" element={<BlogDetails />} /> */}
        <Route path="/news/:slug" element={<BlogDetails setCategory={(category: Category) => dispatch(setCategory(category))} />} />
        <Route path="/the-importance-of-choosing-the-right-service-provider-for-transformers" element={<BlogDetailsTwo />} />
        <Route path="/the-backbone-of-industrial-power-unveiling-the-importance-of-transformers" element={<BlogDetailsThree />} />
        {/* <Route path="/thankyou" element={<ThankYou />} /> */}
        <Route path="/contactus-thankyou" element={<ThankYou />} />
        <Route path="/quotetion-thankyou" element={<ThankYou />} />
        <Route path="/ncc-thankyou" element={<ThankYou />} />
        <Route path="/subscription-thankyou" element={<ThankYou />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/faq" element={<FrequentlyAskedQuestion />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/generate-sitemap" element={<GenerateSitemap />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
