import React from 'react'
import './continent-style.css';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Continent() {
    return (
        <>
            <div className='contientBoxCov'>
                <div className='CustContainer'>
                    <div className='contientBoxTitle'>
                        <h3>VoltageShift - Exports wide range of transformers, spare <br /> parts and service to the following continents</h3>
                    </div>
                    <div className='contientBoxTab'>
                        <Tabs
                            defaultActiveKey="asia"
                            id="uncontrolled-tab-example"
                            className="contientTabMain"
                        >
                            <Tab eventKey="asia" title="Asia">
                                <div className='contientTabIner'>
                                    <p>
                                        AFGHANISTAN | ARMENIA | AZERBAIJAN | BAHRAIN | BANGLADESH | BHUTAN | CAMBODIA | CHINA | CYPRUS | GEORGIA | HONG KONG | INDIA | INDONESIA | IRAN | IRAQ | ISRAEL | JAPAN | JORDAN | KAZAKHSTAN | KUWAIT | LAOS | LEBANON | MALAYSIA | MALDIVES | MYANMAR | NEPAL | NORTH KOREA | OMAN | PAKISTAN | PHILIPPINES | QATAR | SAUDI ARABIA | SINGAPORE | SOUTH KOREA | SRI LANKA | SYRIA | TAIWAN | TAJIKISTAN | THAILAND | TIMOR-LESTE | TURKEY | TURKMENISTAN | UNITED ARAB EMIRATES | UZBEKISTAN | VIETNAM | YEMEN
                                    </p>
                                </div>
                            </Tab>
                            <Tab eventKey="africa" title="Africa">
                                <div className='contientTabIner'>
                                    <p>
                                        Angola | Benin | Botswana | Burkina Faso | Burundi | Cameroon | Cape Verde | Central African Republic | Chad (Tchad) | Comoros | Republic of the Congo | Democratic Republic of the Congo | Côte d'Ivoire (Ivory Coast) | Djibouti | Equatorial Guinea | Egypt (Misr) | Eritrea | Ethiopia (Abyssinia) | Gabon | The Gambia | Ghana | Guinea | Guinea-Bissau | Kenya | Lesotho | Liberia | Libya | Madagascar | Malawi | Algeria | Mali | Mauritania | Mauritius | Morocco (Al Maghrib) | Mozambique | Namibia | Niger | Nigeria | Rwanda | São Tomé and Príncipe | Senegal | Seychelles | Sierra Leone | Somalia | South Africa | South Sudan | Sudan | Swaziland (Eswatini) | Tanzania | Togo | Tunisia | Uganda | Western Sahara | Zambia | Zimbabwe
                                    </p>
                                </div>
                            </Tab>
                            <Tab eventKey="europe" title="Europe">
                                <div className='contientTabIner'>
                                    <p>
                                        Albania | Andorra | Austria | Belarus | Belgium | Bosnia and Herzegovina | Bulgaria | Croatia | Cyprus | Czech Republic | Denmark | Estonia | Finland | Georgia | Germany | Greece | Hungary | Iceland | Republic of Ireland | Italy | Kosovo | Latvia | Liechtenstein | Lithuania | Luxembourg | North Macedonia | Malta | Moldova | Monaco | Montenegro | Netherlands | Norway | Poland | Portugal | Romania | San Marino | Serbia | Slovakia | Slovenia | France | Spain | Sweden | Switzerland | Ukraine | United Kingdom
                                    </p>
                                </div>
                            </Tab>
                            <Tab eventKey="northamerica" title="North America">
                                <div className='contientTabIner'>
                                    <p>
                                        Canada | United States of America | Navassa Island | Puerto Rico | US Virgin Islands | Guam | American Samoa
                                    </p>
                                </div>
                            </Tab>
                            <Tab eventKey="southamerica" title="South America">
                                <div className='contientTabIner'>
                                    <p>
                                        Brazil | Argentina | Bolivia | Chile | Colombia | Ecuador | Falkland Islands | French Guiana | Guyana | Paraguay | Peru | South Georgia and the South Sandwich Islands | Suriname | Uruguay | Venezuela | Belize | Costa Rica | El Salvador | Guatemala | Honduras | Nicaragua | Panama | Haiti | Antigua and Barbuda | Bahamas | Barbados | Cuba | Dominica | Grenada | Jamaica | Saint Lucia | Saint Kitts and Nevis | Saint Vincent and the Grenadines | Trinidad and Tobago
                                    </p>
                                </div>
                            </Tab>
                            <Tab eventKey="oceania" title="Oceania">
                                <div className='contientTabIner'>
                                    <p>
                                        Australia | Fiji | New Zealand | Federated States of Micronesia | Kiribati | Marshall Islands | Nauru | Palau | Papua New Guinea | Samoa | Solomon Islands | Tonga | Tuvalu | Vanuatu
                                    </p>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Continent