import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';

const CategoryEdit = (props: any) => {
    const [name, setName] = useState('');
    const [redirect, setRedirect] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`categories/${id}`);
                setName(data.name);
            }
        )();
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            await axios.put(`categories/${id}`, {
                name,
            });
            setRedirect(true);
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/category'} />
    }

    return (
        <Wrapper>
            <div className="CardPedboxCover">
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Modify Category</h1>
                    </div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={12}>
                                <div className="InputMainComset">
                                    <label htmlFor="firstName" className="form-label">Category Name</label>
                                    <input type="text" className="form-control" id="firstName"
                                        defaultValue={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default CategoryEdit;