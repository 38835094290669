import React from 'react'

import './../aboutus/about-style.css';

import Button from 'react-bootstrap/Button';
import { FaCloudDownloadAlt } from "react-icons/fa";

import Accordion from 'react-bootstrap/Accordion';

import { FiArrowUpRight } from "react-icons/fi";

import { FAQ } from '../../assets';

import { RiArrowRightSLine } from "react-icons/ri";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

function FrequentlyAskedQuestion() {
    return (
        <>
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='FreqAskQuestboxInpg'>
                    <div className='FreqAskQuestboxRight'>

                        <h1>Frequently Asked Question</h1>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What types of transformers does VoltageShift manufacture?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift manufactures a wide range of transformers, including power transformers, distribution transformers, Dry-type transformers, Furnace Transformers, Solar inverter duty transformers and Compact Secondary Substations (CSS) and Industrial transformers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Does VoltageShift have a global presence and export its transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift has a global presence and exports its transformers to 45+ countries. With a network of international clients and distributors, VoltageShift serves customers worldwide, delivering our high-quality transformers to meet the global demand.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Does VoltageShift provide technical support for transformer-related queries?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift provides technical support for transformer-related queries. Our team of experts is available to assist customers with any technical questions or concerns they may have. We are knowledgeable in transformer design, functionality, and application, and can provide valuable guidance and solutions to ensure customer satisfaction.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Does VoltageShift offer customization options for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift offers customization options for transformers. We can tailor the specifications and features of the transformer to meet the specific requirements of the customer.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can VoltageShift provide installation and commissioning services for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift provides installation and commissioning services for transformers to ensure proper functioning and optimal performance. We also offers a comprehensive range of transformer services that include testing, repair, maintenance, oil filtration, transformer rewinding, relocation, emergency repairs, and annual maintenance contract (AMC).</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Does VoltageShift offer maintenance and repair services for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift offers maintenance and repair services for transformers. With 20+ years of experience, our skilled technicians can diagnose and resolve issues to ensure the smooth operation of the transformers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>What is the warranty period for transformers supplied by VoltageShift?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The warranty period for transformers supplied by us may vary depending on the type and specifications of the transformer. It is recommended to contact VoltageShift directly for specific warranty details.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>How can I request a quote for a transformer from VoltageShift?</Accordion.Header>
                                <Accordion.Body>
                                    <p>To request a quote for a transformer from VoltageShift, you can visit our website and fill out the inquiry form or contact their sales team directly.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Can VoltageShift assist with transformer sizing and selection?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift can assist with transformer sizing and selection. With 20+ years of experience, our skilled team can analyze the electrical requirements and application details to recommend the most suitable transformer for the specific needs.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>What quality standards do VoltageShift transformers comply with?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift transformers comply with international quality standards such as IEC (International Electrotechnical Commission) and IS (Indian Standards). They are designed and manufactured to meet or exceed these industry standards.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>How long does it typically take for VoltageShift to deliver a transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The delivery time for a transformer from VoltageShift may vary depending on factors such as customization requirements and order volume. It is best to check with our team for an estimated delivery timeline based on your specific needs.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
            </div>

            <ConsultationForm />

            <BlogList />

            <Continent />

        </>
    )
}

export default FrequentlyAskedQuestion