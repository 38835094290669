/* import { Category } from "../../models/category";

export const setCategoryReducer = (state = { categoryData: new Category() }, action: { type: string, categoryData: Category }) => {
    switch (action.type) {
        case "SET_CATEGORY":
            return {
                ...state,
                selectedCategoryID: action.categoryData.id
            }
        default:
            return state;
    }
} */

import { Category } from "../../models/category";

interface SetCategoryAction {
    type: string;
    categoryData: Category;
}

export const setCategoryReducer = (state = { selectedCategoryID: null }, action: SetCategoryAction) => {
    switch (action.type) {
        case "SET_CATEGORY":
            return {
                ...state,
                selectedCategoryID: action.categoryData.id,
            };
        case "RESET_CATEGORY":
            return {
                ...state,
                selectedCategoryID: null,
            };
        default:
            return state;
    }
};
