import React from 'react'
import './transformers-style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FiArrowUpRight } from "react-icons/fi";
import { PowerTransformImg1, PowerTransformImg2, PowerTransformImg3, PowerTransformImg4, PowerTransformImg5, PowerTransformImg6 } from '../../assets';

import Row from 'react-bootstrap/Row';

function TransformersSlider() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    return (
        <div className='CustContainer'>
            <div className='OthetranprodListTitle'>
                <h2>Other Transformers</h2>
            </div>


            <div className='OthetranprodListbox ComArrowSlidercs'>
                <Row>
                    <Slider {...settings}>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/power-transformer">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg1} alt="Power Transformer" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Power Transformer</h3>
                                        <p>2.5MVA To 50 MVA, Voltage
                                            Class upto 132 KV</p>
                                        <h5>Designed to Withstand Electrical Surges and Temperature Fluctuations</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/distribution-transformer">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg2} alt="Distribution Transformer" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Distribution Transformer</h3>
                                        <p>100 KVA To 2500 KVA,
                                            Voltage class upto 33 KV</p>
                                        <h5>Designed with Low Magnetizing Current by minimizing the load on electricity bills</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/cast-resin-dry-type-transformer">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg3} alt="Dry Type Transformer" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Dry Type Transformer</h3>
                                        <p>Up to 05 MVA, Voltage
                                            class upto 33 KV</p>
                                        <h5>Compact Design, Low in Weight, and Lesser Maintenance Cost</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/furnace-transformer">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg4} alt="Furnace Transformer" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Furnace Transformer</h3>
                                        <p>Up to 15 MVA, Voltage
                                            class upto 33 KV</p>
                                        <h5>High Current Capacity Transformer, Best for Steel & Metal Industries</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/solar-inverter-duty-transformer">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg5} alt="Solar Inverter Duty Transformer" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Solar Inverter Duty Transformer</h3>
                                        <p>Up to 15 MVA, Voltage
                                            Class upto 33 KV</p>
                                        <h5>Best for Solar & Wind Power Plants and Renewable Energy</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='ProductDataCov'>
                            <div className='ProductDataIner'>
                                <a href="/compact-secondary-substations">
                                    <div className='ProductInerCovImg'>
                                        <img src={PowerTransformImg6} alt="Compact Secondary Substations" />
                                    </div>
                                    <div className='ProductInerCovText'>
                                        <h3>Compact Secondary Substations (CSS)</h3>
                                        <p>100 KVA To 2500 KVA,
                                            Voltage class upto 33 KV</p>
                                        <h5>Best for Commercial & Residential Buildings, Easy for Transportation</h5>
                                        <h6>Know More <FiArrowUpRight /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </Slider>
                </Row>
            </div>


        </div>
    )
}

export default TransformersSlider