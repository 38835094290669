import React, { useEffect, useState } from 'react';
import './blog-style.css';
import '../../components/blog/blog-slider-style.css';
import { RiArrowRightSLine, RiPagesFill, RiTimeFill, RiUser3Fill } from "react-icons/ri";
import { CgSearch } from "react-icons/cg";
import Form from 'react-bootstrap/Form';
import { FiArrowUpRight } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import Continent from '../../components/continent/Continent';
import ConsultationForm from '../../components/common/consultation';
import axios from 'axios';
import moment from 'moment';
import Pagination from '../../components/pagination/index';
import BlogListCard from '../../components/common/cards/BlogListCard';
import { resetCategory } from '../../redux/actions/setCategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

function BlogList() {
    const myactive = {
        backgroundColor: "#f1f1f1",
        color: "green",
    };
    const [seos, setSeos] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [seoRecentPost, setSeoRecentPost] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');
    const [seoCount, setSeoCount] = useState(0);
    const [categoryIds, setCategoryIds] = useState<number | null>(null);
    type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
    const dispatch = useDispatch();

    const selectedCategoryID = useSelector((state: any) => state.category.selectedCategoryID);

    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    const [ogImage, setOgImage] = useState('');


    useEffect(() => {
        if (selectedCategoryID) {
            setCategoryIds(selectedCategoryID);
        }
        document.title = 'News';
        fetchSeoList(currentPage, itemsPerPage, searchText, categoryIds, 'main');
    }, [currentPage, itemsPerPage, searchText, categoryIds, selectedCategoryID]);

    useEffect(() => {
        fetchCategory();
        fetchSeoList(1, 3, '', null, 'recent');
    }, []);

    const fetchSeoList = async (currentPage: number, itemsPerPage: number, searchText: string, categoryIds: number | null, types: string) => {
        const { data } = await axios.get(`getseolisthome?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}&category_id=${categoryIds}`);
        if (types === 'main') {
            setSeoCount(data.data.length);
            setSeos(data.data);
            /* SEO - Start */
            setMetaTitle(data.data.meta_title)
            setMetaKeyword(data.data.meta_keyword)
            setMetaDescription(data.data.meta_description)
            setFocusKeyword(data.data.focus_keyword)
            setOgTitle(data.data.og_title)
            setOgDescription(data.data.og_description)
            setOgImage(data.data.og_image)
            /* SEO - End */
            if (categoryIds) {
                setTimeout(function () {
                    const parentDiv = document.getElementById('cate_anchor');
                    const activeLiElements = parentDiv?.getElementsByClassName('active');
                    while (activeLiElements?.length) {
                        activeLiElements[0].classList.remove('active');
                    }
                    const elements = document.getElementsByClassName(`cat_li_${data.data[0].category.id}`);
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].classList.add('active');
                    }
                }, 100)
            }
        } else {
            setSeoRecentPost(data.data)
        }
    };

    const fetchCategory = async () => {
        const { data } = await axios.get(`categorylist`);
        setCategoryList(data);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    const handleSearch = (e: React.KeyboardEvent<FormControlElement>) => {
        setSearchValue(e.currentTarget.value);
    };

    const handleCategoryClick = (cateId: number) => {
        const parentDiv = document.getElementById('cate_anchor');
        const activeLiElements = parentDiv?.getElementsByClassName('active');
        while (activeLiElements?.length) {
            activeLiElements[0].classList.remove('active');
        }
        // document.getElementById('cate_anchor')?.classList.remove('active');
        const elements = document.getElementsByClassName(`cat_li_${cateId}`);
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('active');
        }
        setCategoryIds(cateId);
        dispatch(resetCategory());
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) - itemsPerPage;
    const currentItems = seos.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                canonicalURL={FRONT_BASE_URL + `/news`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>

            <div className='CustContainer'>
                <div className="bloglisttitleCov">
                    <div className="bloglisttitleLeft">
                        <h1>News</h1>
                    </div>
                    <div className="bloglisttitleRight">
                        <Form.Group className="SearchBlogbox" controlId="search_news">
                            <CgSearch />
                            <Form.Control type="" placeholder="Search News" onKeyUp={handleSearch} />
                        </Form.Group>
                    </div>
                </div>

                <div className='BlogListPartDetail'>
                    <div className='BlogDetailsLeft'>
                        {
                            currentItems && currentItems.length > 0 ? (
                                currentItems.map((data1: any) => (
                                    <>
                                        <div className='BlogDetailsNewsCov' key={data1.id}>
                                            <div className='BlogDetailsNewsIner'>
                                                <div className='BlogDetailsInerImg'>
                                                    <img src={`${axios.defaults.baseURL}${data1.image}`} alt={`${data1.slug}`} />
                                                </div>
                                                <div className='BlogDetailsInerTxt'>
                                                    <h3><a href={`/news/${data1.slug}`}>{data1.h1_title}</a></h3>

                                                    <div className="TimeandcateboxMaincovb">
                                                        <h6><RiTimeFill /> {moment.utc(data1.created_at).startOf('day').format('MMMM DD, YYYY')}</h6>
                                                        <h6><RiPagesFill /> {data1.category.name}</h6>
                                                    </div>

                                                    <p dangerouslySetInnerHTML={{ __html: data1.description }}></p>
                                                    <a href={`/news/${data1.slug}`}>Learn More <FiArrowUpRight /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <div className='BlogDetailsNewsCov'>
                                    <div className='BlogDetailsNewsIner'>
                                        No Blog Found
                                    </div>
                                </div>
                            )
                        }

                        <div className='PaginationCustbox PaginationCusUser'>
                            <Pagination
                                totalItems={seoCount}
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                onPageChange={handlePageChange}
                                onItemsPerPageChange={handleItemsPerPageChange}
                            />
                        </div>
                    </div>
                    <div className='BlogDetailsRight'>
                        <div className='BlogDetRecentPostTitle'>
                            <h2>Categories</h2>
                        </div>
                        <div className='CategorieListBlog' id='cate_anchor'>
                            <ul>
                                <li className={`cat_li_${0} active`}><a onClick={() => handleCategoryClick(0)}>All</a></li>
                                {
                                    categoryList && categoryList.length > 0 ? (
                                        categoryList.map((cate: any) => (
                                            <>
                                                <li className={`cat_li_${cate.id}`}>
                                                    <a onClick={() => handleCategoryClick(cate.id)}>{cate.name}</a>
                                                </li>
                                            </>
                                        ))
                                    ) : (
                                        <div>No Category</div>
                                    )
                                }
                            </ul>
                        </div>

                        <div className='BlogDetRecentPostTitle'>
                            <h2>Recent Post</h2>
                        </div>
                        <div className='BlogDetRecentPostList'>
                            {seoRecentPost && seoRecentPost.length > 0 ? (
                                seoRecentPost.map((data1: any) => (
                                    <>
                                        <BlogListCard
                                            slug={data1.slug}
                                            image={data1?.image}
                                            h1_title={data1.h1_title}
                                            id={data1?.id}
                                            created_at={data1.updated_at || data1.created_at}
                                        />
                                    </>
                                ))
                            ) : (
                                <div>No News Found</div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div >
            <ConsultationForm />
            <Continent />
        </>
    )
}

export default BlogList