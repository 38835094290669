import React from 'react'
import './thankyou-style.css';

import { ThankYouImg } from '../../assets';


function ThankYou() {
    return (
        <>
            <div className='CustContainer'>
                <div className='ThnkpagemainCov'>
                    <img src={ThankYouImg} alt="Thank You" />
                    <h1>Thank You for Reaching Out to VoltageShift!</h1>
                    <p>Our team will review your information and get back to you promptly. <br /> We appreciate your interest in our products and services and look forward to assisting you further.</p>
                    <a href="/">Back to home</a>
                </div>
            </div>
        </>
    )
}

export default ThankYou