/* export const categoryAction = (selectedCategoryID: any) => {
    return (dispatch: any) => {
        dispatch({
            type: "selectCategoryData",
            payload: selectedCategoryID
        })
    }
}
 */
/* import { Category } from "../../models/category";

export const setCategory = (category: Category) => {
    return {
        type: 'SET_CATEGROY',
        selectedCategoryID: category.id
    }
} */

import { Category } from "../../models/category";

export const setCategory = (category: Category) => {
    return {
        type: 'SET_CATEGORY',
        categoryData: category,
    };
};

export const resetCategory = () => {
    return {
        type: 'RESET_CATEGORY',
    };
};