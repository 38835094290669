import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { BsInfoCircleFill } from "react-icons/bs";

function ManagePasswordModal(props: { show: boolean, onHide: any }) {
    const animatedComponents = makeAnimated();
    const options = [
        { value: '1', label: 'Role A' },
        { value: '2', label: 'Role B' },
        { value: '3', label: 'Role C' }
    ]

    const options2 = [
        { value: '1', label: 'Role A' },
        { value: '2', label: 'Role B' },
        { value: '3', label: 'Role C' }
    ]
    return (
        <>

            <Modal
                {...props}
                backdrop="static"
                keyboard={false}
                className="ComMainclboxmodalCov"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className='AddRemoveRoleboxmain'>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="InputMainComset" controlId="">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="" placeholder="Enter here" />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="InputMainComset" controlId="">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control type="" placeholder="Enter here" />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <div className='noteboxinfoboxmod'>
                                <p><BsInfoCircleFill /> If Left Empty and clicked on “Change Password” random password will be generated.</p>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className='ModalSubBtncov'>
                                <Button>Change Password</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManagePasswordModal