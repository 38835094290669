import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import TextEditor from '../../components/editor/ckeditor';
import { Category } from '../../models/category';
import { errorToast } from '../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';

const SeoEdit = (props: any) => {
    const [pagename, setPagename] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [news_img, setNewsImg] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [meta_title, setMetaTitle] = useState('');
    const [meta_description, setMetaDescription] = useState('');
    const [meta_keyword, setMetaKeyword] = useState('');
    const [focus_keyword, setFocusKeyword] = useState('');
    const [og_title, setOgTitle] = useState('');
    const [og_description, setOgDescription] = useState('');
    const [image_alt_tag, setImageAltTag] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const { id } = useParams();
    const [contentId, setContentId] = useState(0);

    useEffect(() => {
        fetchData();
        setContentId(1);
    }, []);
    const fetchData = async () => {
        const { data } = await axios.get(`seos/${id}`);
        setTitle(data.h1_title)
        setSlug(data.slug)
        setDescription(data.description)
        setCategoryId(data.category.id)
        setMetaTitle(data.meta_title)
        setMetaDescription(data.meta_description)
        setMetaKeyword(data.meta_keyword)
        setFocusKeyword(data.focus_keyword)
        setOgTitle(data.og_title)
        setOgDescription(data.og_description)
        setNewsImg(data.image)
        setImageAltTag(data.image_alt_tag)
        setPagename(data.pagename);
    };
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const { data } = await axios.put(`pages-seos/${id}`, {
                pagename: pagename,
                h1_title: title,
                slug: slug,
                description: description,
                image: news_img,
                meta_title: meta_title,
                meta_description: meta_description,
                meta_keyword: meta_keyword,
                focus_keyword: focus_keyword,
                og_title: og_title,
                og_description: og_description,
                image_alt_tag: image_alt_tag
            });
            if (data.status === 200) {
                if (selectedFiles !== null) {
                    const formData = new FormData();
                    for (let i = 0; i < selectedFiles.length; i++) {
                        formData.append('image', selectedFiles[i]);
                    }
                    const { data } = await axios.post('upload', formData);
                    if (data.status !== 400) {
                        const urls = data.url;
                        const res = await axios.put(`seos/${id}`, {
                            pagename: pagename,
                            category: categoryId,
                            image: urls,
                            og_image: urls,
                            h1_title: title,
                            slug: slug,
                            description: description,
                        });
                        setRedirect(true);
                    } else {
                        errorToast(data.message);
                        return false;
                    }
                } else {
                    setRedirect(true);
                }
            } else {
                errorToast(data.message)
                return false;
            }
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/pages-seo'} />
    }

    function generateSlug(title: any) {
        const slug = title
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-');
        return slug;
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedFiles(e.target.files);
        }
    };

    const onChange = (data: any) => {
        var span = document.createElement('span');
        span.innerHTML = data;
        setDescription((span.textContent || span.innerText).trim());
    }
    return (
        <Wrapper>
            <ToastContainer />
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Update SEO</h1>
                    </div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="pagename" className="form-label">Pagename</label>
                                    <input type="text" className="form-control" id="fullName" defaultValue={pagename}
                                        onChange={e => setPagename(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control" id="title" defaultValue={title}
                                        onChange={e => { setTitle(e.target.value); setSlug(generateSlug(e.target.value)); }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="slug" className="form-label">Slug</label>
                                    <input type="text" className="form-control" id="slug" defaultValue={slug}
                                        onChange={e => setSlug(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_title" className="form-label">Meta Title</label>
                                    <input type="text" className="form-control" id="meta_title" defaultValue={meta_title}
                                        onChange={e => setMetaTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_description" className="form-label">Meta Description</label>
                                    <input type="text" className="form-control" id="meta_description" defaultValue={meta_description}
                                        onChange={e => setMetaDescription(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_keyword" className="form-label">Meta Keyword</label>
                                    <input type="text" className="form-control" id="meta_keyword" defaultValue={meta_keyword}
                                        onChange={e => setMetaKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="focus_keyword" className="form-label">Focus Keyword</label>
                                    <input type="text" className="form-control" id="focus_keyword" defaultValue={focus_keyword}
                                        onChange={e => setFocusKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default SeoEdit;