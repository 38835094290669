import React from 'react';

const GenerateSitemap: React.FC = () => {
  const generateSitemap = (): string => {
    // Logic to generate the sitemap
    const sitemap: string = `<?xml version="1.0" encoding="UTF-8" ?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
      http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
      <url>
        <loc>https://www.voltageshift.com</loc>
        <changefreq>daily</changefreq>
        <priority>1</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/transformers</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/transformer-services</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/power-transformer</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/distribution-transformer</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/cast-resin-dry-type-transformer</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/furnace-transformer</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/solar-inverter-duty-transformer</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/compact-secondary-substations</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/transformer-spares</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/pressure-relief-valve</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/about-us</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/contact-us</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/privacy-policy</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/installation-and-commissioning</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/transformer-testing-service</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/oil-filtration</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/on-site-fault-troubleshooting</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/overhauling-services</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/repairing-service</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/annual-maintenance-contract</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/turnkey-projects</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/news</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/choosing-the-right-transformer-a-guide-to-ensuring-quality-and-performance</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/the-importance-of-choosing-the-right-service-provider-for-transformers</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/the-backbone-of-industrial-power-unveiling-the-importance-of-transformers</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/contactus-thankyou</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/quotetion-thankyou</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/ncc-thankyou</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/subscription-thankyou</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/faq</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://www.voltageshift.com/generate-sitemap</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>

    </urlset>`;

    return sitemap;
  };
  const downloadSitemap = () => {
    const sitemap = generateSitemap();
    const blob = new Blob([sitemap], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sitemap.xml');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // const downloadSitemap = () => {
  //   const sitemap = generateSitemap();
  //   const blob = new Blob([sitemap], { type: 'application/xml' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'sitemap.xml');

  //   // Specify the root directory path for saving the sitemap
  //   const rootDirectoryPath = '../../public';

  //   // Create a temporary <a> element with the URL and download attribute
  //   link.href = `${rootDirectoryPath}${link.href}`;

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  return (
    <div>
      <h1>Generate Sitemap</h1>
      <button onClick={downloadSitemap}>Download Sitemap</button>
    </div>
  );
};

export default GenerateSitemap;