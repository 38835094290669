import React, { useState } from 'react';
import './contactusinfo-style.css';
import { FiArrowUpRight } from "react-icons/fi";
import Button from 'react-bootstrap/Button';

import GetaQuote from "../common/getquote/GetaQuote";

import { ContactUsIcon } from '../../assets';

function ContactUsInfo() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />
            <div className='ContactUsMainCov'>
                <p>Looking for reliable and high-quality
                    transformer and services? Look no further than VoltageShift! Our team of experts is
                    dedicated to providing top-notch products and services to meet all of your transformer
                    needs.</p>
                <Button onClick={handleShow}>Request a quote <FiArrowUpRight /></Button>
                <img src={ContactUsIcon} alt="Inquiry Now"/>
            </div>
        </>
    )
}

export default ContactUsInfo