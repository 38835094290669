export const API_BASE_URL = 'https://www.voltageshift.com:8001/api/';
export const FRONT_BASE_URL = 'https://www.voltageshift.com';
export const FRONT_BACKEND_BASE_URL = 'https://www.voltageshift.com/backend/';
export const ERR_FULL_NAME_REQUIRED = 'Full Name is required!!';
export const ERR_LAST_NAME_REQUIRED = 'Last Name is required!!';
export const ERR_EMAIL_REQUIRED = 'Email Address is required!!';
export const ERR_INVALID_EMAIL = 'Invalid email address!!';
export const ERR_PHONE_REQUIRED = 'Phone number is required!!';
export const ERR_INVALID_PHONE = 'Invalid phone number!!';
export const ERR_FAILED_EMAIL = 'Failed to send email, Please try again!!';
export const ERR_QUERY_MESSAGE = 'Please write your query!!';
export const ERR_REQ_MESSAGE = 'Please write your requirement!!';
export const ERR_REQ_SUBJECT = 'Please write your subject!!';
export const ERR_INQUIRY_MESSAGE = 'Select your inquiry type!!';


export const FRM_NO_COST = 'No Cost Consultation';
export const FRM_NEWS_SUB = 'Newsletter';
export const FRM_GET_QUOTE = 'Get A Quotation';
export const FRM_CONTACT = 'Contact Us';