import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import { ToastContainer } from 'react-toastify';
import { errorToast, successToast } from '../../utils/toastrUtils';
import { Col, Row } from 'react-bootstrap';

const SeoCreate = () => {
    const [pagename, setPagename] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [news_img, setNewsImg] = useState('');
    const [category, setCategory] = useState('');
    const [meta_title, setMetaTitle] = useState('');
    const [meta_description, setMetaDescription] = useState('');
    const [meta_keyword, setMetaKeyword] = useState('');
    const [focus_keyword, setFocusKeyword] = useState('');
    const [og_title, setOgTitle] = useState('');
    const [og_description, setOgDescription] = useState('');
    const [og_image, setOgImage] = useState('');
    const [image_alt_tag, setImageAltTag] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const { data } = await axios.post('pages-seos', {
                pagename: pagename,
                h1_title: title,
                slug: slug,
                description: description,
                image: news_img,
                meta_title: meta_title,
                meta_description: meta_description,
                meta_keyword: meta_keyword,
                focus_keyword: focus_keyword,
                og_title: og_title,
                og_description: og_description,
                image_alt_tag: image_alt_tag
            });
            const seoId = data.id;
            if (selectedFiles !== null) {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('image', selectedFiles[i]);
                }
                const response = await axios.post('upload', formData);
                const url = response.data.result;
                await axios.put(`seos/${seoId}`, {
                    image: url,
                    og_image: url,
                });
            }
            if (data && data.status === 200) {
                successToast('Success');
                setRedirect(true);
            } else {
                errorToast(data.message);
            }
        } catch (error: any) {
            console.log(error);

            console.log(`${error.request.status}: ${error.request.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/pages-seo'} />
    }

    function generateSlug(title: any) {
        const slug = title
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-');
        return slug;
    }

    return (
        <Wrapper>
            <ToastContainer />
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Add SEO</h1>
                    </div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="pagename" className="form-label">Pagename</label>
                                    <input type="text" className="form-control" id="fullName" onChange={e => setPagename(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control" id="title"
                                        onChange={e => { setTitle(e.target.value); setSlug(generateSlug(e.target.value)); }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="slug" className="form-label">Slug</label>
                                    <input type="text" className="form-control" id="slug" value={slug}
                                        onChange={e => setSlug(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_title" className="form-label">Meta Title</label>
                                    <input type="text" className="form-control" id="meta_title"
                                        onChange={e => setMetaTitle(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_description" className="form-label">Meta Description</label>
                                    <input type="text" className="form-control" id="meta_description"
                                        onChange={e => setMetaDescription(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="meta_keyword" className="form-label">Meta Keyword</label>
                                    <input type="text" className="form-control" id="meta_keyword"
                                        onChange={e => setMetaKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="InputMainComset">
                                    <label htmlFor="focus_keyword" className="form-label">Focus Keyword</label>
                                    <input type="text" className="form-control" id="focus_keyword"
                                        onChange={e => setFocusKeyword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default SeoCreate;