import React, { useState } from 'react';
import './header-style.css';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { Nav, Navbar } from "react-bootstrap";
import { Logo } from '../../../assets';

import Button from 'react-bootstrap/Button';

import GetaQuote from "../../common/getquote/GetaQuote";

function Header() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />
            <header>
                <div className='headerTopPart'>
                    <div className='CustContainer'>
                        <div className='headerTopCover'>
                            <div className='headerTopLeft'>
                                <a href="tel:+91 9974466992"><BsFillTelephoneFill /> +91 99744 66992</a>
                                <span className='divdLine'></span>
                                <a href="mailto:info@voltageshift.com"><MdEmail /> info@voltageshift.com</a>
                            </div>
                            <div className='headerTopRight'>
                                <h3>Follow Us:</h3>
                                {/* <a href=""><FaFacebookF /></a> */}
                                <a href="https://www.linkedin.com/company/voltageshift/" target="_blank"><BsLinkedin /></a>
                                {/* <a href=""><FaYoutube /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='HeaderMainbox'>
                    <div className='CustContainer'>
                        <Navbar collapseOnSelect expand="lg" className='headermainCov'>

                            <Navbar.Brand href="/" className='headerlogo-set'>
                                <img src={Logo} alt="logo" />
                            </Navbar.Brand>

                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                            <Navbar.Collapse id="responsive-navbar-nav" className=''>
                                <Nav className="">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="/transformers">Transformers</Nav.Link>
                                    <Nav.Link href="/transformer-services">Services</Nav.Link>
                                    <Nav.Link href="/transformer-spares">Spares</Nav.Link>
                                    <Nav.Link href="/about-us">About Us</Nav.Link>
                                    <Nav.Link href="/news">News</Nav.Link>
                                    <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>

                            <div className="getaQuote_btn">
                                <Button onClick={handleShow}>Get a Quote</Button>
                            </div>

                        </Navbar>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header