import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'

function ServiceCont() {
    return (
        <>
            <div className='doyouwantCont'>
                <h4>Do you want to work with us?</h4>
                <p>VoltageShift offers a comprehensive range of transformer services.</p>
                <a href="/contact-us">Contact Us <FiArrowUpRight /></a>
            </div>
        </>
    )
}

export default ServiceCont