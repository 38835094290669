import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Wrapper from "../../components/Wrapper";
import BTable from 'react-bootstrap/Table';
import Pagination from '../../components/pagination/index';
import { RiEditBoxLine, RiDeleteBinLine, RiStore3Fill, RiSave2Line } from 'react-icons/ri';
import { GoPlus } from "react-icons/go";
import { Sitemap } from '../../models/sitemap';
import Toggle from 'react-toggle'
import { successToast } from '../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';

const Sitemaps = () => {
    const [sitemaps, setSitemaps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');
    const [sitemap_cnt, setSitemapCount] = useState(0);
    const [priority, setPriority] = useState('');
    const [changeFreq, setChangeFreq] = useState('');

    useEffect(() => {
        const fetchSitemaps = async () => {
            setLoading(true);
            const { data } = await axios.get(`sitemaps?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
            setSitemapCount(data.meta.total);
            setSitemaps(data.data);
            setLoading(false);
        };
        fetchSitemaps();
    }, [currentPage, itemsPerPage, searchText]);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    const statusChange = async (event: any, smid: number) => {
        let curr_stat = (event.target.checked === true) ? '0' : '1';
        try {
            const { data } = await axios.put(`sitemaps/${smid}`, {
                status: curr_stat,
            });
            successToast(data.message);
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    };
    const handleGenerateSitemap = async () => {
        setLoading(true);
        const { data } = await axios.get(`generate-sitemaps`);
        successToast(data.message);
        setLoading(false);
    };

    async function editSitemap(id: number) {
        const { data } = await axios.get(`sitemaps/${id}`);

        setChangeFreq(data.result.changefreq);
        setPriority(data.result.priority);

        const handlePriorityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const newValue = event.target.value;
            setPriority(newValue);
        };

        const handleChangeFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const newValue = event.target.value;
            setChangeFreq(newValue);
        };

        const changefreqSelect = (
            <select
                className="form-select"
                id={`changefreq_${id}`}
                onChange={handleChangeFreqChange}
            >
                <option key='0' value='always' selected={data.result.changefreq === 'always'}>Always</option>
                <option key='1' value='hourly' selected={data.result.changefreq === 'hourly'}>Hourly</option>
                <option key='2' value='daily' selected={data.result.changefreq === 'daily'}>Daily</option>
                <option key='3' value='weekly' selected={data.result.changefreq === 'weekly'}>Weekly</option>
                <option key='4' value='monthly' selected={data.result.changefreq === 'monthly'}>Monthly</option>
                <option key='5' value='yearly' selected={data.result.changefreq === 'yearly'}>Yearly</option>
                <option key='6' value='never' selected={data.result.changefreq === 'never'}>Never</option>
            </select>
        );

        const prioritySelect = (
            <select
                className="form-select"
                id={`priority_${id}`}
                onChange={handlePriorityChange}
            >
                <option key='0' value='0' selected={data.result.priority === '0'}>0</option>
                <option key='1' value='0.1' selected={data.result.priority === '0.1'}>0.1</option>
                <option key='2' value='0.2' selected={data.result.priority === '0.2'}>0.2</option>
                <option key='3' value='0.3' selected={data.result.priority === '0.3'}>0.3</option>
                <option key='4' value='0.4' selected={data.result.priority === '0.4'}>0.4</option>
                <option key='5' value='0.5' selected={data.result.priority === '0.5'}>0.5</option>
                <option key='6' value='0.6' selected={data.result.priority === '0.6'}>0.6</option>
                <option key='7' value='0.7' selected={data.result.priority === '0.7'}>0.7</option>
                <option key='8' value='0.8' selected={data.result.priority === '0.8'}>0.8</option>
                <option key='9' value='0.9' selected={data.result.priority === '0.9'}>0.9</option>
                <option key='10' value='1' selected={data.result.priority === '1'}>1</option>
            </select>
        );

        const changefreqContainer = document.getElementsByClassName(`changefreq_${id}`)[0];
        const priorityContainer = document.getElementsByClassName(`priority_${id}`)[0];

        const changefreqRoot = createRoot(changefreqContainer);
        changefreqRoot.render(changefreqSelect);

        const priorityRoot = createRoot(priorityContainer);
        priorityRoot.render(prioritySelect);

        const edit_btn = document.querySelectorAll(`.edit_btn_${id}`);
        edit_btn.forEach(e_btn => {
            const e_element = e_btn as HTMLElement;
            e_element.style.display = "none";
        });

        const save_btn = document.querySelectorAll(`.save_btn_${id}`);
        save_btn.forEach(s_btn => {
            const s_element = s_btn as HTMLElement;
            s_element.style.display = "inline";
        });
    }

    async function saveSitemap(id: number) {
        try {
            await axios.put(`update-sitemaps/${id}`, {
                priority: priority,
                changefreq: changeFreq,
            });
            document.getElementsByClassName(`priority_${id}`)[0].innerHTML = priority;
            document.getElementsByClassName(`changefreq_${id}`)[0].innerHTML = changeFreq;
            const edit_btn = document.querySelectorAll(`.edit_btn_${id}`);
            edit_btn.forEach(e_btn => {
                const e_element = e_btn as HTMLElement;
                e_element.style.display = "inline";
            });
            const save_btn = document.querySelectorAll(`.save_btn_${id}`);
            save_btn.forEach(s_btn => {
                const s_element = s_btn as HTMLElement;
                s_element.style.display = "none";
            });
            successToast('Record has been updated');
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    }

    return (
        <Wrapper>
            <ToastContainer />
            <div className="CardPedboxCover">
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Sitemaps URLs</h1>
                    </div>
                    <div className='AdmintopTitleRight'>
                        <div className='addeditBtnBoxTitle'>
                            {/* <Link to={'/admin/sitemaps/create'} className="AddBtnSet"><GoPlus />Generate Sitemap</Link> */}
                            <button type="button" className="AddBtnSet" onClick={handleGenerateSitemap}>
                                <GoPlus /> Generate Sitemap
                            </button>
                        </div>
                    </div>
                </div>
                <>
                    <div className='ComTableCardCov'>
                        <BTable striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th>Page Type</th>
                                    <th>URL</th>
                                    <th>Change freq.</th>
                                    <th>Priority</th>
                                    <th>Action</th>
                                    <th style={{ width: 35 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td></td>
                                        <td colSpan={6}>Loading...</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    <>
                                        {sitemaps.length > 0 ? (
                                            sitemaps.map((stmp: Sitemap) => {
                                                let curr_stat = stmp.status === '1' ? false : true;
                                                return (
                                                    <tr key={stmp.id}>
                                                        <td></td>
                                                        <td>{(stmp.page_type && stmp.page_type !== '') ? stmp.page_type.toUpperCase() : 'Static'}</td>
                                                        <td>{stmp.url}</td>
                                                        <td className={`changefreq_${stmp.id}`}>{stmp.changefreq}</td>
                                                        <td className={`priority_${stmp.id}`}>{stmp.priority}</td>
                                                        <td className='action_td'>
                                                            <label>
                                                                <Toggle
                                                                    defaultChecked={curr_stat}
                                                                    onChange={(e) => statusChange(e, stmp.id)} />
                                                            </label>
                                                            <span className={`edit_btn_${stmp.id}`}>
                                                                <button type="button" className="DownloadPageBtnCov" onClick={() => editSitemap(stmp.id)}><RiEditBoxLine /></button>
                                                            </span>
                                                            <span className={`save_btn_${stmp.id}`} style={{ 'display': 'none' }}>
                                                                <button type="button" className="DownloadPageBtnCov" onClick={() => saveSitemap(stmp.id)}><RiSave2Line /></button>
                                                            </span>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td></td>
                                                <td colSpan={2}>No data found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </BTable>
                    </div>
                </>
                <div className='PaginationCustbox PaginationCusUser'>
                    <Pagination
                        totalItems={sitemap_cnt}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
        </Wrapper >
    );
};
export default Sitemaps;