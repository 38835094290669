import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import TransformersSlider from './TransformersSlider';
import Button from 'react-bootstrap/Button';
import { BiCheck } from "react-icons/bi";

import { PowerTransformImg4, ServiceBrochure } from '../../assets';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function FurnaceTransformer() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Furnace Transformer';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/furnace-transformer`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>  
            <GetaQuote show={show} onHide={() => handleClose()} />

            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/furnace-transformer`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Furnace Transformer</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>
                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Furnace Transformer</h1>
                                <p>Delicately Designed & Engineered Furnace Transformers with Attention to Detail</p>
                            </div>
                        </div>
                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg4} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> Up to 15MVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 33KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> ONAN/OFWF</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Tap Changer :</span> Off Circuit or On Load Tap Changer Insulating</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IS 2026, IS 12977</h4>

                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>

                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Take pride in using VoltageShift's first-rate Furnace Transformers, manufactured with
                                attention to detail and thorough testing to withstand extreme weather conditions. As a leading <span>Furnace Transformer Manufacturing Company</span>, we aim to manufacture highly durable &
                                outstanding-performance Transformers with adherence to Industry's highest standards.</p>
                            <p>We strive to deliver cost-effective, highly reliable Furnace Transformer Solutions to build
                                long-term client relations. As a reputable <span>Furnace Transformer Manufacturer</span>, we employ
                                best practices, cutting-edge technology & tools deployed in-house to test & commission <span>Furnace Transformers</span> to ensure they operate at their peak performance, maximizing ROI
                                for the clients.</p>
                            <p>We use our immense experience & knowledge to deliver high-power electric <span>Industrial
                                Furnace Transformers</span>, such as arc, induction, and ladle furnace transformer, for efficient &
                                magnificent performance.</p>
                            <p>As a trustworthy <span>Furnace Transformer Exporter</span>, we export to 45+ countries, including
                                India, Singapore, Hong Kong, Malaysia, Iran, Iraq, Israel, and Jordan. Bank on and enjoy our
                                decades-lasting <span>Furnace Transformer</span>.</p>
                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Furnace Transformer Salient Features & Technical Specifications</h3>
                            <p>As a fast-growing <span>3 Phase Furnace Transformer Manufacturer</span>, we provide a range of <span>Furnace Transformers Services</span> from installation & commissioning, testing, repair &
                                maintenance - everything under a consolidated roof.</p>
                            <p>The following are the technical specifications & features of our <span>Three Phase Furnace Transformer</span>:</p>

                            <h4><BiCheck /> Power Rating upto 15 MVA</h4>
                            <h4><BiCheck /> Upto <span>33KV Class Furnace Transformer</span> Voltage Rating</h4>
                            <h4><BiCheck /> <span>Winding Material:</span> Copper</h4>
                            <h4><BiCheck /> <span>Cooling:</span> ONAN/OFWF</h4>
                            <h4><BiCheck /> The type of <span>Furnace Duty Transformer</span> is Indoor/Outdoor.</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dyn11 or as per specific requirement</h4>
                            <h4><BiCheck /> <span>Frequency Rating:</span> 50Hz or 60Hz</h4>
                            <h4><BiCheck /> <span>Tap Changer:</span> Off Circuit or On Load Tap Changer Insulating</h4>
                            <h4><BiCheck /> <span>Insulating Oil Types:</span> Mineral Oil/Ester Oil</h4>
                            <p>All our 3Phase Furnace Transformers adhere to IS 2026 and IS 12977 standards. We export
                                them to 45+ countries with the luxury of <span>Furnace Transformer Installation</span> on-site at the
                                International level.</p>

                        </div>
                    </div>

                </div>

                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Furnace Transformer Applications</h3>
                                <p>As a reliable <span> 3Phase Furnace Transformer Supplier</span>, we strive to fulfill various application
                                    needs of different industries, including turnkey projects, irrespective of their size & scale.
                                    The following are our Furnace Transformer Applications:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <h3>Salient Features</h3>
                                    <ul>
                                        <li><BiCheck /> Steel Industries</li>
                                        <li><BiCheck /> Metal Industries</li>
                                        <li><BiCheck /> Cement Industries</li>
                                        <li><BiCheck /> Mining Industries</li>
                                        <li><BiCheck /> Non-Ferrous Metal Industries</li>
                                        <li><BiCheck /> Rolling Mills</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>As a prominent <span>Furnace Transform Supplier</span>, we ensure the industries mentioned above
                                operate their business seamlessly by providing them with <span>Furnace Transformer Repair</span>
                                on-site at the International Level.</p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Advantages of Choosing Our Furnace Transformer</h3>
                        <h6>By partnering with us as your <span>Furnace Transformer Service Provider</span>, you will maximize
                            your ROI and realize value for your investment. The following are the salient advantages of
                            choosing our Furnace Transformer:</h6>
                        <p><BiCheck /> Premier Quality <span>Furnace Transformer Maintenance</span> involves timely servicing,
                            regular inspections, 24/7 Emergency Service, and more to keep your transformers
                            intact.</p>
                        <p><BiCheck /> Keep your Transformers operating like new ones with our readily available 100%
                            authentic & genuine <span>Furnace Transformer Spare Parts</span> deliverable to 45+
                            Countries.</p>
                        <p><BiCheck /> Our High Current Capacity Transformer has the Exceptional capability of
                            withstanding extreme weather fluctuations, electrical surges, and challenging
                            conditions.</p>
                        <p><BiCheck /> Customizable 3Phase Furnace Transformers Oil options to meet the client's specific
                            applications requirements & needs, including mineral & ester oils.</p>
                        <p><BiCheck /> Get your queries resolved promptly with our highly trained & professional Customer
                            Support Team, proficient in providing personalized furnace transformers solutions.</p>
                        <p><BiCheck /> Delicately Designed & Engineered to handle extra High Voltage Currents compared
                            to Conventional Transformers. And OFWF Cooling Class with 100% Standby
                            Capacity.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>

                <TransformersSlider />

                <ConsultationForm />


                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a Furnace Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>It is a machine designed & engineered to step up or down voltage levels of AC (alternating
                                        current) to make it ideal for applications in Industrial Heating systems. It uses
                                        electromagnetic induction to transmit electrical energy from one circuit to another.</p>
                                    <p>Its primary role is to regulate incoming voltage levels to meet the system's requirements. The
                                        Furnace Transformer increases or decreases electricity usage depending on the power the
                                        furnace needs.</p>
                                    <p>At VoltageShift, we are a leading <span>33KV Furnace Transformer Manufacturer & Supplier</span> in
                                        UAE, designing & engineering premier quality 33KV Furnace Transformers and exporting to
                                        45+ countries. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and free consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is a 15 MVA Furnace Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A <span>15 MVA Furnace Transformer</span> is a high-capacity electrical transformer to transfer power
                                        from one voltage level to another. It is primarily used in power transmission, generation, and
                                        distribution systems to fulfill various commercial & industrial applications due to high power
                                        & low current levels.</p>
                                    <p>It can operate at 1500V voltage input. They are used in industrial plants, electric robots, arc
                                        welding machines, and more.</p>
                                    <p>If you are searching for a <span>15 MVA Furnace Transformer Manufacturer & Supplier</span>,
                                        VoltageShift is your one-stop destination. Our Furnace Transformers are ideal for industrial
                                        applications. <a href="/contact-us" target="_blank">Contact us</a> for a free consultation and quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What are the Basic Electric Furnace Transformer Troubleshooting?</Accordion.Header>
                                <Accordion.Body>
                                    <p>We recommend the following Basic Troubleshooting Electric Furnace Transformer to fix
                                        minor issues from your end:</p>
                                    <ul>

                                        <li>1) Reset Circuit Breaker, and if the fuse is burned out, replace it.</li>
                                        <li>2) After that, remove the thermostat cover & delicately clean its components, especially
                                            the bimetallic coil & surfaces of a switch.</li>
                                        <li>3) Then, set the thermostat to its lowest setting to clean the bimetallic coil with
                                            compressed air or a soft brush.</li>
                                        <li>4) Next, set the thermostat to its highest setting to clean the coil again.</li>
                                        <li>5) After properly cleaning, reset the thermostat to its desired (original) settings.</li>
                                        <li>6) Ensure to correctly tighten all screw terminals. If any wire is loose, please carefully
                                            place it on the back of the terminal clamp to reinforce the screw.</li>
                                        <li> 7) Next, please check for any loose connections or disconnected wires on the
                                            low-voltage furnace transformer.</li>
                                        <li>8) And lastly, check on electronic thermostat models to see whether the display is
                                            working (indicating power). If power is not showing, please replace the thermostat
                                            batteries.</li>
                                    </ul>
                                    <p>For any assistance, feel free to <a href="/contact-us" target="_blank">contact us</a>. As a reliable    <span>Furnace Transformer Provider</span>, we sell Furnace Transformer Spare Parts which are 100% authentic and genuine. And we ship to 45+ countries.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default FurnaceTransformer