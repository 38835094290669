import { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

type Country = {
    id: number;
    phonecode: string;
    iso: string;
};

type CountrySelectProps = {
    onSelect: (selectedOption: Country | null) => void;
    selectedCountryId?: number;
    className?: string;
};

export default function CountrySelect({
    onSelect,
    selectedCountryId,
    className,
}: CountrySelectProps) {
    const [options, setOptions] = useState<Country[]>([]);
    const [selectedOption, setSelectedOption] = useState<Country | null>(null);

    useEffect(() => {
        async function fetchCountries() {
            try {
                const response = await axios.get<Country[]>('register');
                const countries: Country[] = response.data.map((country) => ({
                    id: country.id,
                    phonecode: country.phonecode,
                    iso: country.iso,
                }));
                setOptions(countries);
            } catch (error) {
                console.error(error);
            }
        }
        fetchCountries();
    }, []);

    useEffect(() => {
        if (selectedCountryId && options.length > 0) {
            const selected = options.find((option) => option.id === selectedCountryId) || null;
            setSelectedOption(selected);
        } else {
            setSelectedOption(null);
        }
    }, [options, selectedCountryId]);

    const handleSelect = (selectedOption: Country | null) => {
        setSelectedOption(selectedOption);
        onSelect(selectedOption);
    };

    return (
        <div className={className}>
            <Select
                value={selectedOption}
                onChange={handleSelect}
                options={options}
                className={className}
                getOptionValue={(option) => option.id.toString()}
                getOptionLabel={(option) => `+${option.phonecode} ${option.iso}`}
                isClearable={true}
            />
        </div>
    );
}


/* import { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

type CountrySelectProps = {
    onSelect: (selectedOption: any) => void;
    className?: string;
}

export default function CountrySelect({ onSelect, className }: CountrySelectProps) {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        async function fetchCountries() {
            try {
                const response = await axios.get('register');
                const countries = response.data.map((values: {
                    id: any; phonecode: any; iso: any;
                }) => ({
                    value: values.id,
                    label: `+${values.phonecode} ${values.iso}`,
                }));
                setOptions(countries);
            } catch (error) {
                console.error(error);
            }
        }
        fetchCountries();
    }, []);
    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelect = (selectedOption: any) => {
        setSelectedOption(selectedOption);
        onSelect(selectedOption);
    };
    return (
        <div className={className}>
            <Select
                value={selectedOption}
                onChange={handleSelect}
                options={options}
                className={className}
            />
        </div>
    );
} */