import React, { useEffect, useState } from 'react';
import './users-style.css';
import axios from 'axios';
import Pagination from '../../components/pagination/index';
import Wrapper from '../../components/Wrapper';
import BTable from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GoPlus, GoSearch } from "react-icons/go";
import { User } from '../../models/user';
import Button from 'react-bootstrap/Button';
import { RiEditBoxLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import ManagePasswordModal from '../../components/common/modal/managepassword/ManagePasswordModal';
import { API_BASE_URL } from '../../constants/commonConstants';
import superagent from 'superagent';

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const Index: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [users_cnt, setUsersCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchText, setSearchValue] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await superagent
                    .get(`${API_BASE_URL}users`)
                    .query({
                        page: currentPage,
                        size: itemsPerPage,
                        keyword: searchText
                    })
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json;charset=UTF-8')
                    .withCredentials();

                const data = response.body.data;
                setUsers(data);
                setUsersCount(response.body.meta.total);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [currentPage, itemsPerPage, searchText]);


    /* useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            const { data } = await axios.get(`users?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}`);
            setUsers(data.data);
            setUsersCount(data.meta.total);
            setLoading(false);
        };
        fetchUsers();
    }, [currentPage, itemsPerPage, searchText]); */

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (itemsPerPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(1);
    };

    const handleSearch = (e: React.KeyboardEvent<FormControlElement>) => {
        setSearchValue(e.currentTarget.value);
    };

    /* const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) - itemsPerPage;
    const currentItems = users.slice(indexOfFirstItem, indexOfLastItem); */
    const currentItems = users;

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <Wrapper>
            <ManagePasswordModal show={show} onHide={() => handleClose()} />
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Users</h1>
                    </div>
                    <div className='AdmintopTitleRight'>
                        <Form className="SerachFlttitlemain">
                            <Form.Group className="SearchTitleboxCov" controlId="">
                                <GoSearch />
                                <Form.Control type="text" placeholder="Search by Username/First name/ Email/ Mobile" onKeyUp={handleSearch} />
                            </Form.Group>
                        </Form>
                        <div className='addeditBtnBoxTitle'>
                            <Link to={'/admin/create-user'} className="AddBtnSet"><GoPlus /> Add</Link>
                        </div>
                    </div>
                </div>
                <>
                    <div className='ComTableCardCov'>
                        <BTable striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                    <th style={{ width: 35 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td></td>
                                        <td colSpan={5}>Loading...</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    <>
                                        {currentItems.length > 0 ? (
                                            currentItems.map((user: User) => {
                                                return (
                                                    <tr key={user.id}>
                                                        <td></td>
                                                        <td>{user.full_name}</td>
                                                        <td>{user.email}</td>
                                                        <td>+{user.countryEntity.phonecode} {user.mobile}</td>
                                                        <td>{user.created_at}</td>
                                                        <td className="usersActinLinkbox">
                                                            <a onClick={handleShow} className='cursor_pointer'>{user.role.name}</a>
                                                            <a onClick={handleShow} className='cursor_pointer'>Manage Password</a>
                                                            <Button variant='link' className='ViewPageiconBtn'><Link to={`/admin/update-user/${user.id}`} className="btn btn-sm btn-outline-secondary"><RiEditBoxLine /></Link></Button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td></td>
                                                <td colSpan={5}>No data found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </BTable>
                    </div>
                </>
                <div className='PaginationCustbox PaginationCusUser'>
                    <Pagination
                        totalItems={users_cnt}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
        </Wrapper >
    );
};

export default Index;