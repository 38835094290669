import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from "./login";
import Seo from "./seo";
import Users from "./users";
import UserCreate from './users/UserCreate';
import UserEdit from './users/UserEdit';
import Roles from './roles/Roles';
import RoleCreate from './roles/RoleCreate';
import RoleEdit from './roles/RoleEdit';
import Sidebar from '../components/common/sidebar';
import SeoCreate from './seo/seoCreate';
import SeoEdit from './seo/seoEdit';
import Categories from './category/category';
import CategoryCreate from './category/categoryCreate';
import CategoryEdit from './category/categoryEdit';
import Sitemaps from './sitemap/sitemaps';
import PageSeo from './pages-seo/index';
import PageSeoCreate from './pages-seo/seoCreate';
import PageSeoEdit from './pages-seo/seoEdit';

function App() {
    return (
        <Routes>
            {/* Start - Admin Route */}
            <Route path={'/login'} element={<Login />} />
            <Route path={'/users-list'} element={<Users />} />
            <Route path={'/create-user'} element={<UserCreate />} />
            <Route path={'/update-user/:id'} element={<UserEdit />} />
            <Route path={'/roles'} element={<Roles />} />
            <Route path={'/roles/create'} element={<RoleCreate />} />
            <Route path={'/roles/:id/edit'} element={<RoleEdit />} />
            <Route path={'/logout'} element={<Sidebar />} />
            <Route path={'/seo'} element={<Seo />} />
            <Route path={'/add-seo'} element={<SeoCreate />} />
            <Route path={'/update-seo/:id'} element={<SeoEdit />} />
            <Route path={'/category'} element={<Categories />} />
            <Route path={'/category/create'} element={<CategoryCreate />} />
            <Route path={'/category/:id/edit'} element={<CategoryEdit />} />
            <Route path={'/sitemaps'} element={<Sitemaps />} />
            <Route path={'/pages-seo'} element={<PageSeo />} />
            <Route path={'/add-pages-seo'} element={<PageSeoCreate />} />
            <Route path={'/update-pages-seo/:id'} element={<PageSeoEdit />} />
            {/* End - Admin Route */}
        </Routes>
    );
}

export default App;
