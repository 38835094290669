import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import { InstallationAndCommissioningImg, OilFiltrationDetailsImg, ServiceBrochure } from '../../assets';
import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function OilFiltration() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Oil filtration';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/oil-filtration`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/oil-filtration`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Oil Filtration</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={OilFiltrationDetailsImg} alt="Oil Filtration" />
                            <h1>Oil Filtration</h1>
                            <h6>Get the Most Reliable Transformers Oil Filtration Services</h6>
                            <p>Keep your Transformers in the best functioning condition with VoltageShift <span>On-site
                                Transformers Oil Filtration Services</span> provided at the International Level in 45+ countries.
                                Our <span>Transformers Oil Testing Services</span> ensure Transformers' excellent performance and
                                longevity. And we offer it for up to 400 KV Voltage Class.</p>
                            <p>Our <span>On-Site Transformers Oil Testing Services</span> are designed to remove dissolved moisture,
                                gasses, sludge, and contaminants to enhance Transformers' overall performance, efficiency,
                                and lifespan.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>Transformer Oil is a pivotal component for extending Transformer's lifespan, and Transformers Oil Filtration must be done periodically to maintain its purity & optimal performance.</p>
                                <p>Transformer Oil must be changed annually, as they are susceptible to degradation because of exposure to acid, moisture, and dust. It will adversely affect its functioning & damage internal components. Choose us for Testing of Transformer Oil to extend its longevity and boost performance.</p>
                            </div>
                        </div>

                        <div className='brektedetailsMaincov'>
                            <h3>Our Transformer Oil Filtration Services</h3>
                            <p>As one of the fastest-growing <span>Transformer Oil Testing Companies</span> in UAE & Africa, we
                                provide the following On-Site Transformer Oil Filtration Services at the International Level
                                in 45+ Countries:</p>


                            <h4>Advanced Filtration Technology</h4>
                            <p>We use Advanced Filtration Technology and tools to remove impurities from the Transformer
                                Oil. Our cutting-edge filtration systems are designed to eliminate the dissolved particles
                                because of exposure to acid & other external natural contaminants to prevent transformers'
                                degradation and malfunctioning.</p>
                            <p>We use various filters & technologies, including degasification, vacuum dehydration, and
                                particulate filtration for <span>Transformer Oil Streamlining</span>.</p>

                            <h4>On-site Services</h4>
                            <p>We offer On-Site Oil Filtration Services at the International Level in 45+ countries. By
                                partnering with us for <span>Transformer Oil Filtration Services</span>, your transformers' downtime
                                will be minimized. And they will operate excellently, maximizing your ROI.</p>
                            <p>We have a team of highly qualified and experienced technicians proficient in providing <span>Oil
                                Filtration Services for Transformers</span> by employing the latest technologies and tools. It is in
                                adherence to the industry's highest quality standards and best practices.</p>

                            <h4>Testing and Analysis</h4>
                            <p>We perform <span>Transformer Oil Analysis</span> by thoroughly testing oil after filtration to ensure it
                                complies with the industry's required standards. We follow a set of testing procedures that
                                include testing for acidity, moisture content, dust particles, dielectric strength, and other
                                factors to enhance the overall performance & quality of your Transformer.</p>
                            <p>We also perform Dissolved Gas Analysis (DGA) to detect and remove harmful gases from
                                the Transformer.</p>

                            <h4>Preventive Maintenance</h4>
                            <p>We offer <span>Transformer Preventive Maintenance Services</span>, which include timely servicing,
                                regular inspections, oil sampling & cleaning, testing, and 24/7 emergency services to identify
                                and address potential issues beforehand.</p>
                            <p>We also provide <span>Preventive Maintenance of Transformer Insulating Materials</span> to test the
                                transformer's winding insulation integrity and lower leakage currents for better insulation.</p>

                            <h4>Furan Analysis</h4>
                            <p>We use Furan Analysis to check whether the transformer unit should continue in service or
                                requires maintenance to replace the Robust Cellulose Paper Insulation. Furans are complex
                                compounds of hydrogen and carbon that get piled up inside the transformer oil because of the
                                degradation of cellulose paper insulation over time. We test whether the moisture is above the
                                limited values to take corrective action to avoid significant insulation failure.</p>


                        </div>

                        <div className='servdetailsListBox'>
                            <h6><BsInfoCircleFill /> Opt for Preventive Transformer Maintenance to prevent unexpected failures or faults in
                                the insulating transformer oil. It involves timely upgradation of the oil to avoid damage from
                                dissolved games, moisture content, acid formation, and sludge materials.</h6>
                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li className='active'><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. What is a Transformer Oil Filtration Machine?</Accordion.Header>
                            <Accordion.Body>
                                <p>Transformer Oil Filtration filters transformer oil through an oil purifier called Transformer
                                    Oil Filtration Machine. It filters impurities in contaminated and deteriorated transformer oil.
                                    It helps to keep Transformer oil clean and pure for its extended lifespan and peak
                                    performance.</p>
                                <p>Oil Filtration Machine enhances 66KV to 500KV Power Transmission Transformers. It is
                                    illustrious for efficiently and rapidly tracing & removing harmful gases, moisture, and other
                                    impurities (contamination) from the insulating oil. It enhances the performance and
                                    functioning of transformers, circuit breakers, capacitors, and cables with insulating systems.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What is Oil Reconditioning in Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>It is the process of removal of sludge materials formed inside the transformer oil by
                                    employing physical therapies, such as degasification, oil dehydration, and filtration.</p>
                                <p>Oil Reconditioning eliminates materials of substantial size & dissolved gases only in a minor
                                    amount. Hence, this technique is not considered in extreme conditions to enhance insulating
                                    oil quality. However, it is ideal for sludge materials removal.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. What is Oil Reclamation Technique in Transformers?</Accordion.Header>
                            <Accordion.Body>
                                <p>Oil Reclamation is a chemical process to remove polar compounds formed inside the
                                    transformer oil due to oxidation. It restores the quality of the aging transformer oil.</p>
                                <p>The oil is tested in a centrifugal unit for solid sludge material removal. After that, the entire
                                    oil volume is passed into a drying unit in a vacuum chamber to remove the moisture content
                                    present in the oil.</p>
                                <p>This technique ensures restoring the oil color, similar to the new transformer oil. It also
                                    reduces moisture & acid present in the insulating oil to increase insulation system dielectric
                                    strength.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default OilFiltration