import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { PowerTransformImg1, PowerTransformImg2, PowerTransformImg3, PowerTransformImg4, PowerTransformImg5, PowerTransformImg6, TICimg1 } from '../../assets';
import { FiArrowUpRight } from "react-icons/fi";
import Row from 'react-bootstrap/Row';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import { BiCheck } from 'react-icons/bi';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';
function Index() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Transformers';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/transformers`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/transformers`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li>Home</li>
                        <li><RiArrowRightSLine /></li>
                        <li>Transformers</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='TransfMaintopbox'>
                    <div className='TransfMaintopLeft'>
                        <h1>Transformers <br /> Manufacturing Company</h1>
                        <p>Converting Immense Experience into Reality by manufacturing
                            premier-quality Transformer products for numerous industries’ applications.</p>
                        <div className='CouYearClitMain'>
                            <div className='CouYearClitIner'>
                                <div className='CouYearClitInbox'>
                                    <h4>45+</h4>
                                    <h6>Countries</h6>
                                </div>
                            </div>
                            <div className='CouYearClitIner'>
                                <div className='CouYearClitInbox'>
                                    <h4>20+</h4>
                                    <h6>Years of <br /> Experience</h6>
                                </div>
                            </div>
                            <div className='CouYearClitIner'>
                                <div className='CouYearClitInbox'>
                                    <h4>244+</h4>
                                    <h6>Clients</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='TransfMaintopRight'>
                        <p>VoltageShift is a reputed <span>Transformers Manufacturer</span> in India, exporting a wide range of
                            Transformers to 45+ countries worldwide. We utilize our extensive years of experience,
                            learnings, knowledge, and expertise to develop premier quality transformers for numerous
                            industries, such as, such as Steel, Cement, Chemical, Manufacturing, Commercial &
                            Residential Buildings, Hospital and Pharmaceutical Industries, Food and Beverages
                            Industries, IT Industries, and more.</p>
                        <p>We are a trusted <span>Transformer Supplier</span> supplying transformers for all sizes, scales, and
                            complex projects, including turnkey. We provide comprehensive services from design &
                            engineering, manufacturing, installation & commissioning to repair & maintenance services.</p>
                    </div>
                </div>
            </div>
            <div className='TransfMainprodbox'>
                <div className='CustContainer'>
                    <div className='TransfMainprodTitle'>
                        <h2>Our Range of Transformers</h2>
                        <p>As one of the most trustworthy <span>Transformer Exporter</span>, we manufacture six principal ranges of Transformers to ensure our clients get the required Transformers at a one-stop destination. All our <span>Industrial Transformers</span> adhere to the quality standards of IS & IEC. The following is our Range of Transformers.</p>
                    </div>
                    <div className='ProductDataMain'>
                        <Row>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/power-transformer">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg1} alt="Power Transformer" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Power Transformer</h3>
                                            <p>2.5MVA To 50 MVA, Voltage
                                                Class upto 132 KV</p>
                                            <h5>Designed to Withstand Electrical Surges and Temperature Fluctuations</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/distribution-transformer">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg2} alt="Distribution Transformer" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Distribution Transformer</h3>
                                            <p>100 KVA To 2500 KVA,
                                                Voltage class upto 33 KV</p>
                                            <h5>Designed with Low Magnetizing Current by minimizing the load on electricity bills</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/cast-resin-dry-type-transformer">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg3} alt="Dry Type Transformer" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Dry Type Transformer</h3>
                                            <p>Up to 05 MVA, Voltage
                                                class upto 33 KV</p>
                                            <h5>Compact Design, Low in Weight, and Lesser Maintenance Cost</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/furnace-transformer">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg4} alt="Furnace Transformer" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Furnace Transformer</h3>
                                            <p>Up to 15 MVA, Voltage
                                                class upto 33 KV</p>
                                            <h5>High Current Capacity Transformer, Best for Steel & Metal Industries</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/solar-inverter-duty-transformer">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg5} alt="Solar Inverter Duty Transformer" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Solar Inverter Duty Transformer</h3>
                                            <p>Up to 15 MVA, Voltage
                                                Class upto 33 KV</p>
                                            <h5>Best for Solar & Wind Power Plants and Renewable Energy</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='ProductDataCov'>
                                <div className='ProductDataIner'>
                                    <a href="/compact-secondary-substations">
                                        <div className='ProductInerCovImg'>
                                            <img src={PowerTransformImg6} alt="Compact Secondary Substations" />
                                        </div>
                                        <div className='ProductInerCovText'>
                                            <h3>Compact Secondary Substations (CSS)</h3>
                                            <p>100 KVA To 2500 KVA,
                                                Voltage class upto 33 KV</p>
                                            <h5>Best for Commercial & Residential Buildings, Easy for Transportation</h5>
                                            <h6>Know More <FiArrowUpRight /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <div className='Traasfainboxset'>
                    <div className='TraasfainboxLeft'>
                        <h3>Transformers Installation and Commissioning</h3>
                        <p>As one of the most reliable <span>Transformer Suppliers in UAE & Africa</span>, we offer
                            comprehensive Installation & Commissioning services with our Transformers Sales from
                            erection, testing, and commissioning activities of all transformer ranges, up to 500 MVA and
                            400 KV Voltage Class. We train your operators on how to correctly use & maintain
                            transformers for peak performance results.
                        </p>
                    </div>
                    <div className='TraasfainboxRight'>
                        <img src={TICimg1} alt="Transformers Installation and Commissioning" />
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <div className='tfbolcMain'>
                    <h3>The following are the benefits of our Installation & Commissioning:</h3>
                    <p><BiCheck /> We have a team of highly qualified, trained, and professional engineers proficient in
                        carrying out the Installation of Transformers for numerous industries, including the  <span>Installation of Transformers in the Substation</span> & completing the task within the
                        deadline with adherence to the industry's highest standards</p>
                    <p><BiCheck /> We employ best practices and advanced technology & tools deployed in-house to test
                        and commission transformers to ensure they function at their peak performance to
                        deliver value for investment for our clients and thereby build long-term business
                        relations.
                    </p>
                    <p><BiCheck /> We do Pre-Installation Testing to check the area, ensuring no ignition or corrosion
                        sources are nearby. We turn off the primary power supply before mounting the
                        transformer. We install all required equipment, such as tap changers, control panels,
                        cooling systems, and bushings. And then connect them to the transformer.</p>
                    <p> <BiCheck />We do proper Oil Filling & Filtration Processes to achieve oil parameters and conduct
                        electrical testing of the transformer, including Low Voltage testing. We also verify
                        that all connections are appropriately tightened and secured. We finish all essential
                        documentation, including test reports.</p>
                </div>
            </div>

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is a Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>It is an electrical device that intakes an input voltage & changes it to another output voltage.
                                    It employs electromagnetic induction to transmit AC signals from one circuit to another. It
                                    increases & decreases AC voltages, depending on the requirement.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What is an Electric Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Electric Transformers are static electrical machines that transfer electric power from one
                                    electric circuit to another without changing frequency.</p>
                                <p>It can step up and down voltage with correspondence to increase & decrease in electric
                                    current, and the coils rounded up around its core. The Electric Transformer working principle
                                    is similar to the basic principles of electromagnetic induction.</p>
                                <p>If you are searching for <span>Electrical Transformer Manufacturers</span>, VoltageShift is your
                                    one-stop destination. We offer one of the most competitive pricing for custom electrical
                                    transformers solutions. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What are Industrial Transformers?</Accordion.Header>
                            <Accordion.Body>
                                <p>Industrial Transformers transfer power & energy between various parts of a factory or other
                                    industrial setup. They alter electricity voltage while keeping power unchanged. They can
                                    regulate temperature, produce power, and do fluid movements, making them a crucial product
                                    for several industrial applications.</p>
                                <p>If you are searching for <span>Industrial Transformer Manufacturers</span>, don’t look beyond
                                    VoltageShift. We offer premium quality industrial transformers solutions. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />

        </>
    )
}

export default Index