import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";

import { FaCloudDownloadAlt } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, OnSiteFaultTroubleshootingDetails, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function OnSiteFaultTroubleshooting() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'On site fault troubleshooting';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/on-site-fault-troubleshooting`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/on-site-fault-troubleshooting`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>On Site Fault Troubleshooting</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={OnSiteFaultTroubleshootingDetails} alt="On Site Fault Troubleshooting" />
                            <h1>On Site Fault Troubleshooting</h1>
                            <h6>Keep your Transformers functioning intact with our Expert On-Site Fault Troubleshooting Services</h6>
                            <p>Stay worry-free with VoltageShift <span>On-Site Transformer Fault Troubleshooting</span>, designed
                                to ensure your transformers function safely and efficiently and keep your operations and
                                working unaffected. We have a team of highly qualified and experienced <span>On-Site Transformer Fault Service Experts</span> proficient in identifying & resolving transformer issues to minimize their impact on your operations.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>As a leading <span>On-Site Transformer Fault Service Provider</span>, we offer On-Site Fault
                                    Troubleshooting at the International Level in 45+ countries. We strive to keep your facility
                                    safe & secured, be it industrial, commercial, residential, or infrastructure projects, with our
                                    <span>On-Site Transformer Fault Analysis Service</span>.</p>
                                <p>Partner with us to give your facility the most trustworthy and efficient Transformer Fault Troubleshooting.</p>
                            </div>
                        </div>

                        <div className='servdetailsListBox'>
                            <h2>Our On-Site Transformer Fault Troubleshooting Process</h2>
                            <p>At VoltageShift, we follow a set process for <span>Transformer Failure Analysis</span>, which helps us
                                rapidly identify and address the faults. The following are the steps involved in the procedure:</p>
                            <ul>
                                <li><BsCheck /> Acknowledging the report received from the client about the fault. And we dispatch
                                    an expert team to resolve the issue on-site.</li>
                                <li><BsCheck /> Conducting a Preliminary Inspection of the Transformer & its surroundings to check
                                    the extent of the fault and any potential safety hazards.</li>
                                <li><BsCheck /> Making a tailored plan for the diagnosing test based on the fault type and
                                    Transformer's technical specifications.</li>
                                <li><BsCheck /> Performing the planned diagnostic test using essential equipment, tools, and practices
                                    to ascertain the root cause of the fault.</li>
                                <li><BsCheck /> After identification of the root cause, determine appropriate corrective measures to
                                    resolve the issue.</li>
                                <li><BsCheck /> Implement corrective actions, including repairing faulty wiring, replacing damaged
                                    contents, fixing oil leaks, or upgrading the Transformer in some cases.</li>
                                <li><BsCheck /> Recommending a Preventive Maintenance Plan to minimize the risk of future faults
                                    and disruptions in the Transformer.</li>
                                <li><BsCheck /> Maintaining Detailed Documentation and Reporting of the On-Site Fault
                                    Troubleshooting Service provided.</li>
                                <li><BsCheck /> Follow up with the client to verify whether Transformer is functioning correctly or if
                                    they require additional technical support or transformer monitoring services.</li>
                            </ul>
                            <p>The above steps help us provide clients with premier quality On-Site Transformer Fault
                                Troubleshooting Services. We provide <span>Troubleshooting of Transformer in Power
                                    Substations</span> On-Site at the International level in 45+ countries for Compact Secondary
                                Substations Transformers and other ranges of Transformers.</p>
                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li className='active'><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>
            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. What are the Primary Causes of Faults in the Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>There are numerous causes of failure in Transformer. However, the primary and common ones are:</p>
                                <ul className='BultPointboxUl'>
                                    <li>Insulation Failure, which deteriorates due to Oxidation, Acidity, Moisture, and Heat</li>
                                    <li>Line Surges, such as Voltage Spikes, Line Faults, Switching Electrical Surges, and Power Distribution Abnormalities</li>
                                    <li>Prolonged Overloading, Delayed Maintenance, and Contamination in Transformer Oil</li>
                                </ul>
                                <p>Hence, it is recommended to keep a close eye on the Fault Identification of Power
                                    Transformers and opt for Preventive Maintenance to avoid unexpected faults and failures.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. How to Identify Faults in the Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>There are various ways for Transformer Fault Prediction. However, the most effective methods are:</p>
                                <ul className='BultPointboxUl'>
                                    <li>Spotting a sudden or extended rise in the fault gases concentrations produced from
                                        liquid (oil) or solid (paper)</li>
                                    <li>Insulation exposed to electrical energy or abnormal heat</li>
                                </ul>
                                <p>The above two ways indicate fault presence inside the Transformer. Harmful Gases
                                    accumulated over longer intervals are another way of locating faults in the Transformer</p>
                                <p>For more tips for <span>Power Transformer Fault Prediction</span>, feel free to <a href="/contact-us" target="_blank">contact us</a>.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. What is the Winding Failure in the Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Windings are made of copper, and thermal losses occur because of copper line resistance.
                                    And they make hotspots in the winding due to ignorance or lack of maintenance. It leads to
                                    wear & tear of the Transformer over the years and affects its overall integrity, leading to the
                                    breaking of the winding.</p>
                                <p>Hence, we recommend <a href="/contact-us" target="_blank">partnering with us</a> as your On-Site Transformer Fault Troubleshooting Service Provider or opting for our Preventive Maintenance to prevent Winding Failure and Breakage in the Transformer.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>4. How do Dielectric faults occur in the Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Dielectric faults occur in the Winding of the Transformer because of insulation breakdown &
                                    affecting turn-to-turn, which is present between the winding turns. Insulation breakdown
                                    happens due to the high current & voltage above the recommended one. It results in an
                                    electric spark of the winding turns, causing a short circuit and might damage transformer
                                    components.</p>
                                <p>Hence, it is suggested to get Preventive Maintenance for your Transformer or opt for On-Site
                                    Fault Troubleshooting to avoid major faults and damages.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>5. What are the Mechanical Faults in Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Mechanical Faults occur due to distortion, displacement, or loosening of the Transformer's
                                    Windings. It leads to degradation in its performance and impacts the turn-to-run ratio. The
                                    primary reasons for these faults are:</p>
                                <ul className='BultPointboxUl'>
                                    <li>Corrosion</li>
                                    <li>Vibration movement inside the transformer</li>
                                    <li>Lack of maintenance & repair</li>
                                </ul>
                                <p>It can also happen due to Transformer Tap Changing & Cooling Media faults, resulting in
                                    high temperatures inside the transformer & impacting insulation & casing. In the worst
                                    scenarios, it can spark a fire inside the transformer</p>
                                <p>Getting Preventive Maintenance Transformer and On-Site Fault Troubleshooting is ideal for
                                    avoiding extreme situations. <a href="/contact-us" target="_blank">Contact us</a> for a consultation and quotation for our On-Site Transformer Fault Troubleshooting Services.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default OnSiteFaultTroubleshooting