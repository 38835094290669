import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BsCheck, BsInfoCircleFill } from "react-icons/bs";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { BiCheck } from 'react-icons/bi';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function InstallationAndCommissioning() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Installation and commissioning';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/installation-and-commissioning`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/installation-and-commissioning`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Installation and Commissioning</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={InstallationAndCommissioningImg} alt="Installation and Commissioning" />
                            <h1>Installation and Commissioning</h1>
                            <h6>Highly Professional Erection, Installation, and Commissioning Services for various ranges of Transformers</h6>
                            <p>Get your Transformers Installed perfectly and professionally with VoltageShift's premier quality <span>Transformer Installation Services</span>. We have a team of highly trained & qualified experts specialized in the Erection, Installation, and Commissioning of Transformers for up to 500 MVA Power Rating and 400 KV Voltage Class</p>
                            <p>As a leading <span>Transformer Installation Company</span> in UAE, we comprehend our client's
                                on-site installation requirements and the installation location. And accordingly, we plan
                                efficient solutions. We employ cutting-edge technology and tools for <span>Transformer Testing
                                    and Commissioning</span>, which adhere to the best practices and the industry's quality standards.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>As a trustworthy Transformer Installation Service Provider, we provide on-site installation
                                    at the International Level to 45+ countries, including India, Singapore, Maldives, Iran, Israel,
                                    and Japan. We update our clients with real-time W-I-P updates to ensure the project is
                                    completed according to their requirements and within a dedicated timeframe.</p>
                            </div>
                        </div>
                        <div className='ServwevoDetailsExtr'>
                            <p>Choose us and get your Transformers Installed, Tested, and Commissioned proficiently to
                                make them operate at their peak performance in the long run</p>
                        </div>
                        <div className='servdetailsListBox'>
                            <h2>Our Installation & Commissioning Services</h2>
                            <p><span>Transformer Installation</span> is a critical process that must be carried out with meticulous
                                attention and competently. With VoltageShift, you can stay worry-free as our highly skilled
                                experts will take care of all aspects of the <span>Installation and Commissioning of Power
                                    Transformers</span>. We provide a vast range of <span>Transformer Installation & Commissioning
                                        services</span> that include the following:</p>
                            <ul>
                                <li><BsCheck /> Pre-Installation Testing includes the Erection process - planning & testing of sites by
                                    ensuring no ignition or corrosion sources are nearby.</li>
                                <li><BsCheck /> Installation of all required equipment, such as tap changers, cooling systems,
                                    bushings, and control panels, and connecting them to the Transformer.</li>
                                <li><BsCheck /> Dry Air & Vacuum Process to achieve Transformer's Dew Point</li>
                                <li><BsCheck /> Oil Filling & Filtration Process in adherence to the oil parameters</li>
                                <li><BsCheck /> Performing Electrical Testing of the Transformer, such as Low Voltage Testing,
                                    Tandelta, and SFRA</li>
                                <li><BsCheck /> Verifying all connections are properly tightened and grounded for secured usage</li>
                                <li><BsCheck /> Transformer Insulation Resistance Test to test its winding insulation integrity</li>
                                <li><BsCheck /> Completion of all essential documentation, including test reports and drawings</li>
                                <li><BsCheck /> Providing proper hands-on training to your operators for appropriately using and
                                    maintaining Transformer</li>
                            </ul>

                            <h6><BsInfoCircleFill /> Wear Protective Gear & Equipment while handling Transformers and maintain
                                adequate. Our team follows safety guidelines and wears protective equipment for the
                                Erection, Installation, and Commissioning of the Transformer.</h6>
                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li className='active'><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>

            <div className='tfbolcTsbox'>
                <div className='CustContainer'>
                    <div className='tfbolcTsIner'>
                        <h3>Why Choose Us for Transformer Installation and Commissioning?</h3>
                        <h6>At VoltageShift, we provide secure, reliable, and cost-efficient Transformer Installation and Commissioning Services tailored to your <span>Transformer Installation Requirements</span>. The following are the reasons why you should choose us for the <span>Erection and Commissioning of the Transformer</span>:</h6>
                        <p><BiCheck /> We take the whole responsibility for on-site monitoring & testing at the International Level in 45+ countries for the <span>Erection of the Transformer</span> to meticulously install Transformer and its accessories.</p>
                        <p><BiCheck /> We conduct the <span>Pre Commissioning Test of the Transformer</span> to ensure it functions
                            similarly to the Factory Acceptance Test. We make sure the transformer setup is
                            delivered securely and protected from dynamic stress & moisture during
                            transportation.</p>
                        <p><BiCheck /> Highly Professional & Proficient <span>Electrical Transformer Installation Services</span> by
                            taking care of all aspects from logistics to erection & testing to commissioning on-site
                            at the International Level in 45+ countries.</p>
                        <p><BiCheck /> We also provide the <span>Installation of Transformers in Substation</span> for Compact
                            Substation units by pre-assembling them to speed up the installation process and
                            reduce significant time & transportation costs, such as on-site testing and field wiring.</p>
                        <p><BiCheck /> We follow a set <span>Transformer Commissioning Procedure</span> to comprehend different
                            scale project installation requirements. And accordingly, plan the <span>Commissioning of
                                the Transformer</span> unit perfectly to ensure it gets into operation rapidly.</p>
                        <p><BiCheck /> We provide <span>Electrical Transformer Installation</span> for several industries, such as Steel,
                            Chemicals, Metal, Mills, Manufacturing, Ceramics, Food and Beverages, Hospitals
                            and Pharmaceuticals, IT, Commercial & Residential Buildings, and more.</p>
                        <p><BiCheck /> We offer <span>Transformer Commissioning Maintenance</span>, which includes timely
                            inspections, 24/7 emergency services & oil filling to ensure the transformer is
                            operating at its peak performance and giving value for investment to clients.</p>
                        <p><BiCheck /> We carefully examine <span>Power Transformer Installation</span> requirements by
                            comprehending location and on-site to design earthing plans and establish secured
                            grounding connections for Turnkey, Industrial, and Infrastructure Projects.</p>
                    </div>
                </div>
            </div>

            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. How to Install a Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Installing Transformer is challenging as it requires immense technical expertise and handling,
                                    as minor mistakes can cause extensive damage & jeopardize lives. We recommend the
                                    following steps to install a Transformer:</p>
                                <p>At VoltageShift, we provide comprehensive Transformer Erection, Installation, &
                                    Commissioning Services. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What is the Pre-Commissioning of the Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>Pre-Commissioning of the Transformer takes place after the erection and installation process
                                    to check whether the installed Transformer is operating smoothly and at its best. Its purpose
                                    is to ensure the Transformer is safe to use by identifying and fixing the issues arising during
                                    installation.</p>
                                <p>This process involves flushing & cleaning of systems, testing control & safety systems, leak
                                    testing, training of operators, and Commissioning of utilities. After Pre-Commissioning, the
                                    Commissioning process comes.</p>
                                <p>The primary difference between Pre- Commissioning and Commissioning is that in the
                                    former dry & trial runs with the test fluids may occur, which are introduced during
                                    Commissioning to process the Transformer</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default InstallationAndCommissioning