import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, AnnualMaintenanceContractDetails, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { Accordion } from 'react-bootstrap';
import { BsCheck } from 'react-icons/bs';
import Metatags from '../../components/meta/metatags';
import axios from 'axios';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function AnnualMaintenanceContract() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Annual maintenance contract';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/annual-maintenance-contract`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/annual-maintenance-contract`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Annual Maintenance Contract</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={AnnualMaintenanceContractDetails} alt="Annual Maintenance Contract" />
                            <h1>Annual Maintenance Contract</h1>
                            <h6>Keep your Transformers operating at their Peak Performance & extend their Lifespan with our AMC.</h6>
                            <p>Give your Transformers highly reliable and trusted <span>Transformers Annual Maintenance
                                Contract Services</span> in UAE by partnering with VoltageShift. We provide tailored Annual
                                Maintenance Contact (AMC) Services by comprehending your applications, site
                                requirements, and needs.</p>
                            <p>As a leading <span>Transformer Annual Maintenance Contract Service Provider</span>, we offer
                                On-Site Transformer AMC Services at the International Level in 45+ countries, including
                                India, Singapore, Kuwait, Japan, Malaysia, Israel, Jordan, Iran, and Iraq.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>We provide cost-effective <span>Transformer Maintenance Services Contracts</span>, including site
                                    visits for inspection & assessment, disassembly and repair, testing and commissioning, and
                                    post-repair activities.</p>
                                <p>We have deployed advanced technologies and tools to provide <span>Transformer AMC Services</span>
                                    which adhere to the industry's highest standards. Our highly qualified and experienced team
                                    skillfully carries out all AMC service tasks with a rapid turnaround time. Choose us to keep
                                    your Transformers performance at peak, enhance quality, and extend longevity.</p>
                            </div>
                        </div>

                        <div className='brektedetailsMaincov'>
                            <h3>Our Transformers Annual Maintenance Contract Services</h3>
                            <p>As a reputable Transformer AMC Service Provider, we offer a comprehensive range of
                                Transformer AMC Contract Services to ensure our clients get all the required services under
                                one consolidated roof. The following are our AMC Services.</p>

                            <h4>Regular Inspections</h4>
                            <p>We provide Regular Inspections as part of our <span>AMC of Transformer</span> to identify potential
                                issues & faults beforehand to ensure they don't deteriorate transformer components and lead
                                to significant damage. We perform visual inspections, oil sampling, and electrical testing to
                                locate any signs of wear & tear, corrosion, or damage to evaluate Transformer performance.
                                We ensure Transformer is functioning correctly & all components are working as intended.
                            </p>

                            <h4>Scheduled Maintenance</h4>
                            <p>We provide Scheduled Routine Maintenance Services as part of our <span>Transformers Service
                                Contract</span>, such as bushing, contact & gasket cleaning, oil filtration, and winding insulation
                                testing to ensure Transformer runs efficiently and safely. We also offer Preventive
                                Maintenance Services that include bushing & gasket replacements and other necessary
                                repairs on time to keep the Transformer operating at its peak performance and extend its
                                longevity.</p>

                            <h4>24/7 Emergency Service</h4>
                            <p>At VoltageShift, we understand that unexpected faults and failures can arise anytime in
                                Transformers. Hence, we offer 24/7 Emergency Services. Our highly qualified & competent
                                Technicians are available round the clock to address and resolve any issues rapidly before it
                                damages other components.</p>
                            <p>We strive to keep your Transformer functioning excellently by getting it back to running with
                                our 24/7 Emergency Services. We utilize our extensive experience & knowledge and the
                                latest tools & equipment to fix problems quickly.
                            </p>

                            <h4>Compliance with Standards</h4>
                            <p>As a renowned <span>Transformer AMC Service Company in UAE</span>, we ensure your
                                Transformers comply with Industry's all relevant safety & performance standards, including
                                NEMA and ANSI/IEEE standards. Through our Annual Maintenance Contracts, we conduct
                                regular testing and analysis to ensure your Transformer meets all required specifications and
                                adheres to Industry's highest standards for peak performance and longevity.</p>

                            <h4>Expert Engineers</h4>
                            <p>Our team of expert Engineers is highly trained and experienced in transformer maintenance and repair. We use state-of-the-art tools and equipment to provide you with the highest quality service and support.</p>

                        </div>

                        <div className='weundeboxMaincov'>
                            <p>We understand the importance of reliable and efficient transformers, which is why we offer comprehensive annual maintenance contracts to ensure that your transformers are always in excellent condition. Our contracts are tailored to your specific needs and requirements, and we work closely with you to provide you with the highest quality service and support.</p>
                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li className='active'><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>
            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. What is a Transformer Annual Maintenance Contract?</Accordion.Header>
                            <Accordion.Body>
                                <p>Transformer Annual Maintenance Contract (AMC) is a well-formulated plan for preventive
                                    maintenance and avoiding disruptions in Transformers Operations. It involves Periodic
                                    Maintenance, which can be quarterly or annually, depending on the AMC Service Contract
                                    you are opting for.
                                </p>
                                <p>It aims to detect the faults or failures beforehand and address them to resolve them rapidly
                                    before they deteriorate or damage other components.
                                </p>
                                <p>At VoltageShift, we provide comprehensive Transformers AMC Service Contracts on-site at
                                    the International Level in 45+ countries. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and free consultation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What are the Benefits of Transformer Annual Maintenance Contract Service?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>Distribution Transformer AMC Service</span> and other transformer range AMC are pivotal for
                                    all the Transformer users to prevent all potential unexpected failures and unnecessary
                                    breakdowns due to non-maintenance or ignorance of repairing. It can deteriorate your
                                    Transformer's quality and impact its performance.</p>
                                <ul>
                                    <li><BsCheck /> Prevention from Sudden Breakdowns with Immediate Assistance</li>
                                    <li><BsCheck /> Extended the Lifespan of Transformers by keeping its components healthy</li>
                                    <li><BsCheck /> Peak Performance of Transformers with timely servicing and regular inspections</li>
                                    <li><BsCheck /> Maximizing your ROI and best results from Transformers</li>
                                    <li><BsCheck /> 24/7 Emergency Services for rapid turnaround time and spare parts availability</li>
                                </ul>
                                <p>If you are looking for a Transformer AMC Service Provider, VoltageShift is your one-stop
                                    destination. We provide <span>Distribution Transformer Annual Maintenance Contract</span> and for
                                    other ranges of Transformers. <a href="/contact-us" target="_blank">Contact us</a> for a free consultation with our experts.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. What Components are Checked in Transformers Annual Maintenance Contract?</Accordion.Header>
                            <Accordion.Body>
                                <p>The following are the crucial components that are checked in Transformers Annual
                                    Maintenance Contract:</p>
                                <ul>
                                    <li><BsCheck /> Earthing Resistance and Grounding Connection is secured or not</li>
                                    <li><BsCheck /> Gasket Joints are tightened or loose</li>
                                    <li><BsCheck /> Oil Filtration and Sampling</li>
                                    <li><BsCheck /> Bushing, Gaskets, and Contacts Cleaning</li>
                                    <li><BsCheck /> Checking Breakers & Relays alarms</li>
                                    <li><BsCheck /> Circuits and Cable Boxes Wiring</li>
                                    <li><BsCheck /> Bearing Motor and Cooling Fan Lubrication</li>
                                    <li><BsCheck /> Winding Insulation Resistance</li>
                                    <li><BsCheck /> Transformers Turn Ratio</li>
                                    <li><BsCheck /> Locate any rusted or semi-damaged components for replacement</li>
                                </ul>
                                <p>At VoltageShift, we have a checklist for Transformers AMC Services to ensure every aspect
                                    is thoroughly checked and tested. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and free consultation for our
                                    AMC Services.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default AnnualMaintenanceContract