import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';

import TransformersSlider from './TransformersSlider';

import Button from 'react-bootstrap/Button';

import { BiCheck } from "react-icons/bi";

import { PowerTransformImg3, ServiceBrochure } from '../../assets';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function CastResinDryTypeTransformer() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Dry Type Transformer';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/dry-type-transformer`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />

            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/dry-type-transformer`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Dry Type Transformer</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>

                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Dry Type Transformer</h1>
                                <p>Vast Range of Superbly Designed & Manufactured Dry Type Transformers.</p>
                            </div>
                        </div>

                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg3} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> Up to 05MVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 33KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> AN/AF</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IEC 60076, IS 11171</h4>

                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>

                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Choose us as your <span>Dry Type Transformer Manufacturer</span>, and get a superbly designed &
                                professionally engineered range of Dry Type Transformers. It employs solid resin insulation
                                over liquid-filled insulation. As a leading <span>Dry Type Transformer Manufacturing
                                    Company</span>, we carefully select superior materials for constructing Dry Type Transformers.</p>
                            <p>As a reputable <span>Dry Type Transformer Supplier</span>, we utilize cutting-edge technology & tools
                                deployed in-house and best practices to manufacture highly durable & robust performance <span>Dry Type Transformers</span> which adhere to IS & IEC standards.</p>
                            <p>We optimally utilize our vast experience & knowledge to deliver cost-effective Dry Type
                                Transformer solutions, striving to attain the industry's highest standards. We provide
                                comprehensive services from Installation & commissioning, testing, repair & maintenance,
                                and <span>Dry Type Transformer parts</span> on-site at the International level.</p>
                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Dry Type Transformer Salient Features & Technical Specifications</h3>
                            <p>As a prominent <span>3 Phase Dry Type Transformer Manufacturer</span>, we run rigorous tests on
                                our Dry Type Transformers to make them last for decades. Reliability & Efficiency are two
                                aspects that make us stand out from competitors. The following are the technical
                                specifications & features:</p>
                            <h4><BiCheck /> Power Rating of upto 05 MVA</h4>
                            <h4><BiCheck /> Voltage Rating of upto 33KV</h4>
                            <h4><BiCheck /> <span>Winding Material:</span> Copper or Aluminum</h4>
                            <h4><BiCheck /> <span>Dry Type Transformer Cooling</span> is AN/AF</h4>
                            <h4><BiCheck /> <span>Type (Duty):</span> Indoor/Outdoor</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dyn11 or as per specific requirements</h4>
                            <h4><BiCheck /> <span>3 Phase Dry Type Transformer</span></h4>
                            <h4><BiCheck /> <span>Frequency Rating:</span> 50Hz or 60Hz</h4>
                            <h4><BiCheck /> <span>Tap Changer:</span> Off Circuit or On Load Tap Changer Insulating</h4>
                            <p>All our Dry Type Transformers adhere to IEC 60076 and IS 11171 Standards. As a
                                fast-growing <span>3 Phase Dry Type Transformer Supplier</span>, we export our transformers to 45+
                                countries, including <span>Dry Type Transformer Installation</span>, repair, and maintenance.</p>
                        </div>
                    </div>

                </div>

                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Dry Type Transformer Applications</h3>
                                <p>VoltageShift is a highly reliable <span>Dry Type Transformer Exporter</span>, exporting Dry Type
                                    Transformers to 45+ countries. They are versatile and used in several industries worldwide
                                    for their various applications & requirements, including turnkey projects.</p>
                                <p>The following are the industrial applications of our Three Phase Dry Type Transformer:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <h3>Salient Features</h3>
                                    <ul>
                                        <li><BiCheck /> Steel Industries</li>
                                        <li><BiCheck /> Cement Industries</li>
                                        <li><BiCheck /> Chemical Industries</li>
                                        <li><BiCheck /> Food and Beverages Industries</li>
                                        <li><BiCheck /> Ceramics Industries</li>
                                        <li><BiCheck /> Hospital and Pharmaceutical Industries</li>
                                        <li><BiCheck /> Manufacturing Industries</li>
                                        <li><BiCheck /> Commercial & Residential Buildings</li>
                                        <li><BiCheck /> IT Industries</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>All the mentioned industries significantly benefit from our transformer. We stay connected
                                with our clients post-installation to provide them with <span>Dry Type Transformer Repair</span>
                                Services for the smooth functioning of their transformers for ages.</p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Benefits of using our Dry Type Transformer</h3>
                        <h6>Partner with us as your <span>Dry Type Transformer Service Provider</span>, and give your industrial
                            facilities and substations high-quality <span>5 MVA Dry Type Transformers</span>. You will enjoy
                            phenomenal benefits in the long run and maximum ROI. The following are the salient ones:</h6>
                        <p><BiCheck /> Compact in Design and will fit ideally in different setups. Also, it is low-weight,
                            making it straightforward to handle & install.</p>
                        <p><BiCheck /> Remarkable Ability to withstand challenging weather fluctuations and electrical
                            surges, making it a wiser long-term investment option.</p>
                        <p><BiCheck /> Manufactured from a meticulous selection of eco-friendly materials, practices, and
                            processes to ensure it doesn't create adverse environmental effects.</p>
                        <p><BiCheck /> It has a lower maintenance cost because of excellent durability & production, which
                            won't create frequent malfunctions in transformers.</p>
                        <p><BiCheck /> 100% Authentic & Genuine <span>Dry Type Transformer Spare Parts</span> are readily
                            available & exported to 45+ countries keeping your transformers performance intact.</p>
                        <p><BiCheck /> We provide <span>Dry Transformer Maintenance</span> that includes Timely servicing of <span>Dry
                            Transformer Cooling Classes</span>, Regular Inspections, Oil changes, 24/7 Emergency
                            Service, and staying compliant with the industry's standards.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>

                <TransformersSlider />

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a 33KV Dry Type Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A 33KV Dry Type Transformer is a high-voltage substation that uses epoxy resin as an
                                        Insulating material. It is considered safe to use in residential areas & businesses by lowering
                                        the voltage levels to 11KV feeders and distributing voltage levels through it. Due to a very
                                        high voltage, it prevents transmission loss during power transfer over long distances.</p>
                                    <p>If you are searching for a 33KV <span>Dry Type Transformer Manufacturer</span>, VoltageShift is your
                                        go-to destination. We design & engineer premier quality 33KV Dry Type Transformers. <a href="/contact-us" target="_blank">Contact us</a> for a free consultation and quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is the Difference between Dry Type & Liquid Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Liquid Transformers are considered more efficient than Dry Type. The primary difference
                                        between both is how they cool down. A Dry Type of Transformer doesn't use toxic fluids for
                                        its system cooling and doesn't have to ventilate harmful gases. It is less likely to catch fire or
                                        explosion during overload because of the presence of flammable liquids in the unit.</p>
                                    <p>Liquid-filled Transformers contain flammable dielectric fluid, making them unsafe for indoor
                                        use because it can cause fire & explosions. They require high-level safety precautions.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Are Dry Type Transformers Eco-Friendly & Safe?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Dry Type Transformers don't employ oils or liquids, which mitigate the risk of oil leaks or
                                        any other environmental pollutants. They are primarily preferred because of their
                                        self-extinguishing properties, which means they rarely explode.</p>
                                    <p>They are renowned for withstanding unexpected temperature fluctuations, making them
                                        perfect for condensed & dynamic stress environments. Dry Transformers are crucial in
                                        contributing to sustainable approaches because of their highly durable and low electricity
                                        usage.</p>
                                    <p>Contribute to the environment by partnering with VoltageShift as your <span>5 MVA Dry Type
                                        Manufacturer & Supplier</span>. <a href="/contact-us" target="_blank">Contact us</a> for a free quotation and project consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default CastResinDryTypeTransformer