import React, { useEffect, useState } from 'react';
import { RiArrowRightSLine, RiPagesFill, RiTimeFill, RiUser3Fill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { HiArrowSmallLeft, HiArrowSmallRight } from "react-icons/hi2";
import './blog-style.css';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from '../../assets';
import Continent from '../../components/continent/Continent';
import ConsultationForm from '../../components/common/consultation';
import { Navigate, redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import BlogListCard from '../../components/common/cards/BlogListCard';
import Metatags from '../../components/meta/metatags';
import { Category } from '../../models/category';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

function BlogDetails(props: { setCategory: (category: Category) => void }) {
    const { slug } = useParams();
    const [seoDetails, setSeoDetails] = useState([]);
    const [pageSlug, setPageSlug] = useState('');
    const [image, setImage] = useState('');
    const [h1_title, setH1Title] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [description, setDescription] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState([]);
    const [previous, setPrevious] = useState('');
    const [current, setCurrent] = useState([]);
    const [next, setNext] = useState('');
    const [categoryId, setCategoryId] = useState<number | null>(null);
    const [seoRecentPost, setSeoRecentPost] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    const [ogImage, setOgImage] = useState('');

    useEffect(() => {
        fetchSeo();
        fetchCategory();
        fetchSeoList(1, 3, '', null, 'recent');
    }, []);

    const fetchSeo = async () => {
        const { data } = await axios.get(`getseodetails/${slug}`);
        // document.title = 'News - ' + data.current.slug;
        setPrevious((data.previous) ? data.previous.slug : '');
        setCurrent(data.current);
        setNext((data.next) ? data.next.slug : '');
        setPageSlug(data.current.slug);
        setSeoDetails(data.current.id);
        setImage(data.current.image);
        setH1Title(data.current.h1_title);
        const created_date = moment.utc(data.current.created_at || data.current.updated_at).startOf('day').format('MMMM DD, YYYY');
        setCreatedDate(created_date);
        setDescription(data.current.description);
        setCategoryName(data.current.category.name);
        /* SEO - Start */
        setMetaTitle(data.current.meta_title)
        setMetaKeyword(data.current.meta_keyword)
        setMetaDescription(data.current.meta_description)
        setFocusKeyword(data.current.focus_keyword)
        setOgTitle(data.current.og_title)
        setOgDescription(data.current.og_description)
        setOgImage(data.current.og_image)
        /* SEO - End */
    };

    const fetchSeoList = async (currentPage: number, itemsPerPage: number, searchText: string, categoryId: number | null, types: string) => {
        const { data } = await axios.get(`getseolisthome?page=${currentPage}&size=${itemsPerPage}&keyword=${searchText}&category_id=${categoryId}`);
        setSeoRecentPost(data.data)
    };

    const fetchCategory = async () => {
        const { data } = await axios.get(`categorylist`);
        setCategoryList(data);
    };

    const handleCategoryClick = (cateId: number) => {
        // document.getElementById('cate_anchor')?.removeAttribute('class');
        const parentDiv = document.getElementById('cate_anchor');
        const activeLiElements = parentDiv?.getElementsByClassName('active');
        while (activeLiElements?.length) {
            activeLiElements[0].classList.remove('active');
        }

        document.getElementById('cate_anchor')?.classList.remove('active');
        const elements = document.getElementsByClassName(`cat_li_${cateId}`);
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('active');
        }

        setSelectedCategoryId(cateId);
        props.setCategory(new Category(
            cateId,
        ));
        setRedirect(true);
    };

    if (redirect) {
        return <Navigate to={`/news`} />
    }
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                canonicalURL={FRONT_BASE_URL + `/news/` + slug}
            />
            <style>
                {`
                .prev_next_disabled {
                    pointer-events: none;
                    background: #a77789 !important;
                }
            `}
            </style>
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/news">News</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>News Detail</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='BlogDetailsMain'>
                    <div className='BlogDetailsLeft'>
                        {seoDetails &&
                            <>
                                <div className='BlogDetailsLefImgbox'>
                                    <img src={`${axios.defaults.baseURL}${image}`} alt={`${pageSlug}`} />
                                </div>
                                <div className='BlogDetailsLefTitle'>
                                    <h1>{h1_title}</h1>
                                    <div className="TimeandcateboxMaincovb">
                                        <h6><RiTimeFill /> {createdDate}</h6>
                                        <h6><RiPagesFill /> {categoryName}</h6>
                                    </div>
                                </div>

                                <div className='BlogDetailsDataall'>
                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                                <div className='BdetailspageSharbtn'>
                                    <div className='BdetailspageSharLeft'>
                                        <p>Share with</p>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.voltageshift.com/${pageSlug}`} id="shareonfacebook" target="_blank" data-href="https://www.voltageshift.com/${pageSlug}"><img src={FacebookIcon} alt="Facebook" /></a>
                                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.voltageshift.com/${pageSlug}&title=${h1_title}&summary=description&source=https://www.voltageshift.com`} target="_blank"><img src={LinkedinIcon} alt="Linkedin" /></a>
                                        <a href={`https://twitter.com/intent/tweet?text=${h1_title}&url=https://www.voltageshift.com/${pageSlug}&hashtags=`} data-size="large" target="_blank"><img src={TwitterIcon} alt="Twitter" /></a>
                                    </div>
                                    <div className='BdetailspageSharRight'>
                                        <a href={`${previous}`} className={previous ? '' : 'prev_next_disabled'}><HiArrowSmallLeft /> Previous</a>
                                        <a href={`${next}`} className={next ? '' : 'prev_next_disabled'}>Next <HiArrowSmallRight /></a>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='BlogDetailsRight'>
                        <div className='BlogDetRecentPostTitle'>
                            <h2>Categories</h2>
                        </div>
                        <div className='CategorieListBlog' id='cate_anchor'>
                            <ul>
                                <li className='active' key="0"><a onClick={() => handleCategoryClick(0)}>All</a></li>
                                {
                                    categoryList && categoryList.length > 0 ? (
                                        categoryList.map((cate: any) => (
                                            <>
                                                <li id={`cate_anchor${cate.id}`} className={(categoryId === cate.id) ? 'active' : ''} key={cate.id}>
                                                    <a onClick={() => handleCategoryClick(cate.id)}>{cate.name}</a>
                                                </li>
                                            </>
                                        ))
                                    ) : (
                                        <div>No Category</div>
                                    )
                                }
                            </ul>
                        </div>

                        <div className='BlogDetRecentPostTitle'>
                            <h2>Recent Post</h2>
                        </div>
                        <div className='BlogDetRecentPostList'>
                            {seoRecentPost && seoRecentPost.length > 0 ? (
                                seoRecentPost.map((data1: any) => (
                                    <>
                                        <BlogListCard
                                            key={data1.id}
                                            slug={data1.slug}
                                            image={data1?.image}
                                            h1_title={data1.h1_title}
                                            id={data1?.id}
                                            created_at={data1.updated_at || data1.created_at}
                                        />
                                    </>
                                ))
                            ) : (
                                <div>No News Found</div>
                            )}
                            {/* <div className='BlogDetRecePotListCov'>
                                <a href="/the-importance-of-choosing-the-right-service-provider-for-transformers">
                                    <div className='BlogDetRecePotListImg'>
                                        <img src={BlogBanner2} alt="The Importance of Choosing the Right Service Provider for Transformers" />
                                    </div>
                                    <div className='BlogDetRecePotListTxt'>
                                        <h3>The Importance of Choosing the Right Service Provider for Transformers</h3>
                                        <p><IoCalendar /> April 21, 2023</p>
                                    </div>
                                </a>
                            </div>
                            <hr />
                            <div className='BlogDetRecePotListCov'>
                                <a href="/the-backbone-of-industrial-power-unveiling-the-importance-of-transformers">
                                    <div className='BlogDetRecePotListImg'>
                                        <img src={BlogBanner3} alt="The Backbone of Industrial Power: Unveiling the Importance of Transformers" />
                                    </div>
                                    <div className='BlogDetRecePotListTxt'>
                                        <h3>The Backbone of Industrial Power: Unveiling the Importance of Transformers</h3>
                                        <p><IoCalendar /> May 19, 2023</p>
                                    </div>
                                </a>
                            </div> */}
                        </div>


                    </div>
                </div>
            </div >

            <ConsultationForm />

            <Continent />
        </>
    )
}

export default BlogDetails