import { Country } from "./country";
import { Role } from "./role";

export class User {
    constructor(
        public id = 0,
        public full_name = '',
        public email = '',
        public mobile = '',
        public created_at = '',
        public role = new Role(),
        public countryEntity = new Country()
    ) {
    }

    get name() {
        return `${this.full_name}`;
    }
}