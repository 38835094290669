import React, { useEffect, useState } from 'react';
import './services-slider-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, RepairingServiceDetails, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { Accordion } from 'react-bootstrap';
import { BsCheck } from 'react-icons/bs';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function RepairingService() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Repairing service';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/repairing-service`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/repairing-service`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Repairing Service</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={RepairingServiceDetails} alt="Repairing Service" />
                            <h1>Repairing Service</h1>
                            <h6>Most Professional & Reliable Transformers Repairing Service for upto 100 MVA Power Rating</h6>
                            <p>Get your Transformers up and running like a new one with VoltageShift <span>Transformer
                                Repairing Service</span> for up to 100 MVA Power Rating. We have a team of highly qualified &
                                skilled experts proficient in carrying out <span>On-Site Transformer Repairing Service</span> at the
                                International Level in 45+ countries, including UAE, India, Singapore, Kuwait, Malaysia,
                                Iraq, Iran, and Japan.</p>
                            <p>As one of the leading <span>Transformer Repair Companies</span> in UAE, we strive to serve our
                                clients with the first Transformer Repair Service, which includes site inspection &
                                assessment, diagnosis and formulation of repair plans, disassembly & reassembly, oil
                                analysis, and post-repair activities.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>We have deployed cutting-edge technology & tools in-house and blend them with our
                                    extensive experience & knowledge to deliver the most professional and reliable Transformers
                                    Repairing Service to ensure your Transformers operate at their peak performance.</p>
                                <p>Choose us as your <span>Transformers Repairing Service Provider</span> and get the best out of your
                                    Transformers in terms of performance, quality, and durability.</p>
                            </div>
                        </div>

                        <div className='brektedetailsMaincov'>
                            <h3>Our Transformer Repairing Service</h3>
                            <p>At VoltageShift, we provide comprehensive Transformer Repairing Solutions for several
                                industries to meet their requirements and needs. And keep their Transformers functioning
                                excellently. The following are our <span>Repair Services for Transformers</span>.</p>

                            <h4>Inspection and Assessment</h4>
                            <p>We begin our <span>Transformer Repair and Services</span> by thoroughly inspecting the Transformer
                                and assessing the extent of the damage and the root cause. It includes rigorously checking the
                                Transformer's internal components, such as bushings, tap charger, winding, insulation,
                                gaskets, and other parts. We make a detailed report based on our Inspection and Assessment
                                to decide the course of action</p>

                            <h4>Diagnosis and Repair Plan</h4>
                            <p>After Transformer Inspection & Assessment, we move towards <span>Diagnostic Testing on
                                Transformers</span> to diagnose the problem. And accordingly, formulate a repair plan outlining
                                the steps required to resolve the issue. It includes the identification of faulty parts that needs
                                repairing or replacement. Also, it contains the equipment and tools required for the repair.</p>

                            <h4>Transformer Oil Analysis</h4>
                            <p>After <span>Transformers Diagnosis</span>, we move towards Transformer Oil Analysis, a crucial
                                component of the Transformer. Transformer Oil is the heart of the Transformer. It is pivotal to
                                conduct regular Transformer Oil Analysis to detect any contaminants, such as dissolved gases
                                and moisture presence. It will degrade Transformer's Performance, Quality, and Lifespan.</p>
                            <p>We perform <span>Transformer Oil Analysis</span> by thoroughly testing oil after the Oil Filtration
                                process using the latest technologies and filters, such as degasification, vacuum dehydration,
                                particulate filtration, and dissolved gas analysis (DGA).</p>
                            <p>We ensure it complies with the Industry's highest quality standards, enhances Transformers'
                                overall performance, and extends its longevity. Based on the results, we recommend the
                                course of treatment or replacement of the faulty components.</p>

                            <h4>Disassembly and Repair</h4>
                            <p>After the Transformer Oil Analysis and recommendation of a course of action, we proceed to
                                disassemble the Transformer to carry out the necessary repairs. Our <span>Transformer Repairing
                                    Service Experts</span> professionally and delicately carry out the disassembly and repairing
                                activities, including repairing and replacing damaged components, such as winding,
                                insulation, tap changer, or any other part. They also ensure that all parts are properly cleaned,
                                dried, and free from dirt, debris, moisture, and other contaminants.</p>

                            <h4>Reassembly and Testing</h4>
                            <p>After completing repairs and replacing faulty components, we reassemble the Transformer
                                and perform a series of tests to ensure it operates smoothly and at its peak performance. It
                                includes conducting a Winding Resistance Test, Insulation Resistance Test, Oil Testing,
                                Turn-to-Turn Ratio Testing, and other required tests to check the Transformer's performance.
                                After successful testing, we perform the final testing and commission the Transformer to put
                                it back into service.</p>


                            <h4>Maintenance and Support</h4>
                            <p>After Final Testing and Commissioning, we recommend our Preventive Maintenance Plan. It
                                includes ongoing <span>Transformer Repair and Maintenance Services</span>, such as timely servicing,
                                regular inspection, oil filtration and sampling, testing, and 24/7 Emergency Support Services
                                to ensure your Transformer operates efficiently and safely without disruptions and
                                unnecessary breakdown, keeping your operations intact.</p>

                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li className='active'><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>
            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. How to Detect Transformer Oil Color for Accurate Analysis?</Accordion.Header>
                            <Accordion.Body>
                                <p>If the Transformer Oil color is darkened, it needs filtration or replacement. With the oil aging,
                                    it gradually changes from a transparent pale yellow color to a deep brown and opaque shade
                                    color. If the oil color is orange, yellow, or red, it is fresh and works as intended</p>
                                <p>Hence it is crucial to get your Transformer Oil Analysis, Sampling, and Filtration done every
                                    6-12 months to ensure the Transformer operates at its peak performance.</p>
                                <p>At VoltageShift, we provide Transformer Oil Analysis and Filtration as part of our Repairing
                                    Services. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and consultation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What is Included in Electrical Field Tests for Transformer Diagnosis?</Accordion.Header>
                            <Accordion.Body>
                                <p>Several components and tests are performed for <span>Power Transformer Diagnosis</span>. The
                                    following are the effective electrical fields tests for Transformer Diagnosis:</p>
                                <ul>
                                    <li><BsCheck /> Winding Resistance Test</li>
                                    <li><BsCheck /> Transformer Turns Ratio Test</li>
                                    <li><BsCheck /> Dielectric Frequency Response (DFR)</li>
                                    <li><BsCheck /> DC Insulation Resistance Test</li>
                                    <li><BsCheck /> Leakage Reactance Test</li>
                                    <li><BsCheck /> Sweep Frequency Response Analysis (SFRA)</li>
                                    <li><BsCheck /> Dielectric Breakdown on Oil Sampling</li>
                                    <li><BsCheck /> Stray Losses Frequency Response</li>
                                    <li><BsCheck /> Dissipation Factor Tip-up Test</li>
                                    <li><BsCheck /> Partial Discharge Test</li>
                                </ul>
                                <p>If you are looking for <span>Transformer Diagnosis & Repair Servicing</span>, VoltageShift is your
                                    one-stop destination. We are competent in Fault Detection and Diagnosis in Power
                                    Transformers and other ranges. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and advice from our experts.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default RepairingService