import React, { useEffect, useState } from 'react';
import './about-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FiArrowUpRight } from "react-icons/fi";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { WhoWeAre, OurVision, OurMission, OurValue, FAQ, RTDimg1, RTDimg2, RTDimg3, MAMSE, COEC, COI, RTDimg4, CompanyProfile, LineIndicator1, WorkFlowIcon1, WorkFlowIcon2, WorkFlowIcon3, WorkFlowIcon4, WorkFlowIcon5, WorkFlowIcon6, WorkFlowIcon7 } from '../../assets';


import Button from 'react-bootstrap/Button';
import { FaCloudDownloadAlt } from "react-icons/fa";

import Accordion from 'react-bootstrap/Accordion';

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import Metatags from '../../components/meta/metatags';
import axios from 'axios';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

function AboutUs() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'About Us';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/about-us`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/about-us`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='AboutusTitlecovlst'>
                    <h1>About Us</h1>
                </div>
            </div>
            <div className='AboutusMainCovBg'>
                <div className='CustContainer'>
                    <div className='AboutusInertopbox'>
                        <div className='AboutusInertopLeft'>
                            <img src={WhoWeAre} alt="Who We Are" />
                        </div>
                        <div className='AboutusInertopRight'>
                            <h2>Who We Are</h2>
                            <h6>We specialize in providing customized solutions to meet the specific needs of our clients in power transmission, distribution, and other industries.</h6>
                            <p>VoltageShift is a leading transformer manufacturer, a globally recognized and renowned group company. We offer a complete range of dependable transformer solutions that meet the diverse needs of our clients, adhering to the highest quality standards set by IEC and IS. With 20+ years of experience, a skilled team, and exports to 45+ countries, we provide cost-effective solutions that meet industry standards. These include design and engineering, manufacturing, installation, commissioning, repair, and maintenance services, utilizing top-notch transformers and cutting-edge technology. We serve clients both in India and across the globe.</p>
                            <div className='AboutusInerdbright'>
                                <h5>Find the best transformer Solution  with our free Consultation</h5>
                                <Button href='/contact-us' className='btn'>Contact Us <FiArrowUpRight /></Button>
                                <a href={CompanyProfile} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>
                        </div>
                    </div>
                    <div className='AboutusInerBot'>
                        <h6>With a wealth of experience and a deep understanding of the industry, we have built a vast network of reliable manufacturers and suppliers, enabling us to offer a wide range of transformers and transformer spares at competitive prices. Our products are manufactured using the latest technology and are designed to meet international quality standards, ensuring that they are durable, reliable, and efficient.</h6>

                        <h6>In addition to providing high-quality power transformers and spares, we also offer a comprehensive range of transformer services, including installation, maintenance, and repair. Our team of experienced professionals is equipped with the skills and knowledge needed to provide top-notch services that keep your transformers running smoothly and efficiently.</h6>

                        <h6>In conclusion, we are a trusted merchant exporter of transformers, transformer spares, and transformer services, committed to providing our customers with high-quality products and services. Our commitment to quality, customer satisfaction, and sustainability has helped us build a reputation as a reliable partner in the industry. Contact us today to learn more about how we can help you with your power transformer and transformer parts requirements.</h6>
                    </div>
                </div>
            </div>

            {/* <div className='AboutUsSectboxmain'>
                <div className='CustContainer'>
                    <div className='aboutHomePageCov'>
                        <div className='aboutHomePageRighttxt'>
                            <h2>We’re VoltageShift.</h2>
                            <h6>we are a trusted merchant exporter of transformers, transformer spares, and transformer services</h6>
                            <p>We are the premier merchant exporter of transformers, Transformer spares, and transformer service. Committed to Providing our customers with high-quality products and services. Our commitment to quality, customer satisfaction & Sustainability has helped us build a reputation as a reliable Partner in the Industry. we have built a vast network of reliable manufacturers And suppliers, enabling us to offer a wide range of transformers And transformer parts at competitive prices. Our products are Manufactured using the latest technology and are designed to Meet international quality standards, ensuring that they are Durable, reliable, and efficient.</p>
                            <a href="">More about company <FiArrowUpRight /></a>
                        </div>
                        <div className='aboutHomePageLeftimg'>
                            <img src={AboutImg1} alt="We’re VoltageShift." />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='AboutOvOmOv'>
                <div className='CustContainer'>
                    <Row>
                        <Col md="6">
                            <div className='AboutOvOmOvinerbox OvOmboxhight'>
                                <img src={OurVision} alt="Our Vision" />
                                <h3>Our Vision</h3>
                                <p>Our vision is to become a leading global supplier of high-quality power transformers, transformer spares, and transformer services. We aspire to be the preferred choice of customers worldwide by offering innovative, sustainable, and customized solutions that meet their diverse needs.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='AboutOvOmOvinerbox OvOmboxhight'>
                                <img src={OurMission} alt="Our Mission" />
                                <h3>Our Mission</h3>
                                <p>Our mission is to provide our customers with the best-in-class products and services by leveraging our deep industry knowledge, extensive network of reliable suppliers, and experienced professionals. We are committed to conducting our business with honesty, integrity, and transparency, while promoting sustainable development and social responsibility.</p>
                            </div>
                        </Col>
                    </Row>
                    <Col md="12">
                        <div className='AboutOvOmOvinerbox'>
                            <img src={OurValue} alt="Our Value" />
                            <h3>Our Value</h3>
                            <p>Our core values guide us in everything we do. We value quality, innovation, customer satisfaction, social responsibility, and sustainability. We believe in providing high-quality products and services that exceed our customers’ expectations. We are committed to continuous innovation and investment in new technology and equipment to meet the evolving needs of our customers. We prioritize our customers’ needs and work tirelessly to ensure their satisfaction. We are dedicated to promoting social responsibility and sustainability by supporting various social initiatives and deploying clean energy solutions</p>
                        </div>
                    </Col>
                </div>
            </div>

            <div className='CustContainer'>
                <div className='FreqAskQuestboxCov'>
                    <div className='FreqAskQuestboxLeft'>
                        <img src={FAQ} alt="faq" />
                    </div>
                    <div className='FreqAskQuestboxRight'>

                        <h3>Frequently Asked Question</h3>

                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What types of transformers does VoltageShift manufacture?</Accordion.Header>
                                <Accordion.Body>
                                    <p>VoltageShift manufactures a wide range of transformers, including power transformers, distribution transformers, Dry-type transformers, Furnace Transformers, Solar inverter duty transformers and Compact Secondary Substations (CSS) and Industrial transformers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Does VoltageShift have a global presence and export its transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift has a global presence and exports its transformers to 45+ countries. With a network of international clients and distributors, VoltageShift serves customers worldwide, delivering our high-quality transformers to meet the global demand.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Does VoltageShift provide technical support for transformer-related queries?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift provides technical support for transformer-related queries. Our team of experts is available to assist customers with any technical questions or concerns they may have. We are knowledgeable in transformer design, functionality, and application, and can provide valuable guidance and solutions to ensure customer satisfaction.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Does VoltageShift offer customization options for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift offers customization options for transformers. We can tailor the specifications and features of the transformer to meet the specific requirements of the customer.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can VoltageShift provide installation and commissioning services for transformers?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, VoltageShift provides installation and commissioning services for transformers to ensure proper functioning and optimal performance. We also offers a comprehensive range of transformer services that include testing, repair, maintenance, oil filtration, transformer rewinding, relocation, emergency repairs, and annual maintenance contract (AMC).</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <div className='FaqRemoBoxbtn'>
                            <a href="/faq">Know More <FiArrowUpRight /></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='WorkflowBoxCovdata'>
                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h2>Work Flow</h2>
                        <p>Our Step-by-Step Approach to Delivering Success</p>
                    </div>
                </div>
                <div className='WorkflowBoxIner'>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon1} alt="Line" />
                        </div>
                        <p>No-Cost Consultation<br />
                            And<br />
                            Product Selection</p>

                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon2} alt="Line" />
                        </div>
                        <p>Quotation<br />
                            and<br />
                            Order Confirmation</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon3} alt="Line" />
                        </div>
                        <p>Manufacturing<br />
                            and<br />
                            Testing</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon4} alt="Line" />
                        </div>
                        <p>Quality Control<br />
                            and<br />
                            Customer’s Inspection</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon5} alt="Line" />
                        </div>
                        <p>Packaging<br />
                            and<br />
                            Delivery</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon6} alt="Line" />
                        </div>
                        <p>Installation<br />
                            and<br />
                            Commissioning</p>
                        <img src={LineIndicator1} alt="Connect with client" className='RightLineIndict1' />
                    </div>
                    <div className='WorkflowBoxdainCov'>
                        <div className='WorkflowBoxdainIconCov'>
                            <img src={WorkFlowIcon7} alt="Line" />
                        </div>
                        <p>Ongoing<br />
                            Support</p>
                    </div>
                </div>

            </div>

            {/* <div className='CustContainer'>
                <div className='AboutCertifAccordTitle'>
                    <h2>Certificate & Accreditations</h2>
                    <p>Our Commitment to Quality and Customer Trust</p>
                </div>
                <div className='CertifAccredBoxIner'>

                    <div className='CertifAccredBoximg'>
                        <a href={COI} target='_blank'><img src={COI} alt="COI" /></a>
                    </div>
                    <div className='CertifAccredBoximg'>
                        <a href={COEC} target='_blank'><img src={COEC} alt="COEC" /></a>
                    </div>
                    <div className='CertifAccredBoximg'>
                        <a href={MAMSE} target='_blank'><img src={MAMSE} alt="MAMSE" /></a>
                    </div>

                </div>
            </div> */}

            <div className='ReltimdelvMain'>
                <div className='CustContainer'>
                    <div className='AboutCertifAccordTitle'>
                        <h2>Reliable & Timely Deliveries</h2>
                        <p>Our Commitment to Customer Satisfaction</p>
                    </div>
                    <div className='ReltimdelvCov'>
                        <Row>

                            <Col md={6}>
                                <div className='ReltimdelvIner pb-30'>
                                    <img src={RTDimg1} alt="Reliable & Timely Deliveries" />
                                </div>
                                <div className='ReltimdelvIner pb-30'>
                                    <img src={RTDimg4} alt="Reliable & Timely Deliveries" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='ReltimdelvIner pb-30'>
                                    <img src={RTDimg2} alt="Reliable & Timely Deliveries" />
                                </div>
                                <div className='ReltimdelvIner pb-30'>
                                    <img src={RTDimg3} alt="Reliable & Timely Deliveries" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <ConsultationForm />

            <BlogList />

            <Continent />

        </>
    )
}

export default AboutUs