import React, { useEffect, useState } from 'react';
import './services-details-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";

import { InstallationAndCommissioningImg, OverhaulingServicesDetails, ServiceBrochure } from '../../assets';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';
import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';
import OtherServicesSlider from './OtherServicesSlider';
import ServiceCont from './ServiceCont';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';


function OverhaulingServices() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Overhauling services';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/overhauling-services`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };
    return (
        <>
            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/overhauling-services`}
            />
            <div className='CustContainer'>
                <div className='BreadcrumbItem'>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li><a href="/transformer-services">Transformer Service</a></li>
                        <li><RiArrowRightSLine /></li>
                        <li>Overhauling Services</li>
                    </ul>
                </div>
            </div>
            <div className='CustContainer'>
                <div className='ServiDetailsPageMain'>
                    <div className='ServiDetailsPageLeft'>
                        <div className='ServiDetailsPageTopbox'>
                            <img src={OverhaulingServicesDetails} alt="Overhauling Services" />
                            <h1>Overhauling Services</h1>
                            <h6>Get On-Site Transformers Overhauling Services for upto 100 MVA Power Rating</h6>
                            <p>Keep your Transformers in the best working condition by partnering with VoltageShift for <span>Transformer Overhauling Services</span>. We offer them for Transformers up to 100 MVA Power
                                Rating.</p>
                            <p>We provide <span>On-Site Overhauling Services for Transformers</span> at the International Level in
                                45+ countries, including India, Singapore, Malaysia, Kuwait, Iraq, Israel, and Japan. They are
                                designed to address potential issues & failures, including wear & tear, of Transformers by
                                extending their lifespan and enhancing their performance.</p>
                            <hr />
                        </div>
                        <div className='ServwevoffDetails'>
                            <div className='ServwevoDetailsImg'>
                                <img src={InstallationAndCommissioningImg} alt="What we Offer" />
                            </div>
                            <div className='ServwevoDetailsTxt'>
                                <h3>What we Offer</h3>
                                <p>As a leading <span>Transformer Overhauling Service Provider</span>, we provide cost-effective <span>Transformer Inspection</span>, Assessment, Disassembly, Repair, Testing, and Reassembly Services to ensure our clients get all the required solutions under one consolidated roof.</p>
                                <p>Boost your Transformer efficiency and reliability by choosing our <span>Overhauling Services for Transformers</span>.</p>
                            </div>
                        </div>

                        <div className='brektedetailsMaincov'>
                            <h3>Our Transformer Overhauling Service</h3>
                            <p>As a fast-growing Transformer Overhauling Service Provider, we provide comprehensive <span>On-Site Transformer Overhauling Services</span>, which are as follows:</p>

                            <h4>Inspection and Assessment</h4>
                            <p>We commence our Transformer Overhauling service with a rigorous inspection & assessment
                                of the transformer's condition, which includes comprehension of the model and year of
                                manufacturing and purchase.</p>
                            <p>Our highly qualified & skilled engineers examine the transformer as a component of our <span>Transformer Assessment Services</span> to determine any possible signs of wear & tear,
                                corrosion, or damage to evaluate its performance.</p>
                            <p>We perform a range of tests as part of the <span>Transformer Inspection Procedure</span>. After
                                Transformer Assessment, we will provide a detailed report of our findings and valuable
                                recommendations for necessary repairs and maintenance.</p>

                            <h4>Cleaning and Replacement</h4>
                            <p>It is a critical aspect of <span>Transformer Overhauling</span>. It is highly recommended to pay utmost
                                attention to <span>Transformer Cleaning</span> and parts replacement. At VoltageShift, we have a team
                                of highly qualified & competent technicians proficient in providing <span>Transformer Cleaning
                                    Services</span> on-site at the International Level in 45+ countries.</p>
                            <p>We delicately and professionally clean the Transformer's core, windings, and cooling system
                                to remove accumulated dirt, debris, or natural contaminants to enhance its performance and
                                quality. We also skillfully replace worn-out or damaged components, such as gaskets,
                                insulations, or bushings, to ensure <span>Overhaul Transformers</span> safety and smooth functioning.</p>

                            <h4>Testing and Commissioning</h4>
                            <p>After <span>Transformer Assessment</span>, Cleaning, and Replacement Process, we perform a series of
                                tests to evaluate the Transformer's performance to ensure it complies with the industry's
                                required standards.</p>
                            <p>We follow a set of testing procedures, including Insulation Resistance Testing, Winding
                                Resistance Testing, Oil Testing, and Turn-to-Turn Ratio Testing. After completion of the
                                testing, we commission the Transformer. We provide detailed documentation (report)
                                containing test findings and recommendations for enhancing Transformer's performance.</p>

                            <h4>Preventive Maintenance</h4>
                            <p>We provide <span>Transformer Overhaul Maintenance Service</span> to prevent unnecessary
                                breakdowns and extend the longevity of your Transformer. Our Preventive Maintenance
                                Services include timely servicing, regular inspections, cleaning, oil sampling & filtration,
                                24/7 emergency services, and testing to identify and address potential faults and issues
                                upfront to ensure they don't convert into significant problems and failures in Transformer</p>
                            <p>We also provide Preventive Maintenance for Transformer Insulating Materials as part of our
                                Overhauling Services to ensure Transformer's winding insulation integrity stays robust.</p>

                        </div>

                    </div>
                    <div className='ServiDetailsPageRight'>
                        <ul>
                            <li><a href="/installation-and-commissioning">- Installation and Commissioning</a></li>
                            <li><a href="/transformer-testing-service">- Transformer Testing Service</a></li>
                            <li><a href="/oil-filtration">- Oil Filtration</a></li>
                            <li><a href="/on-site-fault-troubleshooting">- On Site Fault Troubleshooting</a></li>
                            <li className='active'><a href="/overhauling-services">- Overhauling Service</a></li>
                            <li><a href="/repairing-service">- Repairing Service</a></li>
                            <li><a href="/annual-maintenance-contract">- Annual Maintenance Contract (AMC)</a></li>
                            <li><a href="/turnkey-projects">- Turnkey projects</a></li>
                        </ul>
                        <a href={ServiceBrochure} className='btn-primary' download><FaCloudDownloadAlt /> Download Brochure</a>
                        <ServiceCont />
                    </div>
                </div>
            </div>
            <div className='CustContainer'>
                <ContactUsInfo />
            </div>

            <OtherServicesSlider />

            <ConsultationForm />

            <div className='CustContainer'>
                <div className='HomepgTitleCov'>
                    <h3>Frequently Asked Question</h3>
                </div>
                <div className='faqPageBotset'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. Is Overhauling Part of the Maintenance Cost?</Accordion.Header>
                            <Accordion.Body>
                                <p><span>Overhauling Cost</span> is associated with replacing significant transformer components and
                                    scheduled upgrades. In contrast, maintenance costs relate to scheduled servicing of minor and
                                    major parts and unscheduled repairs, excluding overhauling activities.</p>
                                <p>Overhauling keeps the transformer in a serviceable condition through general maintenance
                                    and regular checks to avoid critical damage.</p>
                                <p>To summarize, you can combine both overhauling and maintenance costs by opting for a
                                    single service provider, like VoltageShift, offering both services. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. What is a Winding Resistance Test in a Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>A <span>Winding Resistance Test</span> is conducted to measure the electrical resistance of Transformer
                                    Windings. If the resistance is on the higher side, wire heating will be significant, leading to
                                    windings.</p>
                                <p>It can lead to the burning of the coil and softening or melting of the wires. This dangerous
                                    situation must be avoided through Regular Inspection and Timely Servicing of Transformers.</p>
                                <p>Partner with VoltageShift for Transformer Overhauling Services in which we provide
                                    comprehensive <span>Transformer Inspection Services</span>, Testing, and Commissioning to prevent
                                    Winding and burning of the coil or damage of any components. We strive to keep your
                                    Transformer operating smoothly without disruptions. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>3. What is Turns Ratio Test in Transformer?</Accordion.Header>
                            <Accordion.Body>
                                <p>A Turns Ratio Test is performed between primary & secondary windings to check whether all
                                    windings are correctly wired and operating perfectly. To test all the windings, a load tap
                                    changer is cycled through all possible positions to determine the Transformer Turns ratio.</p>
                                <p>There are particular protective gears and equipment designed to run this test, which you can
                                    obtain from your supplier.</p>
                                <p>At VoltageShift, we perform the Transformer <span>Turns Ratio Test</span> as part of our Transformer
                                    Testing & Commissioning to ensure all windings function appropriately. <a href="/contact-us" target="_blank">Contact us</a> for a Quotation.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <BlogList />

            <Continent />
        </>
    )
}

export default OverhaulingServices