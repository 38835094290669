import React, { useState } from 'react'
import './getintouch-style.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FiArrowUpRight } from "react-icons/fi";
import { IoLocationSharp } from "react-icons/io5";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { successToast, errorToast } from '../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import superagent from 'superagent';

import { API_BASE_URL, ERR_EMAIL_REQUIRED, ERR_FAILED_EMAIL, ERR_FULL_NAME_REQUIRED, ERR_INVALID_EMAIL, ERR_INVALID_PHONE, ERR_LAST_NAME_REQUIRED, ERR_PHONE_REQUIRED, ERR_REQ_SUBJECT, FRM_NO_COST } from '../../constants/commonConstants';

function GetinTouch() {
    const animatedComponents = makeAnimated();
    const countrycode = [
        { "value": 1, "label": "AF +93" },
        { "value": 2, "label": "AL +355" },
        { "value": 3, "label": "DZ +213" },
        { "value": 4, "label": "AS +1-684" },
        { "value": 5, "label": "AD +376" },
        { "value": 6, "label": "AO +244" },
        { "value": 7, "label": "AI +1-264" },
        { "value": 8, "label": "AQ +672" },
        { "value": 9, "label": "AG +1-268" },
        { "value": 10, "label": "AR +54" },
        { "value": 11, "label": "AM +374" },
        { "value": 12, "label": "AW +297" },
        { "value": 13, "label": "AU +61" },
        { "value": 14, "label": "AT +43" },
        { "value": 15, "label": "AZ +994" },
        { "value": 16, "label": "BS +1-242" },
        { "value": 17, "label": "BH +973" },
        { "value": 18, "label": "BD +880" },
        { "value": 19, "label": "BB +1-246" },
        { "value": 20, "label": "BY +375" },
        { "value": 21, "label": "BE +32" },
        { "value": 22, "label": "BZ +501" },
        { "value": 23, "label": "BJ +229" },
        { "value": 24, "label": "BM +1-441" },
        { "value": 25, "label": "BT +975" },
        { "value": 26, "label": "BO +591" },
        { "value": 27, "label": "BA +387" },
        { "value": 28, "label": "BW +267" },
        { "value": 29, "label": "BR +55" },
        { "value": 30, "label": "IO +246" },
        { "value": 31, "label": "VG +1-284" },
        { "value": 32, "label": "BN +673" },
        { "value": 33, "label": "BG +359" },
        { "value": 34, "label": "BF +226" },
        { "value": 35, "label": "BI +257" },
        { "value": 36, "label": "KH +855" },
        { "value": 37, "label": "CM +237" },
        { "value": 38, "label": "CA +1" },
        { "value": 39, "label": "CV +238" },
        { "value": 40, "label": "KY +1-345" },
        { "value": 41, "label": "CF +236" },
        { "value": 42, "label": "TD +235" },
        { "value": 43, "label": "CL +56" },
        { "value": 44, "label": "CN +86" },
        { "value": 45, "label": "CX +61" },
        { "value": 46, "label": "CC +61" },
        { "value": 47, "label": "CO +57" },
        { "value": 48, "label": "KM +269" },
        { "value": 49, "label": "CK +682" },
        { "value": 50, "label": "CR +506" },
        { "value": 51, "label": "HR +385" },
        { "value": 52, "label": "CU +53" },
        { "value": 53, "label": "CW +599" },
        { "value": 54, "label": "CY +357" },
        { "value": 55, "label": "CZ +420" },
        { "value": 56, "label": "CD +243" },
        { "value": 57, "label": "DK +45" },
        { "value": 58, "label": "DJ +253" },
        { "value": 59, "label": "DM +1-767" },
        { "value": 60, "label": "DO +1-809, 1-829, 1-849" },
        { "value": 61, "label": "TL +670" },
        { "value": 62, "label": "EC +593" },
        { "value": 63, "label": "EG +20" },
        { "value": 64, "label": "SV +503" },
        { "value": 65, "label": "GQ +240" },
        { "value": 66, "label": "ER +291" },
        { "value": 67, "label": "EE +372" },
        { "value": 68, "label": "ET +251" },
        { "value": 69, "label": "FK +500" },
        { "value": 70, "label": "FO +298" },
        { "value": 71, "label": "FJ +679" },
        { "value": 72, "label": "FI +358" },
        { "value": 73, "label": "FR +33" },
        { "value": 74, "label": "PF +689" },
        { "value": 75, "label": "GA +241" },
        { "value": 76, "label": "GM +220" },
        { "value": 77, "label": "GE +995" },
        { "value": 78, "label": "DE +49" },
        { "value": 79, "label": "GH +233" },
        { "value": 80, "label": "GI +350" },
        { "value": 81, "label": "GR +30" },
        { "value": 82, "label": "GL +299" },
        { "value": 83, "label": "GD +1-473" },
        { "value": 84, "label": "GU +1-671" },
        { "value": 85, "label": "GT +502" },
        { "value": 86, "label": "GG +44-1481" },
        { "value": 87, "label": "GN +224" },
        { "value": 88, "label": "GW +245" },
        { "value": 89, "label": "GY +592" },
        { "value": 90, "label": "HT +509" },
        { "value": 91, "label": "HN +504" },
        { "value": 92, "label": "HK +852" },
        { "value": 93, "label": "HU +36" },
        { "value": 94, "label": "IS +354" },
        { "value": 95, "label": "IN +91" },
        { "value": 96, "label": "ID +62" },
        { "value": 97, "label": "IR +98" },
        { "value": 98, "label": "IQ +964" },
        { "value": 99, "label": "IE +353" },
        { "value": 100, "label": "IM +44-1624" },
        { "value": 101, "label": "IL +972" },
        { "value": 102, "label": "IT +39" },
        { "value": 103, "label": "CI +225" },
        { "value": 104, "label": "JM +1-876" },
        { "value": 105, "label": "JP +81" },
        { "value": 106, "label": "JE +44-1534" },
        { "value": 107, "label": "JO +962" },
        { "value": 108, "label": "KZ +7" },
        { "value": 109, "label": "KE +254" },
        { "value": 110, "label": "KI +686" },
        { "value": 111, "label": "XK +383" },
        { "value": 112, "label": "KW +965" },
        { "value": 113, "label": "KG +996" },
        { "value": 114, "label": "LA +856" },
        { "value": 115, "label": "LV +371" },
        { "value": 116, "label": "LB +961" },
        { "value": 117, "label": "LS +266" },
        { "value": 118, "label": "LR +231" },
        { "value": 119, "label": "LY +218" },
        { "value": 120, "label": "LI +423" },
        { "value": 121, "label": "LT +370" },
        { "value": 122, "label": "LU +352" },
        { "value": 123, "label": "MO +853" },
        { "value": 124, "label": "MK +389" },
        { "value": 125, "label": "MG +261" },
        { "value": 126, "label": "MW +265" },
        { "value": 127, "label": "MY +60" },
        { "value": 128, "label": "MV +960" },
        { "value": 129, "label": "ML +223" },
        { "value": 130, "label": "MT +356" },
        { "value": 131, "label": "MH +692" },
        { "value": 132, "label": "MR +222" },
        { "value": 133, "label": "MU +230" },
        { "value": 134, "label": "YT +262" },
        { "value": 135, "label": "MX +52" },
        { "value": 136, "label": "FM +691" },
        { "value": 137, "label": "MD +373" },
        { "value": 138, "label": "MC +377" },
        { "value": 139, "label": "MN +976" },
        { "value": 140, "label": "ME +382" },
        { "value": 141, "label": "MS +1-664" },
        { "value": 142, "label": "MA +212" },
        { "value": 143, "label": "MZ +258" },
        { "value": 144, "label": "MM +95" },
        { "value": 145, "label": "NA +264" },
        { "value": 146, "label": "NR +674" },
        { "value": 147, "label": "NP +977" },
        { "value": 148, "label": "NL +31" },
        { "value": 149, "label": "AN +599" },
        { "value": 150, "label": "NC +687" },
        { "value": 151, "label": "NZ +64" },
        { "value": 152, "label": "NI +505" },
        { "value": 153, "label": "NE +227" },
        { "value": 154, "label": "NG +234" },
        { "value": 155, "label": "NU +683" },
        { "value": 156, "label": "KP +850" },
        { "value": 157, "label": "MP +1-670" },
        { "value": 158, "label": "NO +47" },
        { "value": 159, "label": "OM +968" },
        { "value": 160, "label": "PK +92" },
        { "value": 161, "label": "PW +680" },
        { "value": 162, "label": "PS +970" },
        { "value": 163, "label": "PA +507" },
        { "value": 164, "label": "PG +675" },
        { "value": 165, "label": "PY +595" },
        { "value": 166, "label": "PE +51" },
        { "value": 167, "label": "PH +63" },
        { "value": 168, "label": "PN +64" },
        { "value": 169, "label": "PL +48" },
        { "value": 170, "label": "PT +351" },
        { "value": 171, "label": "PR +1-787, 1-939" },
        { "value": 172, "label": "QA +974" },
        { "value": 173, "label": "CG +242" },
        { "value": 174, "label": "RE +262" },
        { "value": 175, "label": "RO +40" },
        { "value": 176, "label": "RU +7" },
        { "value": 177, "label": "RW +250" },
        { "value": 178, "label": "BL +590" },
        { "value": 179, "label": "SH +290" },
        { "value": 180, "label": "KN +1-869" },
        { "value": 181, "label": "LC +1-758" },
        { "value": 182, "label": "MF +590" },
        { "value": 183, "label": "PM +508" },
        { "value": 184, "label": "VC +1-784" },
        { "value": 185, "label": "WS +685" },
        { "value": 186, "label": "SM +378" },
        { "value": 187, "label": "ST +239" },
        { "value": 188, "label": "SA +966" },
        { "value": 189, "label": "SN +221" },
        { "value": 190, "label": "RS +381" },
        { "value": 191, "label": "SC +248" },
        { "value": 192, "label": "SL +232" },
        { "value": 193, "label": "SG +65" },
        { "value": 194, "label": "SX +1-721" },
        { "value": 195, "label": "SK +421" },
        { "value": 196, "label": "SI +386" },
        { "value": 197, "label": "SB +677" },
        { "value": 198, "label": "SO +252" },
        { "value": 199, "label": "ZA +27" },
        { "value": 200, "label": "KR +82" },
        { "value": 201, "label": "SS +211" },
        { "value": 202, "label": "ES +34" },
        { "value": 203, "label": "LK +94" },
        { "value": 204, "label": "SD +249" },
        { "value": 205, "label": "SR +597" },
        { "value": 206, "label": "SJ +47" },
        { "value": 207, "label": "SZ +268" },
        { "value": 208, "label": "SE +46" },
        { "value": 209, "label": "CH +41" },
        { "value": 210, "label": "SY +963" },
        { "value": 211, "label": "TW +886" },
        { "value": 212, "label": "TJ +992" },
        { "value": 213, "label": "TZ +255" },
        { "value": 214, "label": "TH +66" },
        { "value": 215, "label": "TG +228" },
        { "value": 216, "label": "TK +690" },
        { "value": 217, "label": "TO +676" },
        { "value": 218, "label": "TT +1-868" },
        { "value": 219, "label": "TN +216" },
        { "value": 220, "label": "TR +90" },
        { "value": 221, "label": "TM +993" },
        { "value": 222, "label": "TC +1-649" },
        { "value": 223, "label": "TV +688" },
        { "value": 224, "label": "VI +1-340" },
        { "value": 225, "label": "UG +256" },
        { "value": 226, "label": "UA +380" },
        { "value": 227, "label": "AE +971" },
        { "value": 228, "label": "GB +44" },
        { "value": 229, "label": "US +1" },
        { "value": 230, "label": "UY +598" },
        { "value": 231, "label": "UZ +998" },
        { "value": 232, "label": "VU +678" },
        { "value": 233, "label": "VA +379" },
        { "value": 234, "label": "VE +58" },
        { "value": 235, "label": "VN +84" },
        { "value": 236, "label": "WF +681" },
        { "value": 237, "label": "EH +212" },
        { "value": 238, "label": "YE +967" },
        { "value": 239, "label": "ZM +260" },
        { "value": 240, "label": "ZW +263" }
    ]

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [phonecode, setPhoneCode] = useState(countrycode[94]);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);

    if (redirect) {
        return <Navigate to={'/contactus-thankyou'} />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (name.trim().length === 0) {
            errorToast(ERR_FULL_NAME_REQUIRED);
            return false;
        }
        if (lastname.trim().length === 0) {
            errorToast(ERR_LAST_NAME_REQUIRED);
            return false;
        }
        if (email.trim().length === 0) {
            errorToast(ERR_EMAIL_REQUIRED);
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) {
            errorToast(ERR_INVALID_EMAIL);
            return false;
        }
        if (mobile.trim().length === 0) {
            errorToast(ERR_PHONE_REQUIRED);
            return false;
        }
        const numberRegex = /^[0-9]+$/;
        if (!numberRegex.test(mobile.trim())) {
            errorToast(ERR_INVALID_PHONE);
            return false;
        }
        if (subject.trim().length === 0) {
            errorToast(ERR_REQ_SUBJECT);
            return false;
        }
        try {
            await superagent.post(`${API_BASE_URL}send-email`)
                .send({ formName: FRM_NO_COST, firstName: name, lastName: lastname, email: email, phoneCode: phonecode.label, mobileNumber: mobile, subject: FRM_NO_COST, message: message }).withCredentials();
            setLoading(false);
            setRedirect(true);
        } catch (error: any) {
            setLoading(false);
            errorToast(ERR_FAILED_EMAIL)
        }
    };
    return (
        <>
            {/* <ToastContainer /> */}
            <div className='CustContainer'>
                <div className='getintouchboxMaincov'>
                    <div className='getintouchboxLeft'>
                        <h3>Get in Touch</h3>
                        <p>We’d like to talk with you.</p>
                        <form onSubmit={handleSubmit} id='GetinTouch'>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="InputMainComset" controlId="">
                                        <Form.Label>First Name*</Form.Label>
                                        <Form.Control type="" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="InputMainComset" controlId="">
                                        <Form.Label>Last Name*</Form.Label>
                                        <Form.Control type="" placeholder="" value={lastname} onChange={e => setLastname(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="InputMainComset" controlId="">
                                        <Form.Label>Email Address*</Form.Label>
                                        <Form.Control type="" className='emailID' placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="InputMainComset" controlId="">
                                        <Form.Label>Phone Number*</Form.Label>
                                        <div className='MobileNumbSltbox'>
                                            <div className='MobileNumbCode'>
                                                <Select
                                                    components={animatedComponents}
                                                    options={countrycode}
                                                    classNamePrefix="react-select"
                                                    defaultValue={countrycode[94]}
                                                    value={phonecode}
                                                    onChange={(selectedOption) => setPhoneCode(selectedOption as any)}
                                                />
                                            </div>
                                            <Form.Control type="text" className='phoneNumber' placeholder="" value={mobile} onChange={e => setMobile(e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="InputMainComset" controlId="">
                                        <Form.Label>Subject*</Form.Label>
                                        <Form.Control type="" placeholder="" value={subject} onChange={e => setSubject(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="TextareaMainComset" controlId="">
                                        <Form.Label>Message*</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={message} onChange={e => setMessage(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Button type="submit">Send Message <FiArrowUpRight /></Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                    <div className='getintouchboxRight'>
                        <h3>Contact Information</h3>
                        <p>Fill up the form and our Team will get back to you within 24 hours.</p>
                        <ul>
                            <li><BsFillTelephoneFill /> <a href="tel:+91 9974466992">+91 99744 66992</a></li>
                            <li><MdEmail /> <a href="mailto:info@voltageshift.com">info@voltageshift.com</a></li>
                            <li><IoLocationSharp />Head Office: C/334, Siddhi Vinayak Tower, Near Kataria Arcade, Makarba, S.G. Highway, Ahmedabad, Gujarat, India, 380051.</li>
                            <li><IoLocationSharp />Sales Office: Oud Metha Offices 112 - Umm Hurair 2 - Dubai
                                Healthcare City - Dubai, UAE</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetinTouch