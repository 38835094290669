import { Helmet } from 'react-helmet-async';
import { FRONT_BACKEND_BASE_URL } from '../../constants/commonConstants';

interface MetaTagsProps {
    title: string;
    description: string;
    keywords: string;
    focusedkeyword: string;
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
    canonicalURL: string;
}

function Metatags({ title, description, keywords, focusedkeyword, ogTitle, ogDescription, ogImage, canonicalURL }: MetaTagsProps) {
    const setTitle = (title) ? title : 'VoltageShift | Trusted Transformer Manufacturer, Services, and Spares Supplier';
    const setDescription = (description) ? description : 'VoltageShift - Leading transformer manufacturer, exporter, and service provider in India. With 20+ years of experience, we deliver high-quality transformers, services, and reliable spares globally. Cost-effective solutions meeting industry standards.';
    const setKeywords = (keywords) ? keywords : 'Reliable transformers, High-quality transformers, Comprehensive transformer services, Transformer maintenance and repair, Transformer installation, Transformer spares and accessories, Global transformer solutions, Cost-effective transformers, Industry-standard transformers, Transformer expertise, Trusted transformer solutions, Transformer consultation, Best transformer manufacturer in India, Transformer service provider in India, Transformer spares supplier in India, Best transformer manufacturer in Ahmedabad, Best transformer service provider in Ahmedabad, Best transformer spares supplier in Ahmedabad, Best transformer manufacturer in Gujarat, Best transformer service provider in Gujarat, Best transformer spares supplier in Gujarat, Best transformer maintenance and repair in India, Best transformer maintenance and repair in Ahmedabad, Best transformer maintenance and repair in Gujarat, Custom transformer solutions, Energy-efficient transformers, Renewable energy transformers, CSS, Oil-filled transformers, Step-up transformers, Step-down transformers, Transformer design and engineering, Transformer testing and inspection, Transformer consultancy services, Transformer retrofit and upgradation, IEC Standard, IS Standard, Transformer manufacturing company, Transformer supplier, Transformer exporter in India, Transformer maintenance services, Transformer repair services in India, Transformer spare parts, Transformer reliability, Transformer efficiency, Transformer safety, Transformer standards compliance, Transformer customization, Transformer project management, Transformer refurbishment, Transformer insulation, Transformer cooling systems, Transformer monitoring and diagnostics, Transformer reliability testing, Transformer preventive maintenance, Transformer turnkey projects';
    const setFocusedkeyword = (focusedkeyword) ? focusedkeyword : 'VoltageShift, Transformer manufacturer, Transformer exporter, Transformer service provider, Transformer spares supplier, Power transformers, Distribution transformers, Industrial transformers, Furnace Transformers, Solar inverter duty transformers, Compact Secondary Substations, Dry-type transformers, Transformer installation, Transformer commissioning';

    const setOgTitle = (ogTitle) ? ogTitle : 'VoltageShift | Trusted Transformer Manufacturer, Services, and Spares Supplier';
    const setOgDescription = (ogDescription) ? ogDescription : 'VoltageShift - Leading transformer manufacturer, exporter, and service provider in India. With 20+ years of experience, we deliver high-quality transformers, services, and reliable spares globally. Cost-effective solutions meeting industry standards.';
    const setOgImage = (ogImage) ? FRONT_BACKEND_BASE_URL + ogImage : `https://www.voltageshift.com/og-logo.png`;
    let cur_url = 'INDEX,FOLLOW';
    if(window.location.pathname === '/transformer-spare-parts-lp'){
        cur_url = 'noindex,nofollow';
    }
    return (
        <Helmet>
            <title>{setTitle}</title>
            <link rel="canonical" href={canonicalURL} />
            <meta name="description" content={setDescription} />
            <meta name="keywords" content={setKeywords} />
            <meta name="Focused Keyword" content={setFocusedkeyword} />
            <meta property="og:title" content={setOgTitle} />
            <meta property="og:description" content={setOgDescription} />
            <meta property="og:image" content={setOgImage} />
            <meta property="og:url" content={canonicalURL} />
            <meta name="robots" content={cur_url} />
        </Helmet>
    );
}
export default Metatags;