import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import { Role } from "../../models/role";
import Form from 'react-bootstrap/Form';
import CountrySelect from '../../components/common/CountrySelect';
import { Col, Row } from 'react-bootstrap';

const UserCreate = () => {
    const [full_name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [roleId, setRoleId] = useState('');
    const [roles, setRoles] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [phonecode, setPhonecode] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('roles');
                setRoles(data);
            }
        )();
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            await axios.post('users', {
                full_name: full_name,
                phonecode: phonecode,
                mobile: mobile,
                email,
                role_id: roleId,
                password: password
            });
            setRedirect(true);
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/users-list'} />
    }

    const handleCountrySelect = (selectedOption: any) => {
        if (selectedOption) {
            setPhonecode(selectedOption.id);
        } else {
            setPhonecode('');
        }
    };

    return (
        <Wrapper>
            <div className='CardPedboxCover'>
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Create User</h1>
                    </div>
                    <div className='AdmintopTitleRight'></div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={6}>
                                <div className='InputMainComset'>
                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                    <input type="text" className="form-control" id="fullName"
                                        onChange={e => setFullName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="InputMainComset" controlId="">
                                    <Form.Label>Country Code</Form.Label>
                                    <CountrySelect onSelect={handleCountrySelect} className="mb-2" />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="InputMainComset" controlId="">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your Mobile Number" autoComplete='false'
                                        onChange={e => setMobile(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <div className='InputMainComset'>
                                    <label htmlFor="emailId" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="emailId"
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <label htmlFor="role" className="form-label">Role</label>
                                <select className="form-select" id="role"
                                    onChange={e => setRoleId(e.target.value)}>
                                    <option>Please select a role...</option>
                                    {roles.map((r: Role) => {
                                        if (r.id > 1) {
                                            return (
                                                <option key={r.id} value={r.id}>{r.name}</option>
                                            )
                                        }
                                    })}
                                </select>
                            </Col>
                            <Col md={6}>
                                <div className='InputMainComset'>
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default UserCreate;