import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';

import TransformersSlider from './TransformersSlider';

import Button from 'react-bootstrap/Button';

import { BiCheck } from "react-icons/bi";

import { PowerTransformImg2, ServiceBrochure } from '../../assets';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function DistributionTransformer() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Distribution Transformer';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/distribution-transformer`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />

            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/distribution-transformer`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Distribution Transformer</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>

                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Distribution Transformer</h1>
                                <p>Highly Reliable and Intricately Designed Distribution Transformers</p>
                            </div>
                        </div>

                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg2} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> 100KVA to 2500KVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 33KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> ONAN/ONAF</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Tap Changer :</span> Off Circuit or On Load Tap Changer</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IS 2026, IS 1180, IEC 60076</h4>

                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>

                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Conclude your search for <span>Distribution Transformer Manufacturer</span> by choosing
                                VoltageShift. We are a renowned <span>Distribution Transformer Service Provider Company</span>,
                                involved in designing & engineering, sales & installation, and repair & maintenance of
                                Distribution Transformers.
                            </p>

                            <p>They are available in various sizes & configurations, including <span>3-Phase Distribution
                                Transformers</span>. We meticulously select manufacturing materials, such as copper & aluminum
                                windings, to provide phenomenal performance & outstanding durability Distribution
                                Transformers.
                            </p>

                            <p>As a highly trusted <span>Distribution Transformer Manufacturing Company</span>, we strive to
                                deliver cost-effective and unparalleled Distribution Transformer Solutions by employing our
                                immense experience, knowledge, and expertise.</p>
                            <p>We have deployed cutting-edge technology in-house and utilize best practices to manufacture
                                Distribution Transformers in adherence with IS & IEC's highest standards. Bank on us and
                                experience the best-in-class Distribution Transformer Solutions & Services.</p>
                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Distribution Transformer Salient Features & Technical Specifications</h3>
                            <p>As a leading <span>3 Phase Distribution Transformer Supplier</span>, we offer a range of highly
                                durable & reliable Distribution Transformers intricately designed to transmit electrical energy
                                from distribution networks to end consumers. The following are the technical specifications
                                & salient features of our Distribution Transformer:</p>
                            <h4><BiCheck /> Our <span>Distribution Transformer Power rating</span> in KVA is 100 to 2500.</h4>
                            <h4><BiCheck /> The <span>Distribution Transformer Voltage rating</span> is upto 33 KV</h4>
                            <h4><BiCheck /> <span>Winding Material:</span> Copper or Aluminum</h4>
                            <h4><BiCheck /> <span>Cooling:</span> ONAN/ONAF</h4>
                            <h4><BiCheck /> <span>Type (Duty):</span> Outdoor/Indoor</h4>
                            <h4><BiCheck /> <span>Frequency Rating:</span> 50Hz or 60Hz</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dyn1/ Dyn5 / Dyn11 or as per specific requirement</h4>
                            <h4><BiCheck /> <span>Tap Changer:</span> Off Circuit or On Load Tap Changer Insulating</h4>
                            <h4><BiCheck /> <span>Oil Types:</span> Mineral Oil/Ester Oil</h4>

                            <p>Being a reliable <span>3 Phase Distribution Transformer Manufacturer</span>, all our Distribution
                                Transformers are manufactured with adherence to IS 2026, IS 1180, and IEC 60076
                                standards.
                            </p>
                            <p>We provide <span>Distribution Transformer Installation Services</span> carried out by our highly
                                qualified technicians in a safe environment. You will get 100% Authentic & Genuine
                                <span>Distribution Transformer Spare Parts</span> from us, making VoltageShift your one-stop
                                destination for all Transformer needs.</p>


                        </div>
                    </div>




                </div>

                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Distribution Transformer Applications</h3>
                                <p>As a trustworthy <span>Distribution Transformer Exporter</span>, we export to 45+ countries and
                                    provide clients with comprehensive services, from Distribution Transformer Installation to
                                    Distribution Transformer Repair.
                                </p>
                                <p>Our Distribution Transformer suits various industries' applications, including turnkey
                                    projects. The following are the industries' applications we serve, irrespective of their size and
                                    scale:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <h3>Salient Features</h3>
                                    <ul>
                                        <li><BiCheck /> Steel Industries</li>
                                        <li><BiCheck /> Cement Industries</li>
                                        <li><BiCheck /> Chemical Industries</li>
                                        <li><BiCheck /> Food and Beverages Industries</li>
                                        <li><BiCheck /> Ceramics Industries</li>
                                        <li><BiCheck /> Hospital and Pharmaceutical Industries</li>
                                        <li><BiCheck /> Manufacturing Industries</li>
                                        <li><BiCheck /> Commercial & Residential Buildings</li>
                                        <li><BiCheck /> Renewable Energy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>We will help you select the ideal one according to your <span>Distribution Transformer Rating</span> requirements.</p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Reasons for Choosing Our Distribution Transformers</h3>
                        <h6>Partner with us as your <span>Distribution Transformer Service Provider</span>, and avail fantastic
                            benefits. As a fast-growing <span>Distribution Transformer Supplier</span>, we provide Installation,
                            Testing, and Commissioning services for the Distribution Transformer on-site on the
                            International level.
                        </h6>
                        <h6>The following are the reasons why you should choose our Distribution Transformers:</h6>
                        <p><BiCheck /> Delicately designed & engineered to withstand various electrical loads, dynamic
                            stress, and changing weather conditions.</p>
                        <p><BiCheck /> Have a Low Magnetizing current to reduce the load on your electricity bills &
                            mitigating capacitor needs - a cost-effective investment.</p>
                        <p><BiCheck /> HV & LV Side Cable Box/Bus Duct and customized oil options according to your
                            requirements.</p>
                        <p><BiCheck /> Supplying <span>Distribution Transformer Parts</span> to 45+ countries for quick replacement
                            & keeping the transformer functioning like a new one.</p>
                        <p><BiCheck /> Employment of best practices and latest technology & tools to test and commission
                            transformers for their peak performance.</p>
                        <p><BiCheck /> Highly Trained & Professional Customer Service Support providing personalized
                            solutions & promptly resolving queries.</p>
                        <p><BiCheck /> Assistance with <span>Distribution Transformer Maintenance</span>, which includes timely
                            servicing & oil changes after two years to ensure the transformer is working correctly
                            and giving value for investment to clients.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>

                <TransformersSlider />

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a 100 KVA Distribution Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A 100 KVA Distribution Transformer transfers electrical energy from one voltage level to
                                        another. You have the option to increase or decrease the voltage levels of an electrical supply
                                        to ensure an efficient supply of electricity. The winding numbers or primary voltage is lesser
                                        than the secondary.</p>
                                    <p>If you are looking for a <span>100 KVA Distribution Transformer Manufacturer & Supplier</span>,
                                        VoltageShift is your one-stop source. We manufacture supreme quality 100 KVA Distribution
                                        Transformers. <a href="/contact-us" target="_blank">Contact us</a> for a Free Consultation and Quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is a 2500 KVA Distribution Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>The <span>2500 KVA Distribution Transformer</span> decreases the primary voltage in correspondence
                                        to the lower secondary voltage. And after that, it is supplied to utility consumers. A Single
                                        2500 KVA (2.5 MVA) is robust enough to serve one big building and residential property.</p>
                                    <p>They are divided into copper & aluminum winding transformers. It helps to shorten
                                        low-voltage cable length and reduce power costs & line losses.</p>
                                    <p>If you are searching for a <span>2500 KVA Distribution Transformer Manufacturer & Supplier</span>,
                                        don't look beyond VoltageShift. We manufacture highly durable Distribution Transformers.
                                        <a href="/contact-us" target="_blank">Contact us</a> for a quotation.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What is a 33KV Distribution Transformer?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A <span>33KV Distribution Transformer</span> is a high-voltage substation to distribute electrical
                                        energy & power from one small substation to another, especially in residential areas. It has
                                        low noise, low partial discharge, low loss, and low-temperature rise, proving to be an
                                        energy-saving investment.</p>
                                    <p>If you are looking for a <span>33KV Distribution Transformer Manufacturer & Supplier</span>,
                                        VoltageShift is your prime destination. <a href="/contact-us" target="_blank">Contact us</a> for a quotation and free consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default DistributionTransformer