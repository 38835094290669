import React from 'react'
import './banner-style.css';

import { BannerTwo, BannerMobileTwo, BannerThree, BannerFour, BannerMobileThree, BannerMobileFour, } from '../../assets';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import LazyLoad from 'react-lazyload';

function Banner() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <>
            <div className='BannerCover DesksetboxBanner'>
                <Slider {...settings}>


                    <div className='BannerIner'>
                        <a href="/transformers">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerThree} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>
                    <div className='BannerIner'>
                        <a href="/transformer-services">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerFour} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>
                    <div className='BannerIner'>
                        <a href="#NoCostConsult">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerTwo} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>


                </Slider>
            </div>
            <div className='BannerCover MobilesetboxBanner'>
                <Slider {...settings}>


                    <div className='BannerIner'>
                        <a href="/transformers">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerMobileThree} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>
                    <div className='BannerIner'>
                        <a href="/transformer-services">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerMobileFour} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>
                    <div className='BannerIner'>
                        <a href="#NoCostConsult">
                            <LazyLoad
                                placeholder={<div>Loading...</div>}
                            >
                                <img src={BannerMobileTwo} alt="Banner" />
                            </LazyLoad>

                        </a>
                    </div>


                </Slider>
            </div>
        </>
    )
}

export default Banner