import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
function TextEditor(props: { content: any, onChange: any, contentId: number }) {
    const editorRef = useRef<any>();
    const [cKContent, setCKContent] = useState(props.content);
    const [cKContentID, setCKContentID] = useState(props.contentId);
    useEffect(() => {
        setCKContent(props.content);
        setCKContentID(props.contentId);
    }, [props.content]);
    const handleChange = (event: any) => {
        const data = event.editor.getData();
        props.onChange(data);
    };
    return (
        <>
            {(cKContentID >= 1 || cKContent.length > 0) &&
                <CKEditor
                    ref={editorRef}
                    config={{
                        allowedContent: true,
                        forcePasteAsPlainText: false,
                        pasteFromWordRemoveFontStyles: false,
                        pasteFromWordRemoveStyles: false,
                        extraAllowedContent: 'p(mso*,Normal)',
                        pasteFilter: 'semantic-content',
                        pasteFromWordNumberedHeadingToList: true,
                    }}
                    initData={cKContent}
                    onChange={handleChange}
                />

            }
        </>
    );
}
export default TextEditor;