import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FiSend } from "react-icons/fi";
import './footer-style.css';
import { WhiteLogo } from '../../../assets';
import { FaFacebookF, FaGooglePlusG, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import { Navigate } from 'react-router-dom';
import { API_BASE_URL, ERR_EMAIL_REQUIRED, ERR_FAILED_EMAIL, ERR_INVALID_EMAIL, FRM_NEWS_SUB } from '../../../constants/commonConstants';

import { successToast, errorToast } from '../../../utils/toastrUtils';
import { ToastContainer } from 'react-toastify';
import superagent from 'superagent';

function Index() {

    const [email, setEmail] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);

    if (redirect) {
        window.location.href = "/subscription-thankyou";
        // return <Navigate to={'/thankyou'} />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (email.trim().length === 0) {
            errorToast(ERR_EMAIL_REQUIRED);
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) {
            errorToast(ERR_INVALID_EMAIL);
            return false;
        }

        try {
            await superagent.post(`${API_BASE_URL}send-email`)
                .send({ formName: FRM_NEWS_SUB, firstName: "", lastName: "", email: email, phoneCode: "", mobileNumber: "", subject: 'Subscribe Newsletter', message: "" }).withCredentials();
            setLoading(false);
            setRedirect(true);
        } catch (error: any) {
            setLoading(false);
            errorToast(ERR_FAILED_EMAIL)
        }
    };


    return (
        <>
            <footer>
                <div className='CustContainer'>
                    <div className='footmainBoxset'>
                        <div className='footmainLogobox'>
                            <a href="/">
                                <img src={WhiteLogo} alt="Logo" />
                            </a>
                            <p>We specialize in providing customized <br /> solutions to meet the specific needs of <br /> our clients in power transmission,  <br /> distribution, and other industries.</p>
                            <ul>
                                {/* <li><a href=""><FaFacebookF /></a></li> */}
                                {/* <li><a href=""><FaGooglePlusG /></a></li> */}
                                {/* <li><a href="https://www.linkedin.com/company/voltageshift/" target="_blank"><FaLinkedinIn /></a></li> */}
                                {/* <li><a href=""><FaYoutube /></a></li> */}
                            </ul>
                        </div>
                        <div className='QuickCompMain'>
                            <h3>Contact Us</h3>
                            <h6><IoLocationSharp />Head Office: C/334, Siddhi Vinayak Tower, Near Kataria Arcade, Makarba, S.G. Highway, Ahmedabad, Gujarat, India, 380051.</h6>
                            <h6><IoLocationSharp />Sales Office: Oud Metha Offices 112 - Umm Hurair 2 - Dubai
                                Healthcare City - Dubai, UAE</h6>
                            <h6><BsFillTelephoneFill /> <a href="tel:+91 9974466992">+91 99744 66992</a></h6>
                            <h6><MdEmail /> <a href="mailto:info@voltageshift.com">info@voltageshift.com</a></h6>

                        </div>
                        <div className='QuickCompMain'>
                            <h3>Quick Links</h3>
                            <ul>
                                <li><a href="/transformers">Transformers</a></li>
                                <li><a href="/transformer-services">Transformers Services</a></li>
                                <li><a href="/transformer-spares">Transformer Spares</a></li>
                                <li><a href="/about-us">About us</a></li>
                                <li><a href="/news">News</a></li>
                                <li><a href="/contact-us">Contact us</a></li>
                                <li><a href="/faq">FAQ</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className='StaydateboxCov'>
                            <h3>Stay up to date</h3>
                            <p>Sign up to receive our newsletter for instant access to insights.</p>
                            <form onSubmit={handleSubmit}>
                                <Form.Group className="StaydateboxIner" controlId="">
                                    <Form.Control type="" placeholder="Your email address" value={email} onChange={e => setEmail(e.target.value)} />
                                    <button type="submit"><FiSend /></button>
                                </Form.Group>
                            </form>
                        </div>
                    </div>
                    <div className='footmainBoxBotbox'>
                        <p>© 2023 VoltageShift Engineering Solutions LLP. All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Index