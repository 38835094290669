import { toast } from 'react-toastify';

const defaultOptions = {
    autoClose: 4000,
    className: 'toast-message',
    position: 'top-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    limit: 1,
};

export const successToast = (message: string, options?: any) => {
    toast.success(message, { ...defaultOptions, ...options });
};

export const errorToast = (message: string, options?: any) => {
    toast.error(message, { ...defaultOptions, ...options });
};