import axios from 'axios';
import React, { SyntheticEvent, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Wrapper from "../../components/Wrapper";
import { Col, Row } from 'react-bootstrap';

const CategoryCreate = () => {
    const [selected, setSelected] = useState([] as number[]);
    const [name, setName] = useState('');
    const [redirect, setRedirect] = useState(false);
    const check = (id: number) => {
        if (selected.some(s => s === id)) {
            setSelected(selected.filter(s => s !== id));
            return;
        }
        setSelected([...selected, id]);
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            await axios.post('categories', {
                name
            });
            setRedirect(true);
        } catch (error: any) {
            console.log(`${error.response.status}: ${error.response.message}`);
        }
    };

    if (redirect) {
        return <Navigate to={'/admin/category'} />
    }

    return (
        <Wrapper>
            <div className="CardPedboxCover">
                <div className='AdmintopTitleCov'>
                    <div className='AdmintopTitleLeft'>
                        <h1>Create Category</h1>
                    </div>
                </div>
                <div className='FormCardMainbox'>
                    <form onSubmit={submit}>
                        <Row>
                            <Col md={12}>
                                <div className="InputMainComset">
                                    <label htmlFor="firstName" className="form-label">Category Name</label>
                                    <input type="text" className="form-control" id="firstName"
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-outline-secondary">Save</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </Wrapper >
    );
};

export default CategoryCreate;