import React from 'react'
import './services-details-style.css';
import './services-style.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FiArrowUpRight } from "react-icons/fi";
import Row from 'react-bootstrap/Row';

import { InstallationAndCommissioning, TransformerTestingService, OilFiltration, OnSiteFaultTroubleshooting, OverhaulingService, RepairingService, AnnualMaintenanceContract, TurnkeyProjects } from '../../assets';
import { BsCheck } from 'react-icons/bs';

function OtherServicesSlider() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    return (
        <>
            <div className='OtherServiBoxCov'>
                <div className='CustContainer'>
                    <div className='OthetranprodListTitle'>
                        <h2>Other Services</h2>
                    </div>
                    <div className='OtherServiBoxcovmain ComArrowSlidercs'>
                        <Row>
                            <Slider {...settings}>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/installation-and-commissioning">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={InstallationAndCommissioning} alt="Installation and Commissioning" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Installation and Commissioning</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Installation and Commissioning</h3>
                                                <p><BsCheck /> On-site Installation & Commissioning at the International Level in 45+ Countries</p>
                                                <p><BsCheck /> Carried out by our highly Competent & Experienced Installers</p>
                                                <p><BsCheck /> Training provided to your operators for proper usage & maintenance</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/transformer-testing-service">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={TransformerTestingService} alt="Transformer Testing Service" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Transformer Testing Service</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Transformer Testing Service</h3>
                                                <p><BsCheck /> Our Experts are available 24/7 for Testing Services & Related Assistance</p>
                                                <p><BsCheck /> Breaker Testing to evaluate Breaker's performance & prevent interruptions</p>
                                                <p><BsCheck /> Relay Testing to keep your relays protected from faults and breakdowns</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/oil-filtration">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={OilFiltration} alt="Oil Filtration" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Oil Filtration</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Oil Filtration</h3>
                                                <p><BsCheck /> Usage of Advanced Oil Filtration Technology & Tools to remove impurities from the
                                                    Transformer Oil</p>
                                                <p><BsCheck /> On-Site Oil Filtration Services, Testing & Analysis ensure it complies with Industry's
                                                    Standards.</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/on-site-fault-troubleshooting">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={OnSiteFaultTroubleshooting} alt="On Site Fault Troubleshooting" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>On Site Fault Troubleshooting</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>On Site Fault Troubleshooting</h3>
                                                <p><BsCheck /> Customized Diagnostic Plan for On-Site Fault Troubleshooting</p>
                                                <p><BsCheck /> Determining appropriate corrective measures to resolve the issue</p>
                                                <p><BsCheck /> Maintaining Detailed Documentation to prevent future risks</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/overhauling-services">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={OverhaulingService} alt="Overhauling Service" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Overhauling Service</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Overhauling Service</h3>
                                                <p><BsCheck /> Thorough Inspection and Assessment of the Transformer's Condition</p>
                                                <p><BsCheck /> Professional Cleaning & Replacement of the Transformer's Components</p>
                                                <p><BsCheck /> Insulation Resistance Test of Transformer & Commissioning</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/repairing-service">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={RepairingService} alt="Repairing Service" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Repairing Service</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Repairing Service</h3>
                                                <p><BsCheck /> Careful Inspection and Assessment to detect the root cause of the issue</p>
                                                <p><BsCheck /> Preparing Bespoke Plan to fix the problem after Inspection & Assessment</p>
                                                <p><BsCheck /> Transformer Oil Analysis to detect any contaminants or degradation</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/annual-maintenance-contract">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={AnnualMaintenanceContract} alt="Annual Maintenance Contract" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Annual Maintenance Contract (AMC)</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Annual Maintenance Contract (AMC)</h3>
                                                <p><BsCheck /> Regular Inspections to ensure the transformers are functioning smoothly</p>
                                                <p><BsCheck /> Scheduled Maintenance for Oil Filtration and Bushing & Contact cleaning</p>
                                                <p><BsCheck /> 24/7 Emergency Service to address & resolve the issue upfront</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='OtherServisboxCov'>
                                    <div className='OurservofferBoxSrvIner'>
                                        <a href="/turnkey-projects">
                                            <div className='OurservofferBoxSrvImg'>
                                                <img src={TurnkeyProjects} alt="Turnkey projects" />
                                            </div>
                                            <div className='OurservofferBoxSrvTxt'>
                                                <h3>Turnkey projects</h3>
                                                <p>Learn More <FiArrowUpRight /></p>
                                            </div>
                                            <div className='OurservofferBoxSrvBox'>
                                                <h3>Turnkey projects</h3>
                                                <p><BsCheck /> For Transformer & Switchgear Industry for upto 400 KV Voltage Class</p>
                                                <p><BsCheck /> Includes Installation, Testing, and Commissioning of Transformer</p>
                                                <p><BsCheck /> End-to-End Transformer Solutions for Solar & Substation Projects</p>
                                                <h6>Learn More <FiArrowUpRight /></h6>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </Slider>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtherServicesSlider