import React, { useEffect, useState } from 'react';
import './transformers-style.css';
import { RiArrowRightSLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";

import ConsultationForm from '../../components/common/consultation';
import BlogList from '../../components/blog';
import Continent from '../../components/continent/Continent';

import ContactUsInfo from '../../components/contactinfo/ContactUsInfo';

import TransformersSlider from './TransformersSlider';

import Button from 'react-bootstrap/Button';

import { BiCheck } from "react-icons/bi";

import { PowerTransformImg6, ServiceBrochure } from '../../assets';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Metatags from '../../components/meta/metatags';
import { FRONT_BASE_URL } from '../../constants/commonConstants';

import GetaQuote from "../../components/common/getquote/GetaQuote";

function CompactSecondarySubstations() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeyword, setMetaKeyword] = useState('');
    const [focusKeyword, setFocusKeyword] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    useEffect(() => {
        document.title = 'Compact Secondary Substations Transformers';
        fetchSeo();
    }, []);
    const fetchSeo = async () => {
        const { data } = await axios.get(`getseo/compact-secondary-substations`);
        /* SEO - Start */
        setMetaTitle(data.data.meta_title)
        setMetaKeyword(data.data.meta_keyword)
        setMetaDescription(data.data.meta_description)
        setFocusKeyword(data.data.focus_keyword)
        setOgTitle(data.data.og_title)
        setOgDescription(data.data.og_description)
        /* SEO - End */
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <>
            <GetaQuote show={show} onHide={() => handleClose()} />

            <Metatags
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeyword}
                focusedkeyword={focusKeyword}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage=''
                canonicalURL={FRONT_BASE_URL + `/compact-secondary-substations`}
            />
            <div>
                <div className='CustContainer'>
                    <div className='BreadcrumbItem'>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li><a href="/transformers">Transformers</a></li>
                            <li><RiArrowRightSLine /></li>
                            <li>Compact Secondary Substations Transformers</li>
                        </ul>
                    </div>
                </div>

                <div className='ProdDetailsBgbox'>
                    <div className='CustContainer'>

                        <div className='ProdDetailsTopTitle'>
                            <div className='ProdDetailsTopLeft'>
                                <h1>Compact Secondary Substations Transformers</h1>
                                <p>Cleverly Designed, Tested, and Manufactured Compact Secondary Substations</p>
                            </div>
                        </div>

                        <div className='imgandTecSpecbox'>
                            <div className='imgandTecSpecLeft'>
                                <img src={PowerTransformImg6} alt="Technical Specification" />
                            </div>
                            <div className='imgandTecSpecRight'>
                                <h3>Technical Specification</h3>
                                <h4><BiCheck /> <span>Power Rating :</span> 100KVA to 2500KVA</h4>
                                <h4><BiCheck /> <span>Voltage Rating :</span> Up to 33KV Class</h4>
                                <h4><BiCheck /> <span>Cooling :</span> ONAN/AN/AF</h4>
                                <h4><BiCheck /> <span>Type (Duty) :</span> Indoor/Outdoor</h4>
                                <h4><BiCheck /> <span>Frequency Rating :</span> 50Hz or 60Hz</h4>
                                <h4><BiCheck /> <span>Applicable Standards :</span> IS 62271</h4>


                                <Button onClick={handleShow}>Get A Quotation</Button>
                                <a href={ServiceBrochure} download><FaCloudDownloadAlt /> Download Brochure</a>
                            </div>

                        </div>

                        <div className='ProdDetailsOverview'>
                            <h3>Overview</h3>
                            <p>Give your industrial complexes, commercial & residential buildings, and infrastructure
                                projects a robust durability & astounding performance <span>Compact Substation Transformer</span>.</p>
                            <p>Being a renowned <span>Compact Secondary Substations Exporter</span>, we export to 45+ countries
                                and provide on-site services at the International Level, from <span>Substation Transformer
                                    Installation</span> to Testing & Commissioning, Repair & Maintenance Services. We comprehend
                                the requirements of your application and, accordingly, design the perfect <span>Compact
                                    Substation Solution</span> with ideal configurations.</p>
                            <p>As a leading <span>Compact Secondary Substations Manufacturing Company</span>, we cleverly
                                design, rest, and manufacture our substations transformers. It is compact in size and a highly
                                reliable power distribution unit with switchgear to control power distribution & protection
                                against overloads & faults.</p>
                            <p>As a highly reliable <span>Compact Secondary Substations Manufacturer</span>, we utilize
                                cutting-edge technology & tools deployed in-house and blend them with our knowledge &
                                experience to manufacture premier-quality <span>Compact Substations</span> in adherence with
                                Industry's highest standards. Give your facility a decades-lasting substation by choosing us as
                                your <span>Compact Substation Supplier</span>.</p>
                            <hr />
                        </div>

                        <div className='ProdDetailsKeyFeatures'>
                            <h3>Our Compact Substation Transformers Salient Features & Technical Specifications</h3>
                            <p>As a fast-growing <span>3Phase Compact Secondary Substations Supplier</span>, we design and
                                manufacture 100KVA to 2500KVA Power Rating Transformers, which have low maintenance
                                costs & easy to transport.</p>
                            <p>Our <span>Compact Transformer Substation</span> fulfills our clients' various applications and technical
                                requirements as they are manufactured after meticulous research & comprehension of market
                                demands. The following are the Technical Specifications of our <span>3Phase Compact Secondary
                                    Substations</span>:</p>
                            <h4><BiCheck /> 33KV Voltage Rating Compact Secondary Substations at <span>33KV Compact Secondary
                                Substations Manufacturer</span> & Supplier</h4>
                            <h4><BiCheck /> <span>Frequency Rating:</span> 50 HZ or 60HZ</h4>
                            <h4><BiCheck /> <span>Cooling:</span> ONAN/AN/AF</h4>
                            <h4><BiCheck /> <span>Type (Duty):</span> Indoor/Outdoor</h4>
                            <h4><BiCheck /> <span>Vector Group:</span> Dyn1/ Dyn5 / Dyn11 or as per specific requirement</h4>
                            <h4><BiCheck /> <span>Insulating Oil Types:</span> Dry Type/ Oil immersed</h4>
                            <p>All our <span>Compact Substation Transformers</span> comply with IS 62271 standards. We provide
                                comprehensive <span>Compact Transformer Repair</span> Services to 45+ countries, including
                                inspection & assessment, disassembly & repair, transformer oil analysis, and more to ensure
                                our client's substation transformers operate excellently and give them value for their
                                investment.</p>
                        </div>
                    </div>
                </div>

                <div className='ApplictSiltfech'>
                    <div className='CustContainer'>
                        <div className='ApplictSiltfechMain'>
                            <div className='ApplictSiltfechLeft'>
                                <h3>Our Compact Secondary Substations Transformer Applications</h3>
                                <p>As a Reputable <span>Compact Secondary Substations Service Provider</span>, We strive to fulfill
                                    maximum industries' applications requirements, irrespective of their size, scale, and
                                    complexity, including turnkey projects with our Compact Substations Transformers.</p>
                                <p>We take care of all aspects to ensure our clients have all the required <span>Compact Substation
                                    Transformer Services</span> under one consolidated roof. The following are the <span>Compact
                                        Distribution Substation</span> application:</p>
                            </div>
                            <div className='ApplictSiltfechRight'>
                                <div className='SalientFeaturesListBox'>
                                    <h3>Salient Features</h3>
                                    <ul>
                                        <li><BiCheck /> Commercial Buildings</li>
                                        <li><BiCheck /> Residential Buildings</li>
                                        <li><BiCheck /> Shopping Malls</li>
                                        <li><BiCheck /> Hospitals</li>
                                        <li><BiCheck /> Utilities</li>
                                        <li><BiCheck /> Infrastructure Projects</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='optanoteMaincov'>
                            <p>As a trustworthy <span>Compact Secondary Substations Supplier</span>, we ensure the sectors &
                                industries mentioned above significantly benefit from our <span>Distribution Substations</span> by
                                providing them with comprehensive <span>Compact Substation Maintenance</span> services.
                            </p>
                            <p>It involves Timely Servicing, Regular Inspections, and 24/7 Emergency Service to address
                                any issues that may arise. We want to ensure our clients worry-free use our transformers
                                without any inconvenience.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='CustContainer'>
                    <div className='tfbolcMain'>
                        <h3>Benefits of Choosing Our Compact Secondary Substations</h3>
                        <h6>By selecting us as your <span>Compact Secondary Substations Service Provider Company</span>, you
                            will experience your various applications needs getting fulfilled & expectations being met.
                            We strive to deliver premier quality compact substations which are thoroughly tested &
                            designed to last for decades.</h6>
                        <h6>As one of the most prominent <span>Compact Substation Transformers Distributors</span>, we export
                            to 45+ countries and provide our clients with on-site services at the International Level. The
                            following are the benefits of choosing our Compact Secondary Substations Transformers:</h6>
                        <p><BiCheck />  Comprehensive range of <span>Compact Substation Components</span>, which are rigorously tested
                            and adhere to IS 62271 standards for remarkable durability & performance.</p>
                        <p><BiCheck /> No requirement for ground digging, and don't affect natural drainage, thereby mitigating
                            environmental hazards due to exposed grounding conductors or fencing.</p>
                        <p><BiCheck /> Due to their compact size, they utilize little space and perfectly fit different configurations.
                            It makes them easy to transport to the desired location without additional electrical clearance
                            needs.</p>
                        <p><BiCheck /> Compact Substation units are pre-assembled, which speeds up the installation process and
                            reduces substantial time & transportation costs, such as on-site testing and field wiring.</p>
                        <p><BiCheck /> As a reliable <span>Compact Substation Exporter</span>, we export 100% Genuine & Authentic
                            Compact Substations Spare Parts to 45+ countries to ensure they are readily available.</p>
                        <p><BiCheck /> As a highly Illustrious <span>Compact Substation Manufacturer</span>, we do meticulous
                            Installation, Testing, and Commissioning of your Compact Substation Transformers and train
                            your operators to efficiently use & maintain them.</p>
                    </div>
                </div>

                <div className='CustContainer'>
                    <ContactUsInfo />
                </div>

                <TransformersSlider />

                <ConsultationForm />

                <div className='CustContainer'>
                    <div className='HomepgTitleCov'>
                        <h3>Frequently Asked Question</h3>
                    </div>
                    <div className='faqPageBotset'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is a Compact Secondary Substation?</Accordion.Header>
                                <Accordion.Body>
                                    <p>A Compact Secondary Substation is a prefabricated substation designed & manufactured for
                                        installation in a space, which is limited and demand for efficient & reliable power supply is
                                        high. It is a self-contained unit that integrates components such as transformers, switchgear,
                                        auxiliary systems, and control systems in a single enclosure. It can be installed both indoors
                                        & outdoors.</p>
                                    <p>If you are searching for a <span>Compact Substation Transformers Supplier</span>, VoltageShift is your
                                        one-stop destination. We skillfully design & engineer our 3Phase Compact Substations to
                                        fulfill & meet various application needs, making us a top pick for <span>3 Phase Compact
                                            Secondary Substations Manufacturer</span>. <a href="/contact-us" target="_blank">Contact us</a> for a quotation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What are the Components of Secondary Substations?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Compact Secondary Substations are equipped with a monitoring system to continuously
                                        monitor voltage levels & power distribution to efficiently manage the flow.</p>
                                    <p>They help to identify issues in the system promptly for rapid action for the prevention of
                                        future catastrophes or outages. They generally contain various protective devices, such as
                                        circuit breakers and surge arresters, to shield substation equipment from faults.</p>
                                    <p>At VoltageShift, we manufacture <span>100 KVA & 2500 KVA Compact Secondary Substations</span>
                                        that are safe to use, have low maintenance costs, easy to transport, and are highly durable.
                                        Partner with us as your <span>100 KVA and 2500 KVA Compact Secondary Substations
                                            Manufacturer & Supplier</span> by <a href="/contact-us" target="_blank">contacting us</a> for a free consultation.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

                <BlogList />

                <Continent />
            </div>
        </>
    )
}

export default CompactSecondarySubstations