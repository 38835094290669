import React from 'react'
import './error-style.css';
import { ErrorImg } from '../../assets';

function PageNotFound() {
    return (
        <div className='CustContainer'>
            <div className='ErrorpageCov'>
                <img src={ErrorImg} alt="404" />
                <h1>Page not found</h1>
                <p>We Couldn't Find the Page You're Looking For. <br /> Reach Out to the VoltageShift for Further Support.</p>
                <a href="/">Back to home</a>
            </div>
        </div>
    )
}

export default PageNotFound