import React, { Dispatch, useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { connect } from "react-redux";
import { User } from "../models/user";
import { setUser } from "../redux/actions/setUserAction";
import Sidebar from '../components/common/sidebar';
import superagent from 'superagent';
import { API_BASE_URL } from '../constants/commonConstants';

const Wrapper = ({ setUser, children }: any) => {
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        (
            async () => {
                try {
                    const response = await superagent.get(`${API_BASE_URL}user`).withCredentials();
                    const data = response.body;
                    setUser(new User(
                        data.id,
                        data.full_name,
                        data.email,
                        data.phonecode,
                        data.mobile,
                        data.role
                    ));
                } catch (e) {
                    console.error("Error:", e);
                    setRedirect(true);
                }
            }
        )();
    }, []);

    /* useEffect(() => {
        (
            async () => {
                try {
                    const { data } = await axios.get('user');
                    setUser(new User(
                        data.id,
                        data.full_name,
                        data.email,
                        data.phonecode,
                        data.mobile,
                        data.role
                    ));
                } catch (e) {
                    setRedirect(true);
                }
            }
        )();
    }, []); */
    if (redirect) {
        return <Navigate to={'/admin/login'} />
    }
    return (
        <>
            <Sidebar />
            {children}
        </>
    );
};

const mapStateToProps = (state: { user: User }) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (user: User) => dispatch(setUser(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);