/* import {createStore} from 'redux';
import {setUserReducer} from "./reducers/setUserReducer";

export const configureStore = () => {
    return createStore(setUserReducer);
} */

import { createStore, combineReducers } from 'redux';
import { setUserReducer } from "./reducers/setUserReducer";
import { setCategoryReducer } from './reducers/setCategoryReducer';

const rootReducer = combineReducers({
    user: setUserReducer,
    category: setCategoryReducer,
});

export const configureStore = () => {
    return createStore(rootReducer);
}
